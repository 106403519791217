import { Variant } from '../../types/statistics';

export const LineChartColors = ['#0C7CFF', '#38E391', '#FFF963', '#FE6A4A', '#5668D1', '#88FFFF', '#FE5050', '#F061FF'];

export const BarChartColors: Record<Variant, string> = {
  all: '#000000',
  paid: '#5668D1',
  cancelled: '#FE6A4A',
};

export const ChartMonths: { [key: string]: string } = {
  '01': 'Январь',
  '02': 'Февраль',
  '03': 'Март',
  '04': 'Апрель',
  '05': 'Май',
  '06': 'Июнь',
  '07': 'Июль',
  '08': 'Август',
  '09': 'Сентябрь',
  '10': 'Октябрь',
  '11': 'Ноябрь',
  '12': 'Декабрь',
};
