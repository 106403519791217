import { FC } from "react";
import { useParams } from "react-router-dom";
import { useGetInsuranceQuery } from "../../app/api/insurancesApi";
import InsuranceForm from "../../components/insurances/InsuranceForm";

interface Props {}

const CompaniesUpdatePage: FC<Props> = () => {
  const { id } = useParams();
  const { data } = useGetInsuranceQuery(id);

  if (!data) return null;

  return <InsuranceForm data={data.data} />;
};

export default CompaniesUpdatePage;
