import { Box, Chip, Typography } from "@mui/material";
import { FC } from "react";
import GreyCard from "./GreyCard";

interface StatisticsCardProps {
  label: string;
  value: string | number;
  subtitle: string;
}

const StatisticsCard: FC<StatisticsCardProps> = ({
  label,
  value,
  subtitle,
}) => {
  return (
    <GreyCard display="flex" gap={2}>
      <Box width={0.5}>
        <Typography variant="body2" color="#555562">
          {label}
        </Typography>
        <Typography variant="h4" mt={1} mb={2}>
          {value}
        </Typography>
        <Chip
          label={subtitle}
          variant="outlined"
          size="small"
          sx={{
            border: "1px solid #E1E1E1",
            borderRadius: 3.5,
            px: 1.5,
            py: 0.75,
            "& .MuiChip-label": { p: 0, fontSize: 11, lineHeight: 1 },
          }}
        />
      </Box>
    </GreyCard>
  );
};

export default StatisticsCard;
