import { Troubleshoot } from '@mui/icons-material';
import { Stack } from '@mui/material';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { FC } from 'react';
import {
  useGetStatisticsAppAllQuery,
  useGetStatisticsAppDynBankCountQuery,
  useGetStatisticsAppDynBankPremiumQuery,
  useGetStatisticsAppDynInsCountQuery,
  useGetStatisticsAppDynInsPremiumQuery,
} from '../app/api/statisticsApi';
import PageLayout from '../components/PageLayout';
import StatisticsBarsChart from '../components/statistics/StatisticsBarsChart';
import StatisticsLineChart from '../components/statistics/StatisticsLineChart';
import useUser from '../hooks/useUser';

interface StatisticsProps {}

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const Statistics: FC<StatisticsProps> = () => {
  const { isAdmin, isBank, isInsurance } = useUser();

  return (
    <PageLayout title="Статистика" icon={<Troubleshoot sx={{ fontSize: 36, mr: 1.5, color: '#FF6A2A' }} />}>
      <Stack gap={2}>
        <StatisticsBarsChart query={useGetStatisticsAppAllQuery} />
        <StatisticsLineChart query={useGetStatisticsAppDynBankCountQuery} />
        <StatisticsLineChart query={useGetStatisticsAppDynBankPremiumQuery} />
        <StatisticsLineChart query={useGetStatisticsAppDynInsCountQuery} />
        <StatisticsLineChart query={useGetStatisticsAppDynInsPremiumQuery} />
      </Stack>
    </PageLayout>
  );
};

export default Statistics;
