import BankActivationPage from './ActivationPage';
import BanksCreatePage from './CreatePage';
import BanksListPage from './ListPage';
import BanksUpdatePage from './UpdatePage';

const Banks = {
  List: BanksListPage,
  Create: BanksCreatePage,
  Activate: BankActivationPage,
  Update: BanksUpdatePage,
};

export default Banks;
