import ApplicationCreatePage from "./CreatePage";
import ApplicationDetailsPage from "./DetailsPage";
import ApplicationsListPage from "./ListPage";
import ApplicationUpdatePage from "./UpdatePage";

const Applications = {
  List: ApplicationsListPage,
  Details: ApplicationDetailsPage,
  Create: ApplicationCreatePage,
  Update: ApplicationUpdatePage,
};

export default Applications;
