import { Box, Drawer, Stack } from '@mui/material';
import { FC, useState } from 'react';
import { Outlet } from 'react-router-dom';
import theme from '../app/theme';
import { DrawerProvider } from '../contexts/DrawerContext';
import { withAuthorization } from '../hocs/withAuthorization';
import MenuButton from './MenuButton';
import Sidebar from './Sidebar';

interface DashboardLayoutProps {}

const DashboardLayout: FC<DashboardLayoutProps> = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <DrawerProvider>
      <Box display="flex" width={1} height={1} minHeight="100vh">
        <Drawer
          anchor="left"
          open={menuOpen}
          onClose={() => setMenuOpen(false)}
          sx={{
            '& .MuiDrawer-paper': {
              backgroundColor: 'transparent',
              boxShadow: 'none',
              py: 1.5,
            },
          }}
        >
          <Sidebar onClose={() => setMenuOpen(false)} />
        </Drawer>
        <Stack
          width={70}
          bgcolor="#000000"
          p={theme.spacing(1.25, 1, 2)}
          gap={2.5}
          alignItems="center"
          borderRadius={'0px 20px 20px 0px'}
          position="fixed"
          top={12}
          bottom={12}
          zIndex={10}
        >
          <MenuButton onClick={() => setMenuOpen(true)} />
        </Stack>
        <Box flexGrow={1} maxWidth={1}>
          <Outlet />
        </Box>
      </Box>
    </DrawerProvider>
  );
};

// export default DashboardLayout;
export default withAuthorization(DashboardLayout);
