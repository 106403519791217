import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { urlWithSearchParams } from "../../helpers/format";
import { rtkBaseQuery } from "../../helpers/rtkquery";

export const scoringsApi = createApi({
  reducerPath: "scoringsApi",
  baseQuery: rtkBaseQuery("/insurance-scorings"),
  tagTypes: ["Scoring"],
  endpoints: (builder) => ({
    getScorings: builder.query({
      query: (params) => urlWithSearchParams("", params),
      providesTags: ["Scoring"],
    }),
    getScoringById: builder.query({
      query: (id) => `${id}`,
      providesTags: (_result, _error, { id }) => [{ type: "Scoring", id }],
    }),
    saveScoring: builder.mutation({
      query: (body) => ({
        url: "",
        method: "POST",
        body,
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: "Scoring", id }],
    }),
    editScoring: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `${id}`,
        method: "POST",
        body: { ...body, _method: "PUT" },
      }),
      invalidatesTags: (_result, _error, { id }) => [
        "Scoring",
        { type: "Scoring", id },
      ],
    }),
    getScoringFields: builder.query({
      query: () => "fields",
    }),
  }),
});

export const {
  useGetScoringsQuery,
  useGetScoringByIdQuery,
  useSaveScoringMutation,
  useEditScoringMutation,
  useGetScoringFieldsQuery,
} = scoringsApi;
