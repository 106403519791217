import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { urlWithSearchParams } from "../../helpers/format";
import { rtkBaseQuery } from "../../helpers/rtkquery";

export const clientsApi = createApi({
  reducerPath: "clientsApi",
  baseQuery: rtkBaseQuery("/clients"),
  tagTypes: ["Clients"],
  endpoints: (builder) => ({
    getClients: builder.query({
      query: (params) => urlWithSearchParams("", params),
      providesTags: ["Clients"],
    }),
    getClientById: builder.query({
      query: (id) => `${id}`,
      providesTags: (result, error, { id }) => [{ type: "Clients", id }],
    }),
    saveClient: builder.mutation({
      query: (body) => ({
        url: "",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Clients"],
    }),
    activateClient: builder.mutation({
      query: (id) => ({
        url: `${id}/activate`,
        method: "PATCH",
      }),
      invalidatesTags: (result, error, { id }) => [
        "Clients",
        { type: "Clients", id },
      ],
    }),
    updateClient: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (result, error, { id }) => [
        "Clients",
        { type: "Clients", id },
      ],
    }),
    deleteClient: builder.mutation({
      query: (id) => ({
        url: `${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Clients"],
    }),
  }),
});

export const {
  useGetClientsQuery,
  useGetClientByIdQuery,
  useSaveClientMutation,
  useActivateClientMutation,
  useUpdateClientMutation,
  useDeleteClientMutation,
} = clientsApi;
