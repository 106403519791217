import {
  Checkbox,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { ReactComponent as SortingArrowsIcon } from '../assets/svg/SortingArrows.svg';
import { ReactComponent as SortingArrowsDescIcon } from '../assets/svg/SortingArrowsDesc.svg';
import { Column } from '../types/table';
import Loader from './Loader';
import SplitTableRow from './SplitTableRow';

interface SplitTableProps {
  cols: Column[];
  rows: Record<string, any>[];
  selectable?: boolean;
  actions?: (item: any) => void;
  props: any;
  setProps: (props: any) => void;
  total: number;
  isLoading: boolean;
  expandRow?: (row: any) => React.ReactNode;
}

const SplitTable: FC<SplitTableProps> = ({
  cols,
  rows,
  selectable = false,
  actions,
  props,
  setProps,
  total,
  isLoading,
  expandRow,
}) => {
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState<string | undefined>();
  const [order, setOrder] = useState<'desc' | 'asc' | undefined>();

  useEffect(() => {
    setProps((prev: any) => ({
      ...prev,
      page,
      sort,
      order,
    }));
  }, [page, sort, order, setProps]);

  const createSortHandler = (property: string) => () => {
    if (property === sort) {
      setOrder(order === 'asc' ? 'desc' : 'asc');
    } else {
      setSort(property);
      setOrder('desc');
    }
  };

  const renderData = () => {
    if (isLoading) {
      return (
        <TableRow>
          <TableCell colSpan={cols.length + (selectable ? 1 : 0) + (actions ? 1 : 0)} align="center">
            <Loader />
          </TableCell>
        </TableRow>
      );
    } else {
      if (rows.length === 0) {
        return (
          <TableRow>
            <TableCell colSpan={cols.length + (selectable ? 1 : 0) + (actions ? 1 : 0)} align="center">
              Нет данных
            </TableCell>
          </TableRow>
        );
      } else {
        return rows.map((row: any) => (
          <SplitTableRow
            key={row.id}
            row={row}
            cols={cols}
            selectable={selectable}
            actions={actions}
            expandRow={expandRow}
          />
        ));
      }
    }
  };

  return (
    <Stack
      sx={{
        height: 1,
        justifyContent: 'space-between',

        '& .MuiTableCell-root': {
          // py: 1,
          py: 1.25,
        },
      }}
    >
      <TableContainer
        sx={{
          width: 1,
          maxWidth: 'calc(100vw - 96px)',
        }}
      >
        <Table sx={{ mb: 2 }}>
          <TableHead>
            <TableRow>
              {expandRow && <TableCell width={60} />}
              {selectable && (
                <TableCell width={50}>
                  <Checkbox sx={{ p: 0 }} />
                </TableCell>
              )}
              {cols.map((col) => (
                <TableCell
                  key={col.id}
                  align={col.align || ('left' as any)}
                  sx={{ whiteSpace: 'pre' }}
                  width={col.width}
                >
                  {col.sortable ? (
                    <TableSortLabel
                      active={col.sortable}
                      IconComponent={sort === col.id && order ? SortingArrowsDescIcon : SortingArrowsIcon}
                      direction={sort === col.id ? order : undefined}
                      onClick={createSortHandler(col.id)}
                    >
                      {col.label}
                    </TableSortLabel>
                  ) : (
                    col.label
                  )}
                </TableCell>
              ))}
              {actions && <TableCell width={62} />}
            </TableRow>
          </TableHead>
          <TableBody>{renderData()}</TableBody>
        </Table>
      </TableContainer>

      <Stack direction="row" alignItems="center" justifyContent="flex-end" gap={3}>
        {total > 0 && (
          <Pagination shape="rounded" count={Math.ceil(total / 10)} page={page} onChange={(e, page) => setPage(page)} />
        )}
      </Stack>
    </Stack>
  );
};

export default SplitTable;
