import { Close } from "@mui/icons-material";
import { Box, Button, SxProps, Typography } from "@mui/material";
import {
  ChangeEvent,
  FC,
  InputHTMLAttributes,
  useEffect,
  useState,
} from "react";
import { RecordObject } from "../types";
import FlexBox from "./FlexBox";

interface Props {
  selectFile: (file: File) => void;
  existFile?: RecordObject;
  showPreview?: boolean;
  sx?: SxProps;
}

const FileInput: FC<Props & InputHTMLAttributes<HTMLInputElement>> = ({
  selectFile,
  existFile,
  showPreview = false,
  sx,
  ...props
}) => {
  const [file, setFile] = useState<File | null>(null);

  useEffect(() => {
    // props.onChange?.(file);
  }, [file]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFile(e.target.files?.[0] || null);
    if (e.target.files?.[0]) selectFile(e.target.files?.[0]);
  };

  const handleClear = () => {
    setFile(null);
  };

  const getFileUrl = (file: File) => {
    return URL.createObjectURL(file);
  };

  return (
    <FlexBox alignItems="center" gap={2} sx={sx}>
      <Button
        size="large"
        component="label"
        variant="contained"
        sx={{ whiteSpace: "nowrap", flexShrink: 0 }}
      >
        Выберите файл
        <input hidden type="file" {...props} onChange={handleChange} />
      </Button>
      {existFile && !file && (
        <>
          {showPreview && (
            <Box
              component="img"
              src={existFile.url}
              alt={existFile.file_name}
              sx={{
                width: 80,
                height: 40,
                objectFit: "contain",
              }}
            />
          )}
          <Typography>{existFile.file_name}</Typography>
          <Button
            variant="contained"
            color="secondary"
            sx={{ ml: "auto" }}
            onClick={handleClear}
          >
            <Close />
          </Button>
        </>
      )}
      {file && (
        <>
          {showPreview && (
            <Box
              component="img"
              src={getFileUrl(file)}
              alt={file.name}
              sx={{
                width: 80,
                height: 40,
                objectFit: "contain",
              }}
            />
          )}
          <Typography
            sx={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
            title={file.name}
          >
            {file.name}
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            sx={{ ml: "auto" }}
            onClick={handleClear}
          >
            <Close />
          </Button>
        </>
      )}
    </FlexBox>
  );
};

export default FileInput;
