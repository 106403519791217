import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useLogoutMutation } from '../app/api/authApi';

interface LogoutProps {}

const Logout: FC<LogoutProps> = () => {
  const navigate = useNavigate();
  const [logout] = useLogoutMutation();
  useEffect(() => {
    logout({})
      .unwrap()
      .then(() => {
        localStorage.clear();
        toast.success('Вы успешно вышли из системы');
        console.log('logout');

        navigate('/');
      });
  }, [logout, navigate]);

  return null;
};

export default Logout;
