import { Grid, MenuItem, Stack, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { getIn, useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { object, string } from 'yup';
import { useCheckApplicationMutation } from '../../app/api/applicationsApi';
import { ROUTE } from '../../constants/routes';
import { getFormikError, hasFormikError, objectToFormData, validationErrorsResponse } from '../../helpers/form';
import { parseLocalizedDate } from '../../helpers/format';
import { useDialog } from '../../hooks/useDialog';
import useLocale from '../../hooks/useLocale';
import { Product } from '../../types/product';
import FormPageLayout from '../FormPageLayout';
import FormSection from '../FormSection';
import ApplicationConfirmDialog from './ApplicationConfirmDialog';

interface ApplicationFormProps {
  products: Product[];
}

export const ApplicationForm: FC<ApplicationFormProps> = ({ products }) => {
  const navigate = useNavigate();
  const { locale } = useLocale();

  const [selectedProduct, setSelectedProduct] = useState<Product>();

  const { openDialog, closeDialog, renderDialog } = useDialog();

  const [check] = useCheckApplicationMutation();

  const baseSchema = {
    product_id: string().required('Выберите продукт'),
    // transaction_id: string().required("Введите номер транзакции"),
    policy_fields: object().shape({}),
  };
  const [schema, setSchema] = useState(baseSchema);

  const formik = useFormik({
    // validateOnChange: false,
    // validateOnBlur: false,
    initialValues: {
      product_id: '',
      policy_fields: {},
    },
    validationSchema: object().shape(schema),
    onSubmit: (values) => {
      // openDialog(<ApplicationConfirmDialog values={values} />);

      const formdata = objectToFormData(values);

      check(formdata)
        .unwrap()
        .then((_res) => {
          openDialog(<ApplicationConfirmDialog product={selectedProduct} values={values} close={closeDialog} />);
        })
        .catch((res) => {
          if (res.status === 422) {
            const formikErrors = validationErrorsResponse(res.data.data);
            toast.error('Ошибка валидации');
            formik.setErrors(formikErrors);
          }
        });
    },
  });

  useEffect(() => {
    if (formik.values.product_id) {
      const product: any = products.find((item: any) => item.id === formik.values.product_id);
      if (product) {
        setSelectedProduct(product);
        const policy_fields = product.policy_fields
          .map((section: any) => section.fields)
          .flat()
          .reduce((acc: any, item: any) => {
            acc[item.code] = ['contract_date', 'period_begin'].includes(item.code)
              ? new Date().toLocaleDateString('ru')
              : '';
            return acc;
          }, {});

        formik.setFieldValue('policy_fields', policy_fields);

        const extraSchema = product.policy_fields.reduce((acc: any, item: any) => {
          if (item.is_required) {
            acc[item.code] = string().required('Поле обязательно для заполнения');
            return acc;
          }
          return acc;
        }, {});

        setSchema({
          ...baseSchema,
          policy_fields: object().shape(extraSchema),
          // policy_fields: object().shape({
          //   inn: string().required("Поле обязательно для заполнения"),
          // }),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.product_id]);

  useEffect(() => {
    const { loan_term, period_begin } = formik.values.policy_fields as any;
    if (loan_term && period_begin) {
      const date = new Date(parseLocalizedDate(period_begin) ?? '');
      date.setMonth(date.getMonth() + Number(loan_term));
      date.setDate(date.getDate() - 1);
      formik.setFieldValue('policy_fields.period_end', date.toLocaleDateString('ru'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.policy_fields]);

  const form_sections = [
    {
      id: 'product',
      title: 'Продукт',
      type: 'grid',
      hide: false,
      fields: [
        {
          type: 'text',
          name: 'product_id',
          label: 'Продукт',
          width: 4,
          select: true,
          options: products.map((item: any) => ({
            label: item.name[locale],
            value: item.id,
          })),
        },
      ],
    },
  ];

  const handleSubmit = () => {
    console.log('errors', formik.errors);
    formik.handleSubmit();
  };

  return (
    <FormPageLayout title="Новая заявка" onSave={handleSubmit} onClose={() => navigate(ROUTE.APPLICATIONS)}>
      <Stack flexGrow={1} component="form" noValidate onSubmit={formik.handleSubmit}>
        {form_sections.map(
          (section: any) =>
            !section.hide && (
              <FormSection
                title={section.title}
                id={section.id}
                key={section.id}
                // actions={section.headerActions}
              >
                {section.type === 'grid' && (
                  <Grid container spacing={2}>
                    {section.fields &&
                      section.fields.map((field: any, index: number) => {
                        if (!field) return null;

                        const { name, options, width, ...props } = field;
                        return (
                          <Grid item xs={12} sm={width} key={section.id + '_' + index}>
                            <TextField
                              {...props}
                              fullWidth
                              {...formik.getFieldProps(name)}
                              error={hasFormikError(formik, name)}
                              helperText={getFormikError(formik, name)}
                              defaultValue={props.type === 'date' && new Date()}
                            >
                              {options &&
                                options.map(({ label, value }: any) => (
                                  <MenuItem value={value} key={value}>
                                    {label}
                                  </MenuItem>
                                ))}
                            </TextField>
                          </Grid>
                        );
                      })}
                  </Grid>
                )}
                {section.type === 'custom' && section.content}
              </FormSection>
            )
        )}

        {selectedProduct?.policy_fields.map((section: any) => (
          <FormSection title={section.title} key={section.title}>
            <Grid container spacing={2}>
              {section.fields.map((field: any) => {
                const props = field.type === 'file' ? { InputLabelProps: { shrink: true } } : {};
                return (
                  <Grid item xs={12} sm={6} md={section.code === 'loan_info' ? 3 : 4} key={field.code}>
                    {field.type === 'formattedPrice' ? (
                      <NumericFormat
                        thousandSeparator=" "
                        {...field}
                        customInput={TextField}
                        fullWidth
                        label={field.name}
                        type={field.type}
                        required={field.is_required}
                        {...formik.getFieldProps('policy_fields.' + field.code)}
                        onChange={(e: any) =>
                          formik.setFieldValue('policy_fields.' + field.code, e.target.value.replace(/ /g, ''))
                        }
                        error={hasFormikError(formik, 'policy_fields.' + field.code)}
                        helperText={getFormikError(formik, 'policy_fields.' + field.code)}
                      />
                    ) : field.type === 'date' ? (
                      <DatePicker
                        onChange={(value) => {
                          formik.setFieldValue(
                            'policy_fields.' + field.code,
                            value ? new Date(value).toLocaleDateString('ru') : null
                          );
                        }}
                        value={parseLocalizedDate(getIn(formik.values.policy_fields, [field.code])) || null}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            label: field.name,
                            required: field.is_required,
                            error: hasFormikError(formik, 'policy_fields.' + field.code),
                            helperText: getFormikError(formik, 'policy_fields.' + field.code),
                          },
                        }}
                      />
                    ) : (
                      <TextField
                        fullWidth
                        label={field.name}
                        type={field.type}
                        required={field.is_required}
                        {...(field.type !== 'file' ? formik.getFieldProps('policy_fields.' + field.code) : {})}
                        onChange={(e: any) => {
                          formik.setFieldValue(
                            'policy_fields.' + field.code,
                            field.type === 'file' ? e.target.files : e.target.value
                          );
                        }}
                        error={hasFormikError(formik, 'policy_fields.' + field.code)}
                        helperText={getFormikError(formik, 'policy_fields.' + field.code)}
                        {...props}
                      />
                    )}
                  </Grid>
                );
              })}
            </Grid>
          </FormSection>
        ))}
      </Stack>
      {renderDialog()}
    </FormPageLayout>
  );
};

export default ApplicationForm;
