import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { urlWithSearchParams } from "../../helpers/format";
import { rtkBaseQuery } from "../../helpers/rtkquery";

export const userRolesApi = createApi({
  reducerPath: "userRolesApi",
  baseQuery: rtkBaseQuery("/user-roles"),
  endpoints: (builder) => ({
    getUserRoles: builder.query({
      query: (params) => urlWithSearchParams("", params),
    }),
    getUserRoleById: builder.query({
      query: (id) => `${id}`,
    }),
    createUserRole: builder.mutation({
      query: (body) => ({
        url: "",
        method: "post",
        body,
      }),
    }),
    updateUserRole: builder.mutation({
      query: (body) => ({
        url: `${body.id}`,
        method: "put",
        body,
      }),
    }),
  }),
});

export const {
  useGetUserRolesQuery,
  useGetUserRoleByIdQuery,
  useCreateUserRoleMutation,
  useUpdateUserRoleMutation,
} = userRolesApi;
