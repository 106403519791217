import ProductsCreatePage from "./CreatePage";
import ProductsListPage from "./ListPage";
import ProductsUpdatePage from "./UpdatePage";

const Products = {
  List: ProductsListPage,
  Create: ProductsCreatePage,
  Update: ProductsUpdatePage,
};

export default Products;
