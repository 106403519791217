import { createApi } from "@reduxjs/toolkit/query/react";
import { urlWithSearchParams } from "../../helpers/format";
import { rtkBaseQuery } from "../../helpers/rtkquery";
export const modulesApi = createApi({
  reducerPath: "modulesApi",
  baseQuery: rtkBaseQuery("/modules"),

  endpoints: (builder) => ({
    getModules: builder.query({
      query: (params) => urlWithSearchParams("", params),
    }),
    getModuleById: builder.query({
      query: (id) => `${id}`,
    }),
    createModule: builder.mutation({
      query: (body) => ({
        url: "",
        method: "post",
        body,
      }),
    }),
    updateModule: builder.mutation({
      query: (body) => ({
        url: `${body.id}`,
        method: "put",
        body,
      }),
    }),
  }),
});

export const {
  useGetModulesQuery,
  useGetModuleByIdQuery,
  useCreateModuleMutation,
  useUpdateModuleMutation,
} = modulesApi;
