import { FC } from "react";
import InsuranceForm from "../../components/insurances/InsuranceForm";

interface Props {}

const InsurancesCreatePage: FC<Props> = () => {
  return <InsuranceForm isNew data={{}} />;
};

export default InsurancesCreatePage;
