import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { urlWithSearchParams } from "../../helpers/format";
import { rtkBaseQuery } from "../../helpers/rtkquery";

export const languagesApi = createApi({
  reducerPath: "languagesApi",
  baseQuery: rtkBaseQuery("/languages"),
  endpoints: (builder) => ({
    getAllLanguages: builder.query({
      query: () => "all",
    }),
    getLanguages: builder.query({
      query: (params) => urlWithSearchParams("", params),
    }),
    getLanguageById: builder.query({
      query: (id) => `${id}`,
    }),
    createLanguage: builder.mutation({
      query: (body) => ({
        url: "",
        method: "post",
        body,
      }),
    }),
    updateLanguage: builder.mutation({
      query: (body) => ({
        url: `${body.id}`,
        method: "put",
        body,
      }),
    }),
    patchLanguage: builder.mutation({
      query: (body) => ({
        url: `${body.id}`,
        method: "patch",
        body,
      }),
    }),
  }),
});

export const {
  useGetAllLanguagesQuery,
  useGetLanguagesQuery,
  useGetLanguageByIdQuery,
  useCreateLanguageMutation,
  useUpdateLanguageMutation,
  usePatchLanguageMutation,
} = languagesApi;
