import { Grid, MenuItem, Stack, TextField } from "@mui/material";
import { useFormik } from "formik";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import {
  useCreateProductMutation,
  useUpdateProductMutation,
} from "../../app/api/productsApi";
import { ROUTE } from "../../constants/routes";
import { getFormikError, hasFormikError } from "../../helpers/form";
import FormPageLayout from "../FormPageLayout";
import FormSection from "../FormSection";
import ProductFields from "./ProductFields";

interface Props {
  isNew?: boolean;
  data: any;
}

const initialValues = {
  name: {
    ru: "",
    uz: "",
    en: "",
  },
  description: {
    ru: "",
    uz: "",
    en: "",
  },
  is_legal_form: "0",
  code: "",
  policy_fields: [],
};

const ProductForm: FC<Props> = ({ data, isNew }) => {
  const navigate = useNavigate();
  const query = isNew ? useCreateProductMutation : useUpdateProductMutation;

  const [save] = query();

  const handleFields = (fields: any) => {
    formik.setFieldValue("policy_fields", fields);
  };

  const formik = useFormik({
    initialValues: isNew ? initialValues : data,
    onSubmit: (values) => {
      // console.log(formik.errors);
      // console.log(values);
      save(values)
        .unwrap()
        .then((res) => {
          toast.success("Успешно сохранено");
          navigate(ROUTE.PRODUCTS);
        })
        .catch((err) => {
          toast.error("Ошибка сохранения");
          console.error(err);
        });
    },
    validationSchema: yup.object().shape({}),
  });

  const form_sections = [
    {
      id: "general",
      title: "Основное",
      type: "grid",
      hide: false,
      fields: [
        {
          type: "text",
          name: "is_legal_form",
          label: "Правовая форма",
          width: 6,
          select: true,
          options: [
            {
              label: "Физическое лицо",
              value: "0",
            },
            {
              label: "Юридическое лицо",
              value: "1",
            },
          ],
          defaultValue: "0",
        },
        {
          type: "text",
          name: "code",
          label: "Код",
          width: 6,
        },
        {
          type: "text",
          name: "name.ru",
          label: "Название на русском",
          width: 12,
        },
        {
          type: "text",
          name: "name.uz",
          label: "Название на узбекском",
          width: 12,
        },
        {
          type: "text",
          name: "name.en",
          label: "Название на английском",
          width: 12,
        },
      ],
    },
    {
      id: "fields",
      title: "Поля",
      type: "custom",
      hide: false,
      content: (
        <ProductFields
          onChange={handleFields}
          data={data.policy_fields}
          isBaseProduct
        />
      ),
    },
  ];

  return (
    <FormPageLayout
      title={
        isNew ? "Новый продукт" : "Редактирование продукта " + data.name.ru
      }
      onSave={formik.handleSubmit}
      onClose={() => navigate(ROUTE.PRODUCTS)}
    >
      <Stack
        flexGrow={1}
        component="form"
        noValidate
        onSubmit={formik.handleSubmit}
      >
        {form_sections.map(
          (section) =>
            !section.hide && (
              <FormSection
                title={section.title}
                id={section.id}
                key={section.id}
                // actions={section.headerActions}
              >
                {section.type === "grid" && (
                  <Grid container spacing={2}>
                    {section.fields &&
                      section.fields.map((field: any, index: number) => {
                        if (!field) return null;

                        const { name, options, width, ...props } = field;
                        return (
                          <Grid
                            item
                            xs={12}
                            sm={width}
                            key={section.id + "_" + index}
                          >
                            <TextField
                              {...props}
                              fullWidth
                              {...formik.getFieldProps(name)}
                              error={hasFormikError(formik, name)}
                              helperText={getFormikError(formik, name)}
                            >
                              {options &&
                                options.map(({ label, value }: any) => (
                                  <MenuItem value={value} key={value}>
                                    {label}
                                  </MenuItem>
                                ))}
                            </TextField>
                          </Grid>
                        );
                      })}
                  </Grid>
                )}
                {section.type === "custom" && section.content}
              </FormSection>
            )
        )}
      </Stack>
    </FormPageLayout>
  );
};

export default ProductForm;
