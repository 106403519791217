import { Stack } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import Accordion from '../../components/Accordion';
import { renderCompanyName } from '../../helpers/format';
import { RecordObject } from '../../types';
import { Bank, Branch } from '../../types/reports';
import ReportBranch from './ReportBranch';
import ReportCheckAllButton from './ReportCheckAllButton';
import { DEFAULT_SELECTED_ALL } from './ReportsPage';

interface Props {
  bank: Bank;
  selectedBanks: 'all' | 'none' | 'partial';
  updateSelection: (data: any) => void;
}

const ReportBank: FC<Props> = ({ bank, selectedBanks, updateSelection }) => {
  const [branches, setBranches] = useState<RecordObject[]>(
    bank.merchant.children?.items.map((b) => ({
      bank_branch_seller_ids: [],
      branch_id: b.id,
    })) || []
  );
  const [selectedBranches, setSelectedBranches] = useState<'all' | 'none' | 'partial'>(DEFAULT_SELECTED_ALL);

  useEffect(() => {
    if (!branches.length) return;
    if (branches.every((item) => !!item.bank_branch_seller_ids.length)) setSelectedBranches('all');
    else if (branches.every((item) => !item.bank_branch_seller_ids.length)) setSelectedBranches('none');
    else setSelectedBranches('partial');
  }, [branches]);

  useEffect(() => {
    switch (selectedBanks) {
      case 'all':
        if (selectedBranches === 'none') setSelectedBranches('all');
        break;
      case 'none':
        if (selectedBranches !== 'none') setSelectedBranches('none');
        break;
      default:
        break;
    }
  }, [selectedBanks]);

  useEffect(() => {
    updateSelection({
      bank_id: bank.id,
      branches,
    });
  }, [branches]);

  const handleUpdateSelection = (values: { branch_id: number; bank_branch_seller_ids: number[] }) => {
    setBranches((prev) => {
      const next = [...prev];
      const index = next.findIndex((item) => item.branch_id === values.branch_id);
      if (index === -1) {
        next.push(values);
      } else {
        next[index] = values;
      }
      return next;
    });
  };

  const handleToggleAll = () => {
    setSelectedBranches((prev) => (prev !== 'all' ? 'all' : 'none'));
  };

  return (
    <Accordion
      summary={renderCompanyName(bank.merchant)}
      action={<ReportCheckAllButton checkedAll={selectedBranches} onClick={handleToggleAll} label="Все филиалы" />}
      details={
        bank.merchant.children && (
          <Stack gap={0.25}>
            {bank.merchant.children.items.map((branch: Branch, index: number) => (
              <ReportBranch
                key={index}
                branch={branch}
                updateSelection={handleUpdateSelection}
                selectedBranches={selectedBranches}
              />
            ))}
          </Stack>
        )
      }
      sx={{
        bgcolor: 'white',
      }}
    />
  );
};

export default ReportBank;
