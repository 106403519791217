import { FC } from "react";
import BranchForm from "../../components/branches/BranchForm";

interface Props {}

const BranchesCreatePage: FC<Props> = () => {
  return <BranchForm isNew data={{}} />;
};

export default BranchesCreatePage;
