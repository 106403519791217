import { Add } from '@mui/icons-material';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { RecordObject } from '../../types';
import ProductFieldsRow from './ProductFieldsRow';

interface ProductFieldsProps {
  onChange: (fields: any) => void;
  data?: RecordObject[];
  isBaseProduct?: boolean;
}

const ProductFields: FC<ProductFieldsProps> = ({ data, onChange, isBaseProduct }) => {
  const [fields, setFields] = useState<RecordObject[]>([
    {
      id: '',
      type: 'text',
      name: 'Срок страхования',
      code: 'term',
      is_required: true,
      section: 'loan_info',
    },
    {
      id: '',
      type: 'date',
      name: 'Дата начала',
      code: 'period_begin',
      is_required: true,
      section: 'loan_info',
    },
    {
      id: '',
      type: 'text',
      name: 'Дата окончания',
      code: 'period_end',
      is_required: true,
      section: 'loan_info',
    },
  ]);

  const templateRow = {
    id: '',
    type: 'text',
    name: '',
    code: '',
    is_required: true,
  };

  useEffect(() => {
    if (data) {
      setFields(
        data.map((item: any) => ({
          ...templateRow,
          ...item,
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    onChange(fields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  const handleChangeField = (index: number, field: string, value: any) => {
    setFields((prev) =>
      prev.map((item, i) => {
        return i !== index ? item : { ...item, [field]: value };
      })
    );
  };

  // console.log("fields", fields);
  return (
    <TableContainer>
      <Table cellSpacing={0} cellPadding={0}>
        <TableHead sx={{ '& .MuiTableCell-root': { p: 1, pb: 0 } }}>
          <TableRow>
            <TableCell />
            <TableCell>Секция</TableCell>
            <TableCell>Тип поля</TableCell>
            <TableCell>Название</TableCell>
            <TableCell>Код</TableCell>
            <TableCell>Обязательное</TableCell>
            {isBaseProduct && <TableCell />}
          </TableRow>
        </TableHead>

        <TableBody sx={{ '& .MuiTableCell-root': { p: 1 } }}>
          {fields.map((row: any, index: number) => (
            <ProductFieldsRow
              key={index}
              row={row}
              index={index}
              onChange={handleChangeField}
              onDelete={() => setFields((prev) => prev.filter((_, i) => i !== index))}
              isBaseProduct={isBaseProduct}
              isNewProduct={!data}
              isStaticField={['term', 'period_begin', 'period_end'].includes(row.code)}
            />
          ))}
        </TableBody>
      </Table>
      {isBaseProduct && (
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          onClick={() => setFields((prev: RecordObject[]) => [...prev, templateRow])}
        >
          <Add />
        </Button>
      )}
    </TableContainer>
  );
};

export default ProductFields;
