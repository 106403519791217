import { Box, Paper, Stack, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  useActivateBankMutation,
  useGetBankQuery,
} from "../../app/api/banksApi";
import DocumentsTable from "../../components/DocumentsTable";
import FormPageLayout from "../../components/FormPageLayout";
import { ROUTE } from "../../constants/routes";
import { IFile } from "../../types/merchants";

const BankActivationPage: FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [files, setFiles] = useState<IFile[]>([]);
  const { data } = useGetBankQuery(id);
  const navigate = useNavigate();

  const [activate] = useActivateBankMutation();

  useEffect(() => {
    if (data) {
      setFiles(data.data.merchant.attachments);
    }
  }, [data]);

  return (
    <FormPageLayout
      title={t("banks.activation", "Активация банка")}
      onSave={() => {
        const fileIds = files.map((file) => file.id);
        activate({ id, attachment_ids: fileIds })
          .unwrap()
          .then((res) => navigate(ROUTE.BANKS))
          .catch(console.error);
      }}
      onClose={() => navigate(ROUTE.BANKS)}
    >
      <Box component="section" mb={2} id={id}>
        <Stack direction="row" alignItems="center" gap={2}>
          <Typography variant="h4" p={2.5}>
            {t("documents", "Документы")}
          </Typography>
        </Stack>

        <Box
          component={Paper}
          bgcolor="white"
          elevation={0}
          width={1}
          px={2.5}
          py={3}
        >
          <DocumentsTable
            data={files}
            setData={setFiles}
            documentTypes={{
              contract_signed: t(
                "documents.contract_signed",
                "Договор подписанный"
              ),
            }}
          />
        </Box>
      </Box>
    </FormPageLayout>
  );
};

export default BankActivationPage;
