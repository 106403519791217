import { setLocale } from 'yup';

setLocale({
  mixed: {
    required: 'Поле обязательно для заполнения',
  },
  string: {
    email: 'Неверный формат email',
    min: 'Минимальное количество символов ${min}',
    max: 'Максимальное количество символов ${max}',
    length: 'Длина должна быть ${length} символов',
  },
});
