import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import {
  useSaveClientMutation,
  useUpdateClientMutation,
} from "../../app/api/clientsApi";
import { getFormikError, hasFormikError } from "../../helpers/form";
import { renderFullName } from "../../helpers/format";
import { handleScroll } from "../../helpers/utils";
import { IFile } from "../../types/merchants";
import DocumentsTable from "../DocumentsTable";
import FlexBox from "../FlexBox";
import FormPageLayout from "../FormPageLayout";
import FormSection from "../FormSection";

interface Props {
  isNew?: boolean;
  data: any;
}

const ClientsForm: FC<Props> = ({ data, isNew }) => {
  const navigate = useNavigate();
  const [files, setFiles] = useState<IFile[]>([]);

  const query = isNew ? useSaveClientMutation : useUpdateClientMutation;

  useEffect(() => {
    if (data) {
      setFiles(data.branch_seller.attachments);
    }
  }, [data]);

  const [save] = query();

  const formik = useFormik({
    initialValues: {
      login: "",
      email: "",
      password: "",
      branch_seller: {
        first_name: "",
        last_name: "",
        second_name: "",
        phone: "",
        email: "",
      },

      ...data,
    },
    onSubmit: (values) => {
      save(values)
        .unwrap()
        .then((res: any) => {
          toast.success("Успешно сохранено");
          navigate(
            `/dashboard/clients/${isNew ? res.data.id + "/activate" : ""}`
          );
        })
        .catch((err) => {
          toast.error("Ошибка сохранения");
          console.error(err);
        });
    },
    validationSchema: yup.object().shape({
      login: yup.string().required(),
      email: yup.string().required(),
      password: isNew ? yup.string().required() : yup.string(),
      branch_seller: yup.object().shape({
        first_name: yup.string().required(),
        last_name: yup.string().required(),
        second_name: yup.string().required(),
        phone: yup.string().required(),
        email: yup.string().required(),
      }),
    }),
  });

  const form_sections = [
    {
      id: "account",
      title: "Аккаунт",
      type: "grid",
      fields: [
        {
          type: "text",
          name: "login",
          label: "Логин",
          width: 4,
        },
        {
          type: "text",
          name: "email",
          label: "Email",
          width: 4,
        },
        {
          type: "password",
          name: "password",
          label: "Пароль",
          width: 4,
        },
      ],
    },
    {
      id: "data",
      title: "Данные",
      type: "grid",
      fields: [
        {
          type: "text",
          name: "branch_seller.first_name",
          label: "Имя",
          width: 4,
        },
        {
          type: "text",
          name: "branch_seller.last_name",
          label: "Фамилия",
          width: 4,
        },
        {
          type: "text",
          name: "branch_seller.second_name",
          label: "Отчество",
          width: 4,
        },
        {
          type: "text",
          name: "branch_seller.phone",
          label: "Телефон",
          width: 4,
        },
        {
          type: "text",
          name: "branch_seller.email",
          label: "Email",
          width: 4,
        },
      ],
    },
    {
      id: "branch_seller.attachments",
      title: "Документы",
      type: "custom",
      hide: isNew,
      content: (
        <DocumentsTable
          data={files}
          setData={setFiles}
          documentTypes={{
            contract_signed: "Договор подписанный",
          }}
        />
      ),
    },
  ];

  return (
    <FormPageLayout
      title={
        isNew
          ? "Новый клиент"
          : "Редактирование клиента " + renderFullName(data.branch_seller)
      }
      onSave={() => {
        console.log(formik.errors, formik.values);
        formik.handleSubmit();
      }}
      onClose={() => navigate("/dashboard/clients")}
    >
      <FlexBox gap={3}>
        <Box position="relative" minWidth={240}>
          <List sx={{ position: "sticky", top: 0 }}>
            {form_sections.map(
              (section) =>
                !section.hide && (
                  <ListItem
                    button
                    key={section.id}
                    onClick={() => handleScroll(section.id)}
                  >
                    <ListItemText primary={section.title} />
                  </ListItem>
                )
            )}
          </List>
        </Box>
        <Stack
          flexGrow={1}
          component="form"
          noValidate
          onSubmit={formik.handleSubmit}
        >
          {form_sections.map(
            (section) =>
              !section.hide && (
                <FormSection
                  title={section.title}
                  id={section.id}
                  key={section.id}
                  // actions={section.headerActions}
                >
                  {section.type === "grid" && (
                    <Grid container spacing={2}>
                      {section.fields &&
                        section.fields.map((field: any, index: number) => {
                          if (!field) return null;

                          const { name, options, width, ...props } = field;
                          return (
                            <Grid
                              item
                              xs={12}
                              sm={width}
                              key={section.id + "_" + index}
                            >
                              <TextField
                                {...props}
                                fullWidth
                                {...formik.getFieldProps(name)}
                                error={hasFormikError(formik, name)}
                                helperText={getFormikError(formik, name)}
                              >
                                {options &&
                                  options.map(({ label, value }: any) => (
                                    <MenuItem value={value} key={value}>
                                      {label}
                                    </MenuItem>
                                  ))}
                              </TextField>
                            </Grid>
                          );
                        })}
                    </Grid>
                  )}
                  {section.type === "custom" && section.content}
                </FormSection>
              )
          )}
        </Stack>
      </FlexBox>
    </FormPageLayout>
  );
};

export default ClientsForm;
