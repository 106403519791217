import { toast } from 'react-toastify';

export const handleScroll = (id: string) => {
  const section = document.getElementById(id);
  if (section) {
    section.scrollIntoView({ behavior: 'smooth' });
  }
};

// get search params from url and return as object
export const getSearchParams = () => {
  const query: any = new URLSearchParams(window.location.search);
  const params: any = {};
  for (const [key, value] of query.entries()) {
    params[key] = value;
  }
  return params;
};

export const handleError = (error: any) => {
  console.error(error);
  toast.error(error.data.data.error);
};
