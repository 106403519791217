import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { urlWithSearchParams } from '../../helpers/format';
import { rtkBaseQuery } from '../../helpers/rtkquery';

export const productsApi = createApi({
  reducerPath: 'productsApi',
  baseQuery: rtkBaseQuery('/products'),
  tagTypes: ['Products'],
  endpoints: (builder) => ({
    getProducts: builder.query({
      query: (params) => urlWithSearchParams('', params),
      providesTags: ['Products'],
    }),
    getProductById: builder.query({
      query: (id) => `${id}`,
      providesTags: (_result, _error, { id }) => [{ type: 'Products', id }],
    }),
    createProduct: builder.mutation({
      query: (body) => ({
        url: '',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Products'],
    }),
    updateProduct: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_result, _error, { id }) => ['Products', { type: 'Products', id }],
    }),
    deleteProduct: builder.mutation({
      query: (id) => ({
        url: `${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Products'],
    }),
    importProducts: builder.mutation({
      query: (body) => ({
        url: 'import',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Products'],
    }),
    getReportFields: builder.mutation({
      query: (id) => id + '/report-fields',
    }),
  }),
});

export const {
  useGetProductsQuery,
  useGetProductByIdQuery,
  useCreateProductMutation,
  useUpdateProductMutation,
  useDeleteProductMutation,
  useImportProductsMutation,
  useGetReportFieldsMutation,
} = productsApi;
