import { createElement } from 'react';
import ClientsCreatePage from './CreatePage';
import ClientsListPage from './ListPage';
import Update from './UpdatePage';

const Clients = {
  Create: ClientsCreatePage,
  List: ClientsListPage,
  Update,
  Activation: () => createElement('div', {}, 'Activation'),
};

export default Clients;
