import { Close } from "@mui/icons-material";
import { AppBar, Box, Button, Stack, Toolbar, Typography } from "@mui/material";
import { FC, ReactNode } from "react";
import FlexBox from "./FlexBox";

interface Props {
  title: string;
  children: ReactNode;
  onSave: () => void;
  onClose: () => void;
}

const FormPageLayout: FC<Props> = ({ title, children, onSave, onClose }) => {
  return (
    <Stack sx={{ height: "100vh", width: 1, bgcolor: "#F5F4F5" }}>
      <AppBar
        position="static"
        color="transparent"
        sx={{
          bgcolor: "white",
          boxShadow: "0px 5px 10px rgba(225, 220, 216, 0.5)",
          zIndex: 1,
        }}
      >
        <Toolbar sx={{ pl: { xs: 8.5 }, pr: { xs: 0 } }}>
          <FlexBox width={1} alignItems="center" gap={3.5}>
            <Button
              variant="contained"
              color="secondary"
              sx={{
                width: 64,
                height: 64,
                borderRadius: 0,
                color: "#000000",
                borderRight: 1,
                borderColor: "#E2DDD9",
                backgroundColor: "white",
              }}
              onClick={onClose}
            >
              <Close />
            </Button>

            <Typography variant="h3" fontWeight={500} mr="auto">
              {title}
            </Typography>

            <Button
              variant="contained"
              color="primary"
              sx={{
                height: 64,
                borderRadius: 0,
                px: 4,
                fontSize: 13,
                fontWeight: 500,
                textTransform: "uppercase",
              }}
              onClick={onSave}
            >
              Сохранить
            </Button>
          </FlexBox>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          p: 3,
          pl: 16,
          flexGrow: 1,
          height: "calc(100% - 64px)",
          overflow: "auto",
        }}
      >
        {children}
      </Box>
    </Stack>
  );
};

export default FormPageLayout;
