import { Grid, Stack } from '@mui/material';
import { useFormik } from 'formik';
import { FC } from 'react';
import { useGenerateReportMutation, useGetReportsDataQuery } from '../../app/api/reportsApi';
import { ReportsIcon } from '../../components/Icons';
import PageLayout from '../../components/PageLayout';
import useUser from '../../hooks/useUser';
import { ReportsFormType } from '../../types/reports';
import ReportBanks from './ReportBanks';
import ReportInsurances from './ReportInsurances';
import ReportProductFields from './ReportProductFields';
import ReportsForm from './ReportsForm';

interface Props {}

export const DEFAULT_SELECTED_ALL = 'none';

const ReportsPage: FC<Props> = () => {
  const { isAdmin, isBank, isInsurance, hasRole } = useUser();
  const { data } = useGetReportsDataQuery({});
  console.log('🚀 ~ data:', data);
  const [generateReport, { isLoading }] = useGenerateReportMutation();
  const formik = useFormik<ReportsFormType>({
    initialValues: {
      date_from: '2024-01-01',
      date_to: '2024-01-08',
      product_ids: [1],
      bank_ids: [],
      bank_branch_ids: [],
      bank_branch_seller_ids: [],
      insurance_ids: [],
      fields: [],
    },
    onSubmit: (values) => {
      // console.log(values);
      generateReport(values)
        .unwrap()
        .then((res) => {
          // res.data.url
          // download file by url
          const link = document.createElement('a');
          link.href = res.data.url;
          link.setAttribute('download', 'report.xlsx');
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch(console.error);
    },
  });

  return (
    <PageLayout icon={<ReportsIcon />} title="Отчеты" hasSearch>
      {/* <ReportsProvider> */}
      <Grid container mx="auto" width={1} maxWidth={1100} mt={4}>
        <Grid item xs={12} md={3}>
          <ReportsForm formik={formik} isLoading={isLoading} />
        </Grid>

        <Grid item md={1} />

        <Grid item xs={12} md={8}>
          <Stack flexGrow={1} gap={2.5}>
            {!hasRole('branch_seller') && (
              <ReportBanks
                banks={data?.data?.merchants.banks}
                updateBanks={({ bank_ids, bank_branch_ids, bank_branch_seller_ids }) => {
                  formik.setFieldValue('bank_ids', bank_ids);
                  formik.setFieldValue('bank_branch_ids', bank_branch_ids);
                  formik.setFieldValue('bank_branch_seller_ids', bank_branch_seller_ids);
                }}
              />
            )}

            {!isInsurance && (
              <ReportInsurances
                insurances={data?.data?.merchants.insurances}
                updateInsurances={(values) => formik.setFieldValue('insurance_ids', values)}
              />
            )}

            <ReportProductFields
              fields={data?.data?.fields}
              updateFields={(values) => formik.setFieldValue('fields', values)}
            />
          </Stack>
        </Grid>
      </Grid>
      {/* </ReportsProvider> */}
    </PageLayout>
  );
};

export default ReportsPage;
