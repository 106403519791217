import { Avatar } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetScoringsQuery } from '../../app/api/scoringsApi';
import DetailsButton from '../../components/DetailsButton';
import { ScoringIcon } from '../../components/Icons';
import ListLayout from '../../components/ListLayout';
import ScoringInfo from '../../components/scoring/ScoringInfo';
import { useDrawer } from '../../contexts/DrawerContext';
import { formatPrice } from '../../helpers/numbers';
import { Column } from '../../types';

interface Props {}

const ScoringListPage: FC<Props> = () => {
  const { openDrawer } = useDrawer();
  const { t } = useTranslation();

  const cols: Column[] = [
    {
      id: 'id',
      label: 'ID',
      width: 60,
    },

    {
      id: 'logo',
      label: '',
      width: 20,
      renderCell: (data: any) => (
        <Avatar
          variant="rounded"
          src={data.merchant?.logo?.url || '/assets/images/product-image-placeholder.png'}
          sx={{
            width: 60,
            height: 40,
            borderRadius: 1,
            mr: -2,
          }}
        />
      ),
    },
    {
      id: 'insurance_company',
      label: 'Страховая компания',
      renderCell: (data: any) => data.merchant?.company_name ?? '',
    },
    // {
    //   id: "reporting_date_at",
    //   label: "Отчетная дата",
    // },
    {
      id: 'score',
      label: 'Рейтинг',
    },
    {
      id: 'solvency',
      label: 'Платежеспособность',
    },
    {
      id: 'rating_international',
      label: 'Международный рейтинг',
    },
    {
      id: 'rating_national',
      label: 'Национальный рейтинг',
    },
    {
      id: 'net_profit_at_reporting_date',
      label: 'Чистая прибыль на отчетную дату',
      align: 'right',
      renderCell: (data: any) =>
        data.merchant?.net_profit_at_reporting_date ? formatPrice(data.merchant?.net_profit_at_reporting_date) : '',
    },
    {
      id: 'coverage_percentage_max',
      label: 'Максимальный процент покрытия от портфеля',
      width: 100,
    },
    {
      id: 'action',
      label: '',
      width: 60,
      renderCell: (data: any) => <DetailsButton onClick={() => handleInfo(data)} />,
    },
  ];

  const handleInfo = (data: any) => {
    openDrawer({
      content: <ScoringInfo data={data} />,
    });
  };

  return (
    <ListLayout
      query={useGetScoringsQuery}
      icon={<ScoringIcon />}
      title={t('scoring', 'Скоринг')}
      cols={cols}
      createPermission="UserMerchantScoring@store"
    />
  );
};

export default ScoringListPage;
