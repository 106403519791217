import { Box, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { CommonItem, CommonItems, GroupedItem, GroupedItems, Variant } from '../../../types/statistics';
import FlexBox from '../../FlexBox';
import { BarChartColors, ChartMonths } from '../vars';

interface Props {
  data: GroupedItems;
}

const initialItems: CommonItems = {
  all: {
    label: 'Все заявки',
    code: 'all',
    value: 0,
    percentage: 0,
  },
  paid: {
    label: 'Полисы получены',
    code: 'paid',
    value: 0,
    percentage: 0,
  },
  cancelled: {
    label: 'Отменённые',
    code: 'cancelled',
    value: 0,
    percentage: 0,
  },
};

const initialData: GroupedItems = Object.entries(ChartMonths).reduce((acc, [code, label]) => {
  acc[code] = {
    items: initialItems,
    label,
    code,
  };
  return acc;
}, {} as any);

const YearlyBars: FC<Props> = ({ data }) => {
  const mergedData: GroupedItems = { ...initialData, ...data };
  const max = Math.max(...Object.values(data).map((item) => item.items.all.value));
  const percentOfmax = (value: number) => (value / max) * 100;
  const sortedData = Object.keys(mergedData)
    .sort()
    .reduce((acc, key) => {
      acc[+key] = mergedData[key];
      return acc;
    }, {} as GroupedItems);

  return (
    <FlexBox sx={{ alignItems: 'flex-end', gap: '25px' }}>
      {Object.values(sortedData).map((group: GroupedItem) => (
        <Stack key={group.code} sx={{ position: 'relative', gap: '3px', '&:hover .item-legend': { display: 'flex' } }}>
          <FlexBox height={160} alignItems="flex-end">
            <FlexBox height={percentOfmax(group.items.all.value)} gap={0.375} alignItems="flex-end" position="relative">
              {Object.values(group.items).map((item: CommonItem, index: number) => (
                <Box
                  key={index}
                  height={`${item.percentage}%`}
                  bgcolor={BarChartColors[item.code as Variant]}
                  sx={{ borderRadius: 0.375, width: 13, transition: '0.3s' }}
                />
              ))}

              <Stack
                className="item-legend"
                sx={{
                  position: 'absolute',
                  bottom: '100%',
                  transform: 'translateY(-4px)',
                  left: 0,
                  minWidth: '100%',
                  padding: '6px 5px',
                  bgcolor: '#F4F4F4',
                  borderRadius: '5px',
                  display: 'none',
                }}
              >
                {Object.values(group.items).map((item) => (
                  <FlexBox sx={{ fontSize: 10, alignItems: 'center', gap: '5px' }}>
                    <Box bgcolor={BarChartColors[item.code]} sx={{ width: 10, height: 10, borderRadius: '3px' }} />{' '}
                    {item.value}
                  </FlexBox>
                ))}
              </Stack>
            </FlexBox>
          </FlexBox>
          <Typography textAlign="center">{group.label.slice(0, 3)}</Typography>
        </Stack>
      ))}
    </FlexBox>
  );
};

export default YearlyBars;
