import { Avatar, Divider, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { renderFullName } from "../helpers/format";
import DetailsDrawerContainer from "./DetailsDrawerContainer";
import InfoRow from "./InfoRow";

interface Props {
  data: any;
  onClose: () => void;
}

const UserInfo: FC<Props> = ({ data, onClose }) => {
  return (
    <DetailsDrawerContainer closeDrawer={onClose}>
      <Stack gap={2}>
        <Avatar
          variant="rounded"
          src={data.img || null}
          sx={{
            width: 80,
            height: 80,
            borderRadius: 1.5,
          }}
        />

        <Typography variant="h3">{renderFullName(data)}</Typography>

        <Stack>
          <InfoRow label="ID клиента" value={data.id} />
          <Divider />
          <InfoRow label="ФИО" value={renderFullName(data)} />
          <Divider />
          <InfoRow label="Email" value={data.email} />
          <Divider />
          <InfoRow
            label="Роль"
            value={data.roles.map((role: any) => role.role_name).join(", ")}
          />
          <Divider />
        </Stack>
      </Stack>
    </DetailsDrawerContainer>
  );
};

export default UserInfo;
