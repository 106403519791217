import { createBrowserRouter } from 'react-router-dom';
import DashboardLayout from '../components/DashboardLayout';
import Home from '../pages/Home';
import Logout from '../pages/Logout';
import Statistics from '../pages/Statistics';
import Applications from '../pages/applications';
import Banks from '../pages/banks';
import Branches from '../pages/branches';
import Clients from '../pages/clients';
import InsuranceProducts from '../pages/insurance-products';
import Insurances from '../pages/insurances';
import Policies from '../pages/policies';
import Products from '../pages/products';
import { ReportsPage } from '../pages/reports';
import Scoring from '../pages/scoring';
import Sellers from '../pages/sellers';
import ModuleOperations from '../pages/settings/ModuleOperations';
import Modules from '../pages/settings/Modules';
import RoleEdit from '../pages/settings/RoleEdit';
import RoleOperations from '../pages/settings/RoleOperations';
import Roles from '../pages/settings/Roles';
import Settings from '../pages/settings/Settings';
import UserEdit from '../pages/settings/UserEdit';
import UserOperations from '../pages/settings/UserOperations';
import UserRoles from '../pages/settings/UserRoles';
import Users from '../pages/settings/Users';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/dashboard',
    element: <DashboardLayout />,
    children: [
      { index: true, element: <Statistics /> },
      {
        path: 'banks',
        children: [
          { index: true, element: <Banks.List /> },
          { path: 'add', element: <Banks.Create /> },
          { path: ':id/activate', element: <Banks.Activate /> },
          { path: ':id/edit', element: <Banks.Update /> },
        ],
      },
      {
        path: 'insurances',
        children: [
          { index: true, element: <Insurances.List /> },
          { path: 'add', element: <Insurances.Create /> },
          { path: ':id/activate', element: <Insurances.Activate /> },
          { path: ':id/edit', element: <Insurances.Update /> },
        ],
      },
      {
        path: 'branches',
        children: [
          { index: true, element: <Branches.List /> },
          { path: 'add', element: <Branches.Create /> },
          { path: ':id/activate', element: <Branches.Activate /> },
          { path: ':id/edit', element: <Branches.Update /> },
        ],
      },
      {
        path: 'sellers',
        children: [
          { index: true, element: <Sellers.List /> },
          { path: 'add', element: <Sellers.Create /> },
          { path: ':id/activate', element: <Sellers.Activate /> },
          { path: ':id/edit', element: <Sellers.Update /> },
        ],
      },
      {
        path: 'applications',
        children: [
          { index: true, element: <Applications.List /> },
          { path: 'add', element: <Applications.Create /> },
          { path: ':id', element: <Applications.Details /> },
          // { path: ":id/edit", element: <Applications.Update /> },
        ],
      },
      {
        path: 'products',
        children: [
          { index: true, element: <Products.List /> },
          { path: 'add', element: <Products.Create /> },
          { path: ':id/edit', element: <Products.Update /> },
        ],
      },
      {
        path: 'insurance-products',
        children: [
          { index: true, element: <InsuranceProducts.List /> },
          { path: 'add', element: <InsuranceProducts.Create /> },
          { path: ':id/edit', element: <InsuranceProducts.Update /> },
        ],
      },
      // {
      //   path: "product-categories",
      //   children: [
      //     { index: true, element: <ProductCategories.List /> },
      //     { path: "add", element: <ProductCategories.Create /> },
      //     { path: ":id/edit", element: <ProductCategories.Update /> },
      //   ],
      // },
      {
        path: 'clients',
        children: [
          { index: true, element: <Clients.List /> },
          { path: 'add', element: <Clients.Create /> },
          { path: ':id', element: <Clients.Update /> },
          { path: ':id/activation', element: <Clients.Activation /> },
        ],
      },
      {
        path: 'policies',
        children: [
          { index: true, element: <Policies.List /> },
          { path: 'add', element: <Policies.Create /> },
          { path: ':id/edit', element: <Policies.Update /> },
        ],
      },
      {
        path: 'reports',
        children: [{ index: true, element: <ReportsPage /> }],
      },
      {
        path: 'scoring',
        children: [
          { index: true, element: <Scoring.List /> },
          { path: 'add', element: <Scoring.Create /> },
          { path: ':id/edit', element: <Scoring.Update /> },
        ],
      },
      {
        path: 'settings',
        element: <Settings />,
        children: [
          {
            path: 'users',
            children: [
              { index: true, element: <Users /> },
              { path: ':id', element: <UserEdit /> },
            ],
          },
          {
            path: 'roles',
            children: [
              { index: true, element: <Roles /> },
              { path: ':id', element: <RoleEdit /> },
            ],
          },
          { path: 'user_roles', element: <UserRoles /> },
          { path: 'modules', element: <Modules /> },

          { path: 'module_operations', element: <ModuleOperations /> },
          { path: 'role_operations', element: <RoleOperations /> },
          { path: 'user_operations', element: <UserOperations /> },
        ],
      },
    ],
  },
  {
    path: '/logout',
    element: <Logout />,
  },
]);

export default router;
