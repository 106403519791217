import { Avatar, Checkbox, FormLabel, Stack, Typography } from '@mui/material';
import { FC, memo, useCallback, useEffect, useState } from 'react';
import Accordion from '../../components/Accordion';
import FlexBox from '../../components/FlexBox';
import { renderFullName } from '../../helpers/format';
import { RecordObject } from '../../types';
import { Branch } from '../../types/reports';
import ReportCheckAllButton from './ReportCheckAllButton';

interface Props {
  branch: Branch;
  selectedBranches: 'all' | 'none' | 'partial';
  updateSelection: (data: any) => void;
}

const ReportBranch: FC<Props> = ({ branch, selectedBranches, updateSelection }) => {
  const [selectedSellers, setSelectedSellers] = useState<number[]>([]);

  useEffect(() => {
    if (selectedBranches === 'all') {
      if (selectedSellers.length !== 0) return;
      setSelectedSellers(branch.merchant_branch.children?.items.map((item) => item.id));
    } else if (selectedBranches === 'none') {
      setSelectedSellers([]);
    }
  }, [selectedBranches]);

  useEffect(() => {
    updateSelection({
      branch_id: branch.id,
      bank_branch_seller_ids: selectedSellers,
    });
  }, [selectedSellers]);

  const handleCheck = (e: any) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedSellers((prev) => [...prev, parseInt(value)]);
    } else {
      setSelectedSellers((prev) => prev.filter((id) => id !== parseInt(value)));
    }
  };

  const handleSelectAll = () => {
    const checked = getBranchSelectedStatus() === 'all';
    setSelectedSellers(!checked ? branch.merchant_branch.children?.items.map((item) => item.id) : []);
  };

  const getBranchSelectedStatus = useCallback(() => {
    const selected = selectedSellers.length;
    const sellers = branch.merchant_branch.children?.items.length;
    if (selected === 0) {
      return 'none';
    }
    if (selected === sellers) {
      return 'all';
    }
    return 'partial';
  }, [selectedSellers]);

  return (
    <Accordion
      summary={branch.merchant_branch.name}
      action={
        <ReportCheckAllButton checkedAll={getBranchSelectedStatus()} onClick={handleSelectAll} label="Все сотрудники" />
      }
      details={
        branch.merchant_branch.children && (
          <Stack>
            {branch.merchant_branch.children.items.map((seller: RecordObject, index: number) => (
              <FlexBox
                component={FormLabel}
                alignItems="center"
                key={seller.id}
                sx={{
                  py: 0.625,
                  pl: 1.25,
                  // bgcolor: 'white',
                  borderBottom: '1px solid #E5E5E5',
                  '&:last-of-type': {
                    border: 0,
                  },
                }}
              >
                <Avatar
                  variant="rounded"
                  src={seller.image || '/assets/images/product-image-placeholder.png'}
                  sx={{
                    width: 35,
                    height: 35,
                    borderRadius: 5,
                  }}
                />
                <Typography mr="auto">{renderFullName(seller.branch_seller)}</Typography>
                <Checkbox value={seller.id} checked={selectedSellers.includes(seller.id)} onChange={handleCheck} />
              </FlexBox>
            ))}
          </Stack>
        )
      }
      sx={{
        bgcolor: '#F5F4F5',
        borderRadius: '0 !important',
        borderLeft: '2px solid #F5F4F5',
        boxShadow: 'none',
        '&.Mui-expanded': {
          borderColor: '#2A8684',
        },
        '& .MuiAccordionSummary-root': { bgcolor: 'transparent', p: 1.25, pr: 0 },
        '& .MuiAccordionDetails-root': {
          py: 0.25,
          pl: 5.375,
          pr: 0,
        },
      }}
    />
  );
};

export default memo(ReportBranch);
