import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { urlWithSearchParams } from "../../helpers/format";
import { rtkBaseQuery } from "../../helpers/rtkquery";

export const moduleOperationsApi = createApi({
  reducerPath: "moduleOperationsApi",
  baseQuery: rtkBaseQuery("/module-operations"),
  endpoints: (builder) => ({
    getModuleOperations: builder.query({
      query: (params) => urlWithSearchParams("", params),
    }),
    getModuleOperationById: builder.query({
      query: (id) => `${id}`,
    }),
    createModuleOperation: builder.mutation({
      query: (body) => ({
        url: "",
        method: "post",
        body,
      }),
    }),
    updateModuleOperation: builder.mutation({
      query: (body) => ({
        url: `${body.id}`,
        method: "put",
        body,
      }),
    }),
  }),
});

export const {
  useGetModuleOperationsQuery,
  useGetModuleOperationByIdQuery,
  useCreateModuleOperationMutation,
  useUpdateModuleOperationMutation,
} = moduleOperationsApi;
