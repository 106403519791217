import { ChevronRight } from '@mui/icons-material';
import { Button, Checkbox, Collapse, TableCell, TableRow } from '@mui/material';
import { FC, useState } from 'react';
import { Column } from '../types';

interface Props {
  row: any;
  cols: Column[];
  selectable?: boolean;
  actions?: (item: any) => void;
  expandRow?: (row: any) => React.ReactNode;
}

const SplitTableRow: FC<Props> = ({ row, cols, selectable = false, actions, expandRow }) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggleExpand = () => {
    setExpanded((prev) => !prev);
  };
  return (
    <>
      <TableRow
        key={row.id}
        sx={{
          borderTop: '4px solid #F5F4F5',
          '& td:first-of-type': {
            borderTopLeftRadius: 5,
            borderBottomLeftRadius: 5,
          },
        }}
      >
        {selectable && (
          <TableCell>
            <Checkbox sx={{ p: 0 }} />
          </TableCell>
        )}
        {expandRow && (
          <TableCell>
            <Button
              variant="contained"
              size="large"
              color="secondary"
              sx={{ p: 0, transition: '0.2s', transform: expanded ? 'rotate(-90deg)' : 'rotate(90deg)' }}
              onClick={handleToggleExpand}
            >
              <ChevronRight />
            </Button>
          </TableCell>
        )}
        {cols.map((col) => (
          <TableCell key={col.id} align={col.align || ('left' as any)}>
            {col.renderCell ? col.renderCell(row) : row[col.id]}
          </TableCell>
        ))}
        {actions && (
          <TableCell>
            <Button variant="outlined" sx={{ p: 0 }} onClick={() => actions(row)}>
              <ChevronRight />
            </Button>
          </TableCell>
        )}
      </TableRow>

      {expandRow && (
        <TableRow>
          <TableCell
            colSpan={cols.length + 1 + (selectable ? 1 : 0) + (actions ? 1 : 0)}
            sx={{ p: '0 !important', bgcolor: 'none' }}
          >
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              {expandRow(row)}
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default SplitTableRow;
