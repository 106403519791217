import { Box, Paper, Stack, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  useActivateMerchantBranchMutation,
  useGetMerchantBranchQuery,
} from "../../app/api/merchantBranchesApi";
import DocumentsTable from "../../components/DocumentsTable";
import FormPageLayout from "../../components/FormPageLayout";
import { IFile } from "../../types/merchants";

const BranchActivationPage: FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { data } = useGetMerchantBranchQuery(id);
  const navigate = useNavigate();

  const [activate] = useActivateMerchantBranchMutation();

  const [files, setFiles] = useState<IFile[]>([]);

  useEffect(() => {
    if (data) {
      setFiles(data.data.merchant_branch.attachments);
    }
  }, [data]);

  return (
    <FormPageLayout
      title={t("branches.activation", "Активация банка")}
      onSave={() => {
        const fileIds = files.map((file) => file.id);
        activate({ id, attachment_ids: fileIds })
          .unwrap()
          .then((res) => navigate("/dashboard/branches"))
          .catch(console.error);
      }}
      onClose={() => navigate("/dashboard/branches")}
    >
      <Box component="section" mb={2} id={id}>
        <Stack direction="row" alignItems="center" gap={2}>
          <Typography variant="h4" p={2.5}>
            Документы
          </Typography>
        </Stack>
        <Box
          component={Paper}
          bgcolor="white"
          elevation={0}
          width={1}
          px={2.5}
          py={3}
        >
          <DocumentsTable
            data={files}
            setData={setFiles}
            documentTypes={{ power_of_attorney: "Доверенность подписанный" }}
          />
        </Box>
      </Box>
    </FormPageLayout>
  );
};

export default BranchActivationPage;
