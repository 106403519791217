import { FC } from 'react';
import BankForm from '../../components/banks/BankForm';

interface Props {}

const BanksCreatePage: FC<Props> = () => {
  return <BankForm />;
};

export default BanksCreatePage;
