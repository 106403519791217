import { Avatar } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useGetBanksQuery } from '../../app/api/banksApi';
import DetailsButton from '../../components/DetailsButton';
import { BankIcon } from '../../components/Icons';
import ListLayout from '../../components/ListLayout';
import BankInfo from '../../components/banks/BankInfo';
import { useDrawer } from '../../contexts/DrawerContext';
import { renderCompanyName, renderFullName } from '../../helpers/format';
import { Column } from '../../types';

interface Props {}

const BanksListPage: FC<Props> = () => {
  const { openDrawer } = useDrawer();
  const { t } = useTranslation();

  const cols: Column[] = [
    {
      id: 'id',
      label: 'ID',
      width: 60,
    },
    {
      id: 'logo',
      label: '',
      width: 92,
      renderCell: (data: any) => (
        <Avatar
          variant="rounded"
          src={data.merchant.logo?.url || '/assets/images/product-image-placeholder.png'}
          sx={{
            width: 60,
            height: 40,
            borderRadius: 1,
            // mr: -2,
          }}
        />
      ),
    },
    {
      id: 'name',
      label: t('name', 'Наименование'),
      renderCell: (data: any) => (
        <Link to={`/dashboard/branches?parent_id=${data.id}`}>{renderCompanyName(data.merchant)}</Link>
      ),
      width: 300,
    },
    {
      id: 'contract_number',
      label: t('contractNumber', 'Номер договора'),
      renderCell: (data: any) => data.merchant.contract_number,
      width: 200,
    },
    {
      id: 'mfo',
      label: t('mfo', 'МФО'),
      renderCell: (data: any) => data.merchant.bank_mfo,
      width: 100,
    },
    {
      id: 'contact_person',
      label: t('contactPerson', 'Контактное лицо'),
      renderCell: (data: any) => renderFullName(data.merchant, 'contact_1'),
      width: 350,
    },
    {
      id: 'phone',
      label: t('phone', 'Телефон'),
      renderCell: (data: any) => data.merchant.contact_1_phone,
      width: 150,
    },
    {
      id: 'is_active_api',
      label: t('isActiveApi', 'Активен API'),
      renderCell: (data: any) => (data.merchant.is_active_api ? 'Да' : 'Нет'),
      // width: 150,
    },
    {
      id: 'action',
      label: '',
      renderCell: (data: any) => <DetailsButton onClick={() => handleInfo(data)} />,
      align: 'right',
    },
  ];

  const handleInfo = (data: any) => {
    openDrawer({
      content: <BankInfo data={data} />,
    });
  };

  return (
    <ListLayout
      query={useGetBanksQuery}
      icon={<BankIcon />}
      title={t('banks', 'Банки')}
      cols={cols}
      createPermission="UserMerchantBank@store"
      // expandRow={(row) => <BankBranchesRow row={row} />}
    />
  );
};

export default BanksListPage;
