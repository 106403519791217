import { List, styled } from "@mui/material";

const NavList = styled(List)({
  padding: 0,
  display: "flex",
  flexDirection: "column",
  gap: 1,
  "& + .MuiList-root": {
    marginTop: 24,
  },
});

export default NavList;
