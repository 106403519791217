import { Avatar, Box, Checkbox, FormLabel, Stack } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import Accordion from '../../components/Accordion';
import FlexBox from '../../components/FlexBox';
import { renderCompanyName } from '../../helpers/format';
import { RecordObject } from '../../types';
import ReportCheckAllButton from './ReportCheckAllButton';

interface Props {
  insurances?: RecordObject[];
  updateInsurances: (ids: number[]) => void;
}

const ReportInsurances: FC<Props> = ({ insurances, updateInsurances }) => {
  const [checkedAll, setCheckedAll] = useState<'all' | 'none' | 'partial'>('none');
  const [selectedIds, setSelectedIds] = useState<number[]>([]);

  const handleSelectAll = () => {
    if (checkedAll !== 'all') {
      setSelectedIds(insurances?.map((item) => item.id) ?? []);
    } else {
      setSelectedIds([]);
    }
  };

  const handleCheck = (e: any) => {
    const { value, checked } = e.target;
    setSelectedIds((prev) => (checked ? [...prev, Number(value)] : prev.filter((id) => id !== Number(value))));
  };

  useEffect(() => {
    if (selectedIds.length === 0) setCheckedAll('none');
    else if (selectedIds.length === insurances?.length) setCheckedAll('all');
    else setCheckedAll('partial');

    updateInsurances(selectedIds);
  }, [selectedIds]);

  if (!insurances) return null;

  return (
    <div>
      <Accordion
        summary="Страховые компании"
        action={
          <ReportCheckAllButton checkedAll={checkedAll} onClick={handleSelectAll} label="Все страховые компании" />
        }
        details={
          <Stack gap={0.5}>
            {insurances.map((insurance: any) => (
              <FlexBox
                component={FormLabel}
                sx={{
                  bgcolor: 'background.default',
                  alignItems: 'center',
                  gap: 1.25,
                  py: 0.625,
                  pr: 2.5,
                  pl: 1.25,
                  cursor: 'pointer',
                }}
                key={insurance.id}
              >
                <Avatar
                  variant="rounded"
                  src={insurance.merchant.logo?.url || '/assets/images/product-image-placeholder.png'}
                  sx={{
                    width: 60,
                    height: 40,
                    borderRadius: 1,
                  }}
                />

                <Box mr="auto">{renderCompanyName(insurance.merchant)}</Box>

                <Stack>
                  <Checkbox
                    sx={{ p: 0 }}
                    checked={selectedIds.includes(insurance.id)}
                    value={insurance.id}
                    onClick={handleCheck}
                  />
                </Stack>
              </FlexBox>
            ))}
          </Stack>
        }
      />
    </div>
  );
};

export default ReportInsurances;
