import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { urlWithSearchParams } from "../../helpers/format";
import { rtkBaseQuery } from "../../helpers/rtkquery";

export const roleOperationsApi = createApi({
  reducerPath: "roleOperationsApi",
  baseQuery: rtkBaseQuery("/role-operations"),
  endpoints: (builder) => ({
    getRoleOperations: builder.query({
      query: (params) => urlWithSearchParams("", params),
    }),
    getRoleOperationById: builder.query({
      query: (id) => `${id}`,
    }),
    createRoleOperation: builder.mutation({
      query: (body) => ({
        url: "",
        method: "post",
        body,
      }),
    }),
    updateRoleOperation: builder.mutation({
      query: (body) => ({
        url: `${body.id}`,
        method: "put",
        body,
      }),
    }),
  }),
});

export const {
  useGetRoleOperationsQuery,
  useGetRoleOperationByIdQuery,
  useCreateRoleOperationMutation,
  useUpdateRoleOperationMutation,
} = roleOperationsApi;
