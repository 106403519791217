import { EditOutlined } from '@mui/icons-material';
import { Avatar, Box, Button, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTE, getRoute } from '../../constants/routes';
import { useDrawer } from '../../contexts/DrawerContext';
import { renderFullName } from '../../helpers/format';
import DataSetCard from '../DataSetCard';
import FlexBox from '../FlexBox';

interface Props {
  data: any;
}

const BranchInfo: FC<Props> = ({ data }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { closeDrawer } = useDrawer();

  const handleEdit = () => {
    navigate(getRoute(ROUTE.BRANCHES, data.id, 'edit'));
    closeDrawer();
  };

  return (
    <Stack width={466} px={2.5} py={3.5} gap={3}>
      <FlexBox alignItems="flex-start" gap={2.5}>
        <Avatar
          src={data.merchant_branch?.logo?.url}
          variant="square"
          sx={{ width: 80, height: 60, '& img': { objectFit: 'contain' } }}
        />
        <Stack gap={0.5} alignItems="flex-start">
          <Typography variant="h3">{data.merchant_branch.name}</Typography>
          <Typography variant="body2" color="#999999">
            ID: {data.id}
          </Typography>
        </Stack>

        <Box display="flex" gap={2.5} ml="auto">
          <Button variant="contained" size="large" sx={{ width: 45, height: 45 }} onClick={handleEdit}>
            <EditOutlined />
          </Button>
        </Box>
      </FlexBox>

      <Stack gap={1}>
        <Typography variant="h6" color="#424242" textTransform="uppercase" mb={1} pl={2.5} letterSpacing={'0.05em'}>
          {t('description', 'Описание')}
        </Typography>

        <Typography pl={2.5}>{data.merchant_branch.description?.[i18n.language]}</Typography>
      </Stack>

      <Stack gap={1}>
        <Typography variant="h6" color="#424242" textTransform="uppercase" mb={1} pl={2.5} letterSpacing={'0.05em'}>
          {t('address', 'Адрес')}
        </Typography>

        <Typography pl={2.5}>{data.merchant_branch.company_legal_address}</Typography>
      </Stack>

      <Stack gap={1}>
        <Typography variant="h6" color="#424242" textTransform="uppercase" mb={1} pl={2.5} letterSpacing={'0.05em'}>
          {t('contact_1', 'Контактное лицо 1')}
        </Typography>

        <DataSetCard
          data={[
            {
              label: t('fullName', 'ФИО'),
              value: renderFullName(data.merchant_branch, 'contact_1'),
            },
            {
              label: t('phone', 'Телефон'),
              value: data.merchant_branch.contact_1_phone,
            },
            {
              label: t('email', 'Email'),
              value: data.merchant_branch.contact_1_email,
            },
          ]}
        />
      </Stack>

      {(renderFullName(data.merchant_branch, 'contact_2').trim() !== '' ||
        data.merchant_branch.contact_2_phone ||
        data.merchant_branch.contact_2_email) && (
        <Stack gap={1}>
          <Typography variant="h6" color="#424242" textTransform="uppercase" mb={1} pl={2.5} letterSpacing={'0.05em'}>
            {t('contact_2', 'Контактное лицо 2')}
          </Typography>

          <DataSetCard
            data={[
              {
                label: t('fullName', 'ФИО'),
                value: renderFullName(data.merchant_branch, 'contact_2'),
              },
              {
                label: t('phone', 'Телефон'),
                value: data.merchant_branch.contact_2_phone,
              },
              {
                label: t('email', 'Email'),
                value: data.merchant_branch.contact_2_email,
              },
            ]}
          />
        </Stack>
      )}
    </Stack>
  );
};

export default BranchInfo;
