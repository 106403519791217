import { FC } from "react";
import { useGetScoringFieldsQuery } from "../../app/api/scoringsApi";
import ScoringForm from "../../components/scoring/ScoringForm";

interface Props {}

const ScoringCreatePage: FC<Props> = () => {
  const { data: fieldsData, isFetching } = useGetScoringFieldsQuery({});

  if (isFetching || !fieldsData) return null;

  return <ScoringForm fields={fieldsData.data.scoring_fields} isNew />;
};

export default ScoringCreatePage;
