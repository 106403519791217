import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { rtkBaseQuery } from '../../helpers/rtkquery';

export const statisticsApi = createApi({
  reducerPath: 'statisticsApi',
  baseQuery: rtkBaseQuery('/dashboards'),
  endpoints: (builder) => ({
    getStatistics: builder.query({
      query: (params) => ({
        url: '',
        params,
      }),
    }),
    getStatisticsAppAll: builder.query({
      query: (params) => ({
        url: 'app-all',
        params,
      }),
      keepUnusedDataFor: 0,
    }),
    getStatisticsAppDynInsCount: builder.query({
      query: (params) => ({
        url: 'app-dyn-ins-count',
        params,
      }),
      keepUnusedDataFor: 0,
    }),
    getStatisticsAppDynInsPremium: builder.query({
      query: (params) => ({
        url: 'app-dyn-ins-premium',
        params,
      }),
      keepUnusedDataFor: 0,
    }),
    getStatisticsAppDynBankCount: builder.query({
      query: (params) => ({
        url: 'app-dyn-bank-count',
        params,
      }),
      keepUnusedDataFor: 0,
    }),
    getStatisticsAppDynBankPremium: builder.query({
      query: (params) => ({
        url: 'app-dyn-bank-premium',
        params,
      }),
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  useGetStatisticsQuery,
  useGetStatisticsAppAllQuery,
  useGetStatisticsAppDynInsCountQuery,
  useGetStatisticsAppDynInsPremiumQuery,
  useGetStatisticsAppDynBankCountQuery,
  useGetStatisticsAppDynBankPremiumQuery,
} = statisticsApi;
