import BranchSellerActivationPage from './ActivationPage';
import BranchSellerCreatePage from './CreatePage';
import SellersListPage from './ListPage';
import BanksUpdatePage from './UpdatePage';

const Sellers = {
  List: SellersListPage,
  Create: BranchSellerCreatePage,
  Activate: BranchSellerActivationPage,
  Update: BanksUpdatePage,
};

export default Sellers;
