import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { objectToFormData } from '../../helpers/form';
import { rtkBaseQuery } from '../../helpers/rtkquery';
import { urlWithSearchParams } from './../../helpers/format';

export const banksApi = createApi({
  reducerPath: 'banksApi',
  baseQuery: rtkBaseQuery('/banks'),
  tagTypes: ['Banks'],
  endpoints: (builder) => ({
    getBanks: builder.query({
      query: (props) => urlWithSearchParams('', props),
      providesTags: ['Banks'],
    }),
    getBank: builder.query({
      query: (id) => `${id}`,
      providesTags: (_result, _error, { id }) => [{ type: 'Banks', id }],
    }),
    createBank: builder.mutation({
      query: (body) => ({
        url: '',
        method: 'POST',
        body: objectToFormData(body),
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: 'Banks', id }],
    }),
    activateBank: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `${id}/activate`,
        // method: "PATCH",
        method: 'POST',
        body: { ...body, _method: 'PATCH' },
      }),
      invalidatesTags: (_result, _error, { id }) => ['Banks', { type: 'Banks', id }],
    }),
    updateBank: builder.mutation({
      query: (body) => ({
        url: `${body.id}`,
        // method: 'PUT',
        method: 'POST',
        body: objectToFormData({ ...body, _method: 'PUT' }),
      }),
      invalidatesTags: (_result, _error, { id }) => ['Banks', { type: 'Banks', id }],
    }),
    patchBank: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (_result, _error, { id }) => ['Banks', { type: 'Banks', id }],
    }),
    deleteBank: builder.mutation({
      query: (id) => ({
        url: `${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Banks'],
    }),

    setBankApiStatus: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `${id}/status-api`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (_result, _error, { id }) => ['Banks', { type: 'Banks', id }],
    }),

    getBanksDropdown: builder.query({
      query: () => '/dropdown',
    }),
  }),
});

export const {
  useGetBanksQuery,
  useGetBankQuery,
  useCreateBankMutation,
  useActivateBankMutation,
  useUpdateBankMutation,
  usePatchBankMutation,
  useDeleteBankMutation,
  useSetBankApiStatusMutation,
  useGetBanksDropdownQuery,
} = banksApi;
