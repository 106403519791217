import { createApi } from "@reduxjs/toolkit/query/react";
import { urlWithSearchParams } from "../../helpers/format";
import { rtkBaseQuery } from "../../helpers/rtkquery";

export const usersApi = createApi({
  reducerPath: "usersApi",
  baseQuery: rtkBaseQuery("/users"),
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: (params) => urlWithSearchParams("", params),
    }),
    getUserById: builder.query({
      query: (id) => `${id}`,
    }),
    createUser: builder.mutation({
      query: (body) => ({
        url: "",
        method: "post",
        body,
      }),
    }),
    updateUser: builder.mutation({
      query: (body) => ({
        url: `${body.id}`,
        method: "put",
        body,
      }),
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetUserByIdQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
} = usersApi;
