import { Add, DeleteOutlined, Download } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDeleteAttachmentMutation } from '../app/api/attachmentsApi';
import { useDialog } from '../hooks/useDialog';
import { Column, RecordObject } from '../types';
import { IFile } from '../types/merchants';
import FileForm from './FileForm';
import FormDialog from './FormDialog';
import IconButton from './IconButton';

interface Props {
  data: IFile[];
  setData: (data: IFile[]) => void;
  documentTypes: RecordObject;
}

const DocumentsTable: FC<Props> = ({ data, setData, documentTypes }) => {
  const { t } = useTranslation();
  const { openDialog, closeDialog, renderDialog } = useDialog();
  const [rows, setRows] = useState<IFile[]>([]);
  const [selected, setSelected] = useState<number[]>([]);
  const [selectedAll, setSelectedAll] = useState(false);
  const [deleteAttachment] = useDeleteAttachmentMutation();

  useEffect(() => {
    setRows(data);
  }, [data]);

  useEffect(() => {
    if (rows.length > 0) setData(rows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  const cols: Column[] = [
    {
      id: 'file',
      label: 'Файл',
      renderCell: (data) => (
        <Button download href={data.url} variant="contained" startIcon={<Download />}>
          {t('download', 'Скачать')}
        </Button>
      ),
    },
    {
      id: 'description',
      label: t('description', 'Описание'),
    },
    {
      id: 'document_type',
      label: t('type', 'Тип'),
      renderCell: (data) => documentTypes[data.document_type],
    },
  ];

  const handleAdd = () => {
    openDialog(
      <FormDialog title={t('files.add', 'Добавить файл')} type={'add'}>
        <FileForm
          setItem={(item: IFile) => {
            setRows([...rows, item]);
          }}
          onClose={closeDialog}
          types={documentTypes}
        />
      </FormDialog>
    );
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedAll(event.target.checked);
  };

  const handleSelect = (index: number, checked: boolean) => {
    if (checked) {
      if (selected.indexOf(index) === -1) {
        setSelected([...selected, index]);

        if (selected.length === rows.length - 1) setSelectedAll(true);
      }
    } else {
      setSelected(selected.filter((i) => i !== index));

      if (selectedAll) setSelectedAll(false);
    }
  };

  // const handleDelete = (index: number) => {
  //   setRows((prev) => prev.filter((_, i) => i !== index));
  // };
  const handleDelete = (id: number) => {
    deleteAttachment(id)
      .unwrap()
      .then(() => {
        setRows((prev) => prev.filter((item) => item.id !== id));
      })
      .catch();
  };

  const handleDeleteSelected = () => {
    const orderedSelected = selected.sort((a, b) => b - a);
    orderedSelected.forEach((i) => handleDelete(i));
    setSelected([]);
    setSelectedAll(false);
  };

  return (
    <Stack>
      {rows.length > 0 ? (
        <TableContainer>
          <Table>
            <TableHead
              sx={{
                '& .MuiTableCell-root': {
                  paddingTop: 0,
                  paddingBottom: 1,
                },
              }}
            >
              <TableRow>
                <TableCell width={30} padding="checkbox">
                  <Checkbox onChange={handleSelectAll} checked={selectedAll} />
                </TableCell>
                {cols.map((col) => (
                  <TableCell key={col.id}>{col.label}</TableCell>
                ))}
                <TableCell width={30}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row: any, index) => (
                <TableRow key={index}>
                  <TableCell padding="checkbox">
                    {!row.is_generated && (
                      <Checkbox
                        checked={selectedAll || selected.indexOf(index) !== -1}
                        onChange={(e) => handleSelect(index, e.target.checked)}
                      />
                    )}
                  </TableCell>
                  {cols.map((col) => (
                    <TableCell key={col.id}>{col.renderCell ? col.renderCell(row) : row[col.id]}</TableCell>
                  ))}
                  <TableCell>
                    {!row.is_generated && (
                      <IconButton variant="outlined" onClick={() => handleDelete(row.id)} size="medium" color="error">
                        <DeleteOutlined />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell></TableCell>
                <TableCell colSpan={4}>
                  <Stack direction="row" gap={1}>
                    {selected.length > 0 && (
                      <Button variant="outlined" onClick={handleDeleteSelected} color="error" sx={{ ml: -6.5 }}>
                        Удалить выбранные
                      </Button>
                    )}
                    <Button variant="outlined" onClick={handleAdd} startIcon={<Add />}>
                      Добавить документ
                    </Button>
                  </Stack>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Paper sx={{ py: 1.5, px: 2.5 }}>
          <Button variant="outlined" onClick={handleAdd}>
            Добавить
          </Button>
        </Paper>
      )}
      {renderDialog()}
    </Stack>
  );
};

export default DocumentsTable;
