import { TypographyOptions } from "@mui/material/styles/createTypography";

const typography: TypographyOptions = {
  fontSize: 16,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  h1: {
    fontSize: 46,
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: -1.84,
  },
  h2: {
    fontSize: 36,
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: -1.44,
  },
  h3: {
    fontSize: 26,
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: -1.04,
  },
  h4: {
    fontSize: 20,
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: -0.8,
  },
  h5: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: -0.64,
  },
  h6: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: -0.56,
  },
  subtitle1: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 1.4,
  },
  subtitle2: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: 1.4,
  },
  body1: {
    fontSize: 16,
    lineHeight: 1.4,
  },
  body2: {
    fontSize: 14,
    lineHeight: 1.4,
  },
  button: {
    fontSize: 12,
    fontWeight: 500,
    textTransform: "none",
  },
  caption: {
    fontSize: 12,
    lineHeight: 1,
  },
  overline: {
    fontSize: 10,
    lineHeight: 1.4,
  },
};

export default typography;
