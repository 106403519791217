import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { urlWithSearchParams } from "../../helpers/format";
import { rtkBaseQuery } from "../../helpers/rtkquery";

export const userOperationsApi = createApi({
  reducerPath: "userOperationsApi",
  baseQuery: rtkBaseQuery("/user-operations"),
  endpoints: (builder) => ({
    getUserOperations: builder.query({
      query: (params) => urlWithSearchParams("", params),
    }),
    getUserOperationById: builder.query({
      query: (id) => `${id}`,
    }),
    createUserOperation: builder.mutation({
      query: (body) => ({
        url: "",
        method: "post",
        body,
      }),
    }),
    updateUserOperation: builder.mutation({
      query: (body) => ({
        url: `${body.id}`,
        method: "put",
        body,
      }),
    }),
  }),
});

export const {
  useGetUserOperationsQuery,
  useGetUserOperationByIdQuery,
  useCreateUserOperationMutation,
  useUpdateUserOperationMutation,
} = userOperationsApi;
