import { format } from 'date-fns';
import React from 'react';
import { RecordObject } from './../types/common.d';
export const urlWithSearchParams = (url: string, params: Record<string, any>) => {
  const urlParts = url.split('?');
  const urlParams = new URLSearchParams(urlParts[1]);
  const newUrlParams = Object.entries(params).reduce((acc, [key, value]) => {
    if (value) {
      acc.set(key, value);
    }
    return acc;
  }, urlParams);

  return `${urlParts[0]}?${newUrlParams.toString()}`;
};

export const deepMergeObjects = (targetObject: RecordObject, source: RecordObject) => {
  const target = { ...targetObject };
  const isObject = (obj: RecordObject) => obj && typeof obj === 'object';

  if (!isObject(target) || !isObject(source)) {
    return source;
  } else {
    Object.keys(source).forEach((key) => {
      const targetValue = target[key];
      const sourceValue = source[key];

      if (!targetValue) {
        target[key] = sourceValue;
        return;
      }

      if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
        target[key] = targetValue.concat(sourceValue);
      } else if (isObject(targetValue) && isObject(sourceValue)) {
        target[key] = deepMergeObjects(Object.assign({}, targetValue), sourceValue);
      } else {
        target[key] = sourceValue;
      }
    });

    // Add any properties in source that are not already in target
    Object.keys(source).forEach((key) => {
      if (!target.hasOwnProperty(key)) {
        target[key] = source[key];
      }
    });
  }

  // console.log("target", deepReplaceNullWithEmptyString(target));
  return target;
  // return deepReplaceNullWithEmptyString(target);
};

// deep replace object props values with empty string if they are null
export const deepReplaceNullWithEmptyString = (targetObject: RecordObject): RecordObject => {
  const target = { ...targetObject } || {};

  const isObject = (obj: RecordObject): boolean => obj && typeof obj === 'object';

  if (!isObject(target)) {
    return target;
  }

  for (const [key, value] of Object.entries(target)) {
    if (value === null) {
      target[key] = '';
    } else if (isObject(value)) {
      // console.log("value", key, value);
      target[key] = deepReplaceNullWithEmptyString(value);
    }
  }

  return target;
};

export const renderFullName = (user: RecordObject, prefix?: string) => {
  let firstName = user[prefix ? prefix + '_first_name' : 'first_name'] || '';
  let lastName = user[prefix ? prefix + '_last_name' : 'last_name'] || '';
  let middleName = user[prefix ? prefix + '_second_name' : 'second_name'] || '';

  return `${lastName} ${firstName} ${middleName}`;
};

export const renderCompanyName = (data: RecordObject) => {
  if (!data) return null;

  const { organization_type: orgType, company_type: type, company_name: name } = data;
  if (!['bank', 'insurance'].includes(orgType)) return null;

  return [type, name].filter(Boolean).join(' ');
};

export const renderPhoneLink = (phone: string) => {
  if (!phone) return null;

  return React.createElement('a', { href: `tel:${phone}` }, phone);
};

export const dateFormat = (date: string, dateFormat?: string) => {
  if (!date) {
    return null;
  }

  return format(new Date(date), dateFormat || 'dd.MM.yyyy');
};

export const parseLocalizedDate = (date: string) => {
  if (!date) {
    return null;
  }
  const [day, month, year] = date.split('.');
  return new Date(`${year}-${month}-${day}`);
};
