import { ArrowForwardIosSharp } from '@mui/icons-material';
import {
  AccordionProps,
  Box,
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionProps as MuiAccordionProps,
  AccordionSummary as MuiAccordionSummary,
  AccordionSummaryProps as MuiAccordionSummaryProps,
  Stack,
  styled,
} from '@mui/material';
import { FC, ReactNode, useState } from 'react';

interface Props {
  summary: ReactNode;
  details: ReactNode;
  action?: ReactNode;
}

const Accordion: FC<Props & Partial<AccordionProps>> = ({ summary, details, action, ...props }) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => setExpanded((prev) => !prev);

  return (
    <StyledAccordion expanded={expanded} onChange={handleToggle} {...props}>
      <StyledAccordionSummary>
        <Box>{summary}</Box>
        {action && <Box ml="auto">{action}</Box>}
      </StyledAccordionSummary>
      <StyledAccordionDetails>{details}</StyledAccordionDetails>
    </StyledAccordion>
  );
};

export default Accordion;

const StyledAccordion = styled((props: MuiAccordionProps) => <MuiAccordion disableGutters {...props} />)(
  ({ theme }) => ({
    background: '#F5F4F5',
    borderRadius: '10px',
    boxShadow: '0px 5px 10px 0px rgba(0, 0, 0, 0.10)',
    overflow: 'hidden',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  })
);

const StyledAccordionSummary = styled((props: MuiAccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={
      <Stack
        sx={{
          width: 35,
          height: 35,
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '9px',
          background: '#F5F5F5',
        }}
      >
        <ArrowForwardIosSharp sx={{ fontSize: 15 }} />
      </Stack>
    }
    {...props}
  />
))(({ theme }) => ({
  padding: theme.spacing(2, 2.5),
  backgroundColor: 'rgba(255, 255, 255, 1)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(-90deg)',
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
    marginLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
}));

const StyledAccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2.5),
  borderTop: '1px solid #E5E5E5',
}));
