import { FC } from 'react';
import { Line } from 'react-chartjs-2';
import { LineChartItem, LineChartItemRows } from '../../../types/statistics';
import { ChartMonths, LineChartColors } from '../vars';

const LINE_CHART_OPTIONS = {
  // responsive: true,
  interaction: {
    mode: 'index' as const,
    intersect: false,
  },
  layout: {
    padding: 0,
  },
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      display: true,
      gridLines: {
        display: false,
      },
    },
  },
};

const initialDataRows = Object.entries(ChartMonths).reduce((acc, [code, label]) => {
  acc[code] = {
    value: 0,
    label: label.slice(0, 3),
    code,
  };
  return acc;
}, {} as LineChartItemRows);

interface Props {
  data: Record<'all' | string, LineChartItem>;
}

const LineChart: FC<Props> = ({ data }) => {
  if (!data) return null;

  const chartData = {
    labels: Object.values(initialDataRows).map((item) => item.label),
    datasets: Object.values(data)
      .filter((item: any) => item.code !== 'all')
      .map((item: LineChartItem, index: number) => {
        const dataValues = { ...initialDataRows, ...item.items };

        const sortedDataValues = Object.keys(dataValues)
          .sort()
          .map((code) => dataValues[code].value);

        return {
          label: item.label,
          data: sortedDataValues,
          borderColor: LineChartColors[index],
          backgroundColor: LineChartColors[index],
          yAxisID: 'y',
          tension: 0.2,
          borderDash: [10, 8],
        };
      }),
  };

  return <Line options={LINE_CHART_OPTIONS} data={chartData} height={177} width={830} />;
};

export default LineChart;
