import { FC } from "react";
import ProductForm from "../../components/products/ProductForm";

interface CreateProps {}

const ProductsCreatePage: FC<CreateProps> = () => {
  return <ProductForm isNew data={{}} />;
};

export default ProductsCreatePage;
