import { Box, BoxProps, styled } from "@mui/material";
import { FC } from "react";

const GreyCard: FC<BoxProps> = ({ children, ...props }) => {
  return <StyledCard {...props}>{children}</StyledCard>;
};

export default GreyCard;

const StyledCard = styled(Box)({
  backgroundColor: "#F5F4F7",
  padding: "18px 20px 10px",
  borderRadius: 20,
});
