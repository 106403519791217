import { Components } from '@mui/material';
import { createElement } from 'react';
import { CheckboxCheckedIcon, CheckboxIcon } from '../../components/Icons';

const components: Components = {
  MuiCssBaseline: {
    styleOverrides: {
      '*, *::before, *::after': {
        boxSizing: 'border-box',
      },
      a: {
        textDecoration: 'none',
      },
      body: {
        backgroundColor: '#F5F4F5',
      },
    },
  },
  MuiContainer: {
    styleOverrides: {
      root: {},
    },
  },
  MuiPaper: {
    defaultProps: {
      elevation: 0,
    },
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true,
    },
    styleOverrides: {
      root: {
        minWidth: 0,
        textTransform: 'none',
      },
    },
  },
  MuiCheckbox: {
    defaultProps: {
      icon: createElement(CheckboxIcon),
      checkedIcon: createElement(CheckboxCheckedIcon),
    },
  },
  MuiTableContainer: {},
  MuiTable: {},
  MuiTableHead: {
    styleOverrides: {
      root: {
        '& .MuiTableCell-root': {
          border: 'none',
          color: '#999999',
        },
        '& .MuiTableSortLabel-root': {
          color: '#999999',
        },
      },
    },
  },
  MuiTableBody: {
    styleOverrides: {
      root: {
        '& .MuiTableCell-root': {
          backgroundColor: 'white',
          borderColor: '#F0F0F0',
          padding: 16,
        },
        '& .MuiTableRow-root:last-child .MuiTableCell-root': {
          border: 'none',
        },
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        '& .MuiOutlinedInput-input': {
          backgroundColor: '#FFFFFF',
          borderRadius: 8,
        },
        '& .MuiInputLabel-root.Mui-error, & .MuiFormHelperText-root.Mui-error': {
          color: '#F44336',
        },
        '& .MuiInputBase-root.Mui-error': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#F44336',
          },
        },
      },
    },
  },
  MuiAlert: {
    styleOverrides: {
      filledError: {
        backgroundColor: '#F44336',
        color: 'white',
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        borderRadius: 6,
      },
      colorSuccess: {
        backgroundColor: '#CBFED3',
        color: '#16A05E',
      },
      colorSecondary: {
        backgroundColor: '#D6ECFE',
        color: '#2D64B7',
      },
      colorInfo: {
        backgroundColor: '#F3F3F3',
        color: '#909090',
      },
      colorWarning: {
        backgroundColor: '#FFEBCC',
        color: '#896329',
      },
      colorError: {
        backgroundColor: '#FEDDDA',
        color: '#B94E49',
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        fontSize: 32,
      },
    },
  },
};

export default components;
