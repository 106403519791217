import { Stack } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import Accordion from '../../components/Accordion';
import { RecordObject } from '../../types';
import { Bank } from '../../types/reports';
import ReportBank from './ReportBank';
import ReportCheckAllButton from './ReportCheckAllButton';
import { DEFAULT_SELECTED_ALL } from './ReportsPage';

interface Props {
  banks?: Bank[];
  updateBanks: (data: any) => void;
}

const ReportBanks: FC<Props> = ({ banks: rawBanks, updateBanks }) => {
  const [banks, setBanks] = useState<RecordObject[]>([]);
  const [selectedBanks, setSelectedBanks] = useState<'all' | 'none' | 'partial'>(DEFAULT_SELECTED_ALL);

  useEffect(() => {
    updateBanks({
      bank_ids: banks.map((item) => item.bank_id),
      bank_branch_ids: banks.flatMap((item) => item.branches.map((branch: any) => branch.branch_id)),
      bank_branch_seller_ids: banks.flatMap((item) =>
        item.branches.flatMap((branch: any) => branch.bank_branch_seller_ids)
      ),
    });
  }, [banks]);

  const selectAllBanks = () => {
    setSelectedBanks((prev) => (prev === 'all' ? 'none' : 'all'));
  };

  const handleUpdateSelection = (bank: {
    bank_id: number;
    branches: { branch_id: number; bank_branch_seller_ids: number[] }[];
  }) => {
    setBanks((prev) => {
      const next = [...prev];
      const index = next.findIndex((item) => item.bank_id === bank.bank_id);
      if (index === -1) {
        next.push(bank);
      } else {
        next[index] = bank;
      }
      return next;
    });
  };

  if (!rawBanks) return null;

  return (
    <Stack>
      <Accordion
        summary="Банки"
        action={<ReportCheckAllButton checkedAll={selectedBanks} onClick={selectAllBanks} label="Все банки" />}
        details={
          <Stack gap={0.25}>
            {rawBanks.map((bank: any) => (
              <ReportBank
                bank={bank}
                key={bank.id}
                selectedBanks={selectedBanks}
                updateSelection={handleUpdateSelection}
              />
            ))}
          </Stack>
        }
      />
    </Stack>
  );
};

export default ReportBanks;
