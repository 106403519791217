import { AppBar, Stack, Tab, Tabs } from "@mui/material";
import { FC, useState } from "react";
import { Outlet } from "react-router-dom";

interface SettingsProps {}

const Settings: FC<SettingsProps> = () => {
  const [tab, setTab] = useState("users");

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };
  return (
    <Stack>
      {false && (
        <AppBar
          // position="fixed"
          sx={{ left: 70 }}
          color="transparent"
          elevation={1}
        >
          {/* <Toolbar>
          <Typography variant="h1">Settings</Typography>
        </Toolbar> */}
          <Tabs value={tab} onChange={handleChangeTab}>
            <Tab label="Users" value={"users"} />
            <Tab label="Roles" value={"roles"} />
            <Tab label="User roles" value={"user-roles"} />
            <Tab label="Modules" value={"modules"} />
          </Tabs>
        </AppBar>
      )}
      <Outlet />
    </Stack>
  );
};

export default Settings;
