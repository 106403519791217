import { EditOutlined } from "@mui/icons-material";
import { Avatar, Box, Button, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useDrawer } from "../../contexts/DrawerContext";
import DataSetCard from "../DataSetCard";
import FlexBox from "../FlexBox";
import ScoringCard from "./ScoringCard";

interface Props {
  data: any;
}

const ScoringInfo: FC<Props> = ({ data }) => {
  const navigate = useNavigate();
  const { closeDrawer } = useDrawer();
  const handleEdit = () => {
    closeDrawer();
    navigate(`/dashboard/scoring/${data.id}/edit`);
  };
  return (
    <Stack width={466} px={2.5} py={3.5} gap={3}>
      <FlexBox alignItems="flex-start" gap={2.5}>
        <Avatar
          src={data.merchant.logo?.url}
          variant="square"
          sx={{ width: 80, height: 60, "& img": { objectFit: "contain" } }}
        />
        <Stack gap={0.5} alignItems="flex-start">
          <Typography variant="h3">{data.merchant.company_name}</Typography>
          <Typography variant="body2" color="#999999">
            ID: {data.id}
          </Typography>
        </Stack>

        <Box display="flex" gap={2.5} ml="auto">
          <Button
            variant="contained"
            size="large"
            sx={{ width: 45, height: 45 }}
            onClick={handleEdit}
          >
            <EditOutlined />
          </Button>
        </Box>
      </FlexBox>

      <Stack gap={1}>
        <Typography
          variant="h6"
          color="#424242"
          textTransform="uppercase"
          mb={1}
          pl={2.5}
          letterSpacing={"0.05em"}
        >
          РЕЙТИНГИ
        </Typography>

        <FlexBox gap={2.5} mb={1.5}>
          <ScoringCard
            label="Международный рейтинг"
            value={data.rating_international}
          />
          <ScoringCard
            label="Национальный рейтинг"
            value={data.rating_national}
          />
        </FlexBox>

        <DataSetCard
          data={[
            { label: "Наименование", value: data.merchant.company_name },
            // { label: "Отчетная дата", value: data.reporting_date_at },
            { label: "Платежеспособность", value: data.solvency },
            { label: "Скоринг балл", value: data.score },
            // {
            //   label: "Международный рейтинг",
            //   value: data.rating_international,
            // },
            // { label: "Национальный рейтинг", value: data.rating_national },
            {
              label: "Чистая прибыль на отчетную дату",
              value: data.net_profit_at_reporting_date,
            },
            {
              label: "Максимальный процент покрытия от портфеля",
              value: data.coverage_percentage_max,
            },
          ]}
        />
      </Stack>
    </Stack>
  );
};

export default ScoringInfo;
