export interface StatusesObject {
  label: string;
  color: any;
}
export type Statuses = Record<string, StatusesObject>;

// applications
// public const STATUS_NEW = 'new'; // Новая заявка
// public const STATUS_POLICY_REQUEST = 'policy_request'; // Заявка на полис сгенерирована
// public const STATUS_POLICY_REQUEST_SENT = 'policy_request_sent'; // Заявка на полис отправлена
// public const STATUS_POLICY_READY = 'policy_ready'; // Полис готов
// public const STATUS_POLICY_ERROR = 'policy_error'; // Ошибка полиса
// public const STATUS_POLICY_INSURANCE_ERROR = 'policy_insurance_error'; // Ошибка
// public const STATUS_POLICY_PAID = 'policy_paid'; // Полис оплачен
// public const STATUS_CANCELLED = 'cancelled'; // Заявка отменена
export const APPLICATION_STATUS: Statuses = {
  new: { label: 'Новая заявка', color: 'secondary' },
  policy_request: { label: 'Заявка на полис сгенерирована', color: 'secondary' },
  policy_request_sent: { label: 'Заявка на полис отправлена', color: 'secondary' },
  policy_ready: { label: 'Полис готов', color: 'secondary' },
  policy_error: { label: 'Ошибка полиса', color: 'error' },
  policy_insurance_error: { label: 'Ошибка', color: 'error' },
  policy_paid: { label: 'Полис оплачен', color: 'success' },
  cancelled: { label: 'Заявка отменена', color: 'info' },
};

// policies
// public const STATUS_READY_TO_PAYMENT = 'ready_to_payment'; // Полис готов к оплате
// public const STATUS_PAID = 'paid'; // Полис оплачен
// public const STATUS_NO_DATA = 'no_data'; // Нет данных полиса
// public const STATUS_PAYMENT_ERROR = 'payment_error'; // Ошибка оплаты
// public const STATUS_CANCELLED = 'cancelled'; // Аннулирован
export const POLICY_STATUS: Statuses = {
  ready_to_payment: { label: 'Полис готов к оплате', color: 'secondary' },
  paid: { label: 'Полис оплачен', color: 'success' },
  no_data: { label: 'Нет данных полиса', color: 'error' },
  payment_error: { label: 'Ошибка оплаты', color: 'error' },
  cancelled: { label: 'Аннулирован', color: 'info' },
};

// export const statuses: RecordObject = {
//   // Заявка
//   new: { label: 'Новая заявка', color: 'secondary' },
//   scoring_ok: { label: 'Скоринг одобрен', color: 'success' },
//   scoring_nok: { label: 'Скоринг не одобрен', color: 'error' },
//   approved: { label: 'Одобрен переход на следующий шаг', color: 'success' },
//   plastic_card: { label: 'Пластиковая карта прикреплена', color: 'success' },
//   documents_attached: { label: 'Документы прикреплены', color: 'success' },
//   act_of_transfer_acceptance_attached: {
//     label: 'Документы прикреплены',
//     color: 'success',
//   },
//   cancelled: { label: 'Заявка отменена', color: 'info' },

//   ready_to_payment: { label: 'Готов к оплате', color: 'secondary' },

//   policy_ready: { label: 'Готов к оплате', color: 'secondary' },
//   policy_paid: { label: 'Оплачено', color: 'success' },

//   // Кредит
//   loan: { label: 'Выдали кредит', color: 'success' },
//   loan_schedule: { label: 'Погашение по графику', color: 'secondary' },
//   loan_completed_ok: { label: 'Кредит завершён успешно', color: 'success' },
//   loan_problem_upto_30: { label: 'Просрочка до 30 дней', color: 'warning' },
//   loan_problem_upto_60: { label: 'Просрочка до 60 дней', color: 'warning' },
//   loan_problem_upto_90: { label: 'Просрочка до 90 дней', color: 'warning' },
//   loan_problem_over_90: { label: 'Просрочка свыше 90 дней', color: 'warning' },
//   loan_completed_nok: { label: 'Кредит завершён не успешно', color: 'error' },

//   // Суд
//   tribunal: { label: 'Подали в суд', color: 'warning' },
//   tribunal_progress: { label: 'Суд в процессе', color: 'warning' },
//   tribunal_cancel: { label: 'Отзыв суда', color: 'warning' },
//   tribunal_completed_ok: {
//     label: 'Суд завершён в нашу пользу',
//     color: 'success',
//   },
//   tribunal_completed_nok: {
//     label: 'Суд завершён в не в нашу пользу',
//     color: 'error',
//   },

//   // МИБ
//   mib: { label: 'Передали документы в МИБ', color: 'warning' },
//   mib_progress: { label: 'МИБ начал делопроизводство', color: 'warning' },
//   mib_completed_ok: {
//     label: 'МИБ успешно взыскал задолженность',
//     color: 'success',
//   },
//   mib_completed_nok: {
//     label: 'МИБ успешно не взыскал задолженность',
//     color: 'error',
//   },
// };
