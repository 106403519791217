import { FC } from "react";
import { useParams } from "react-router-dom";
import { useGetMerchantBranchQuery } from "../../app/api/merchantBranchesApi";
import BranchForm from "../../components/branches/BranchForm";

const BanksUpdatePage: FC = () => {
  const { id } = useParams();
  const { data } = useGetMerchantBranchQuery(id);

  if (!data) return null;

  return <BranchForm data={data.data} />;
};

export default BanksUpdatePage;
