import { FC } from "react";
import { useParams } from "react-router-dom";
import {
  useGetScoringByIdQuery,
  useGetScoringFieldsQuery,
} from "../../app/api/scoringsApi";
import ScoringForm from "../../components/scoring/ScoringForm";

interface Props {}

const ScoringUpdatePage: FC<Props> = () => {
  const { id } = useParams();
  const { data } = useGetScoringByIdQuery(id);
  const { data: fieldsData, isFetching } = useGetScoringFieldsQuery({});

  if (isFetching || !fieldsData) return null;

  if (!data) return null;

  return (
    <ScoringForm fields={fieldsData.data.scoring_fields} data={data.data} />
  );
};

export default ScoringUpdatePage;
