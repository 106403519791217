import { Stack } from "@mui/material";
import { FC, ReactNode } from "react";
import InfoButton from "./InfoButton";

const InfoRow: FC<{
  label: string;
  value: ReactNode;
  infoClick?: () => void;
}> = ({ label, value, infoClick }) => {
  return (
    <Stack direction="row" py={2} alignItems="center">
      <Stack mr={2}>{label}</Stack>
      <Stack textAlign="right" ml="auto">
        {value}
      </Stack>
      {infoClick && <InfoButton onClick={infoClick} />}
    </Stack>
  );
};

export default InfoRow;
