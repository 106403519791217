import BranchActivationPage from './ActivationPage';
import BranchesCreatePage from './CreatePage';
import BranchesListPage from './ListPage';
import BanksUpdatePage from './UpdatePage';

const Branches = {
  List: BranchesListPage,
  Create: BranchesCreatePage,
  Activate: BranchActivationPage,
  Update: BanksUpdatePage,
};

export default Branches;
