import { Search } from '@mui/icons-material';
import { Box, InputBase, alpha, styled } from '@mui/material';
import { FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getSearchParams } from '../helpers/utils';

interface Props {}

const SearchWidget: FC<Props> = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleSearch = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearchParams({ ...getSearchParams(), q: e.target.value });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  return (
    <Box
      component="form"
      sx={{
        position: 'relative',
        border: '1px solid #E4E4E4',
        borderRadius: 1,
        backgroundColor: alpha('#fff', 0.15),
        '&:hover': {
          backgroundColor: alpha('#fff', 0.25),
        },
        marginLeft: 'auto',
        marginRight: 2.5,
        width: '100%',
        maxWidth: 240,
      }}
      onSubmit={handleSubmit}
    >
      <SearchIconWrapper>
        <Search fontSize="small" />
      </SearchIconWrapper>

      <StyledInputBase
        placeholder="Поиск"
        inputProps={{ 'aria-label': 'search' }}
        value={searchParams.get('q') ?? ''}
        onChange={handleSearch}
      />
    </Box>
  );
};

export default SearchWidget;

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 1),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: theme.spacing(4.5),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));
