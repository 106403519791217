import { FileDownloadOutlined } from '@mui/icons-material';
import { Box, Button, MenuItem, Stack, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { format } from 'date-fns';
import { FormikProps, getIn } from 'formik';
import { FC, useEffect, useState } from 'react';
import { useGetProductsQuery } from '../../app/api/productsApi';
import { parseLocalizedDate } from '../../helpers/format';
import { ReportsFormType } from '../../types/reports';

interface Props {
  formik: FormikProps<ReportsFormType>;
  isLoading?: boolean;
}

const ReportsForm: FC<Props> = ({ formik, isLoading }) => {
  const [products, setProducts] = useState([]);
  const { data: productsQuery } = useGetProductsQuery({ end: 9999 });

  useEffect(() => {
    if (productsQuery) setProducts(productsQuery.data.items);
  }, [productsQuery]);

  return (
    <Stack component="form" gap={4} width={274} onSubmit={formik.handleSubmit}>
      <TextField
        label="Продукт"
        select
        sx={{ minWidth: 220 }}
        InputLabelProps={{ shrink: true }}
        SelectProps={{
          displayEmpty: true,
          multiple: true,
        }}
        // value={productId}
        // onChange={(e: any) => setProductId(e.target.value)}
        {...formik.getFieldProps('product_ids')}
        disabled={isLoading}
      >
        <MenuItem value="">Все</MenuItem>
        {products.map((product: any) => (
          <MenuItem key={product.id} value={product.id}>
            {product.name.ru}
          </MenuItem>
        ))}
      </TextField>

      <Box display="flex" gap={2}>
        <DatePicker
          // {...formik.getFieldProps('date_from')}
          value={parseLocalizedDate(getIn(formik.values, 'date_from'))}
          onChange={(date) => date && formik.setFieldValue('date_from', format(date, 'yyyy-mm-dd'))}
          disabled={isLoading}
          // value={dateFrom}
          // onChange={(value) => setDateFrom(value)}
          slotProps={{
            textField: {
              label: 'Дата от',
              // placeholder: '__.__.____',
              InputLabelProps: {
                shrink: true,
              },
              sx: { bgcolor: 'white', width: 170 },
            },
          }}
          sx={{ width: 170 }}
        />
        <DatePicker
          // {...formik.getFieldProps('date_to')}
          value={parseLocalizedDate(getIn(formik.values, 'date_to')) || null}
          onChange={(date) => date && formik.setFieldValue('date_to', format(date, 'yyyy-mm-dd'))}
          disabled={isLoading}
          // value={dateTo}
          // onChange={(value) => setDateTo(value)}
          slotProps={{
            textField: {
              label: 'Дата до',
              // placeholder: '__.__.____',
              InputLabelProps: {
                shrink: true,
              },
              sx: { bgcolor: 'white', width: 170 },
            },
          }}
        />
      </Box>
      <Stack gap={2.5}>
        <Button
          variant="outlined"
          size="large"
          type="reset"
          color="inherit"
          onClick={formik.handleReset}
          sx={{ fontSize: 14, lineHeight: 2.5, borderColor: '#E5E9EB' }}
        >
          Сбросить фильтр
        </Button>

        <Button
          type="submit"
          variant="contained"
          size="large"
          startIcon={<FileDownloadOutlined />}
          sx={{ fontSize: 14, lineHeight: 2.5 }}
          disabled={isLoading}
        >
          Скачать Excel
        </Button>
      </Stack>
    </Stack>
  );
};

export default ReportsForm;
