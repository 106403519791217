import { Avatar, Stack } from '@mui/material';
import { FC } from 'react';
import { useGetClientsQuery } from '../../app/api/clientsApi';
import DetailsButton from '../../components/DetailsButton';
import { CustomersIcon } from '../../components/Icons';
import ListLayout from '../../components/ListLayout';
import ClientInfo from '../../components/clients/ClientInfo';
import { useDrawer } from '../../contexts/DrawerContext';
import { renderFullName } from '../../helpers/format';
import { Column } from '../../types';

interface ListProps {}

const ClientsListPage: FC<ListProps> = () => {
  const { openDrawer, closeDrawer } = useDrawer();

  const cols: Column[] = [
    {
      id: 'id',
      label: 'ID',
      width: 80,
    },
    {
      id: 'photo',
      label: '',
      width: 40,
      renderCell: (data) => (
        <Avatar
          variant="rounded"
          src={data.img || '/assets/images/product-image-placeholder.png'}
          sx={{
            width: 40,
            height: 40,
            borderRadius: 1,
            marginLeft: -4,
          }}
        />
      ),
    },
    {
      id: 'name',
      label: 'ФИО',
      renderCell: (data) => renderFullName(data.client),
    },
    {
      id: 'pinfl',
      label: 'ПИНФЛ',
      renderCell: (data) => data.client.pinfl,
    },
    {
      id: 'phone',
      label: 'Номер телефона',
      renderCell: (data) => (
        <Stack>
          <div>{data.client.phone_1}</div>
          <div>{data.client.phone_2}</div>
        </Stack>
      ),
    },
    // {
    //   id: 'gender',
    //   label: 'Пол',
    //   renderCell: (data) => (data.client.gender === '1' ? 'Мужской' : 'Женский'),
    // },
    {
      id: 'address',
      label: 'Адрес',
      renderCell: (data) => data.client.address,
    },
    {
      id: 'actions',
      label: '',
      width: 40,
      renderCell: (data) => <DetailsButton onClick={() => handleInfo(data)} />,
    },
  ];

  const handleInfo = (data: any) => {
    openDrawer({
      content: <ClientInfo onClose={closeDrawer} data={data} />,
    });
  };

  return (
    <ListLayout
      icon={<CustomersIcon />}
      title="Клиенты"
      cols={cols}
      query={useGetClientsQuery}
      createPermission="UserClient@store"
      filter
    />
  );
};

export default ClientsListPage;
