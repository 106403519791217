import { Box, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { RecordObject } from '../../types';
import { ChartProps, LineChartProps } from '../../types/statistics';
import FlexBox from '../FlexBox';
import Loader from '../Loader';
import LineChart from './line-chart/LineChart';
import { LineChartColors } from './vars';

const StatisticsLineChart: FC<ChartProps> = ({ query }) => {
  const [data, setData] = useState<LineChartProps | null>(null);
  const [year, setYear] = useState<string>(new Date().getFullYear().toString());
  const { data: queryData, isLoading } = query({ year }, { skip: !year });

  useEffect(() => {
    if (queryData) setData(queryData.data);
  }, [queryData]);

  if (isLoading) return <Loader />;

  if (!data) return null;

  return (
    <FlexBox
      sx={{
        borderRadius: 0.75,
        bgcolor: '#222222',
        border: '1px solid #E5E9EB',
        width: 1200,
        // padding: '15px 30px 30px',
        height: 300,
      }}
    >
      <Stack
        gap={2.5}
        sx={{
          padding: '20px 25px 25px 22px',
        }}
        width={322}
      >
        <Typography fontSize={20} fontWeight={400} color="white">
          {data.title}
        </Typography>
        <Stack gap={2} color="white">
          {Object.values(data.items)
            .filter((item) => item.code !== 'all')
            .map((item: RecordObject, index: number) => (
              <Stack gap={0.5} key={item.code}>
                <FlexBox gap={1} justifyContent="space-between" alignItems="flex-end">
                  <Typography variant="caption">{item.label}</Typography>
                  <Typography variant="caption" color="#999999" textAlign="right">
                    {item.percentage}% (
                    {item.value.toFixed(0) === item.value.toString() ? item.value : item.value.toFixed(3)}
                    {data.unit && ' ' + data.unit})
                  </Typography>
                </FlexBox>
                <Box bgcolor="black" borderRadius={2}>
                  <Box height="5px" width={item.percentage + '%'} bgcolor={LineChartColors[index]} borderRadius={2} />
                </Box>
              </Stack>
            ))}
        </Stack>
      </Stack>

      <Box flexGrow={1}>
        <Box
          sx={{
            borderLeft: '1px solid #434343',
            padding: '16px 20px 25px 25px',
          }}
        >
          <Stack>
            <FlexBox justifyContent="flex-end" mb="35px">
              <TextField select size="small" value={data.params.year.value} onChange={(e) => setYear(e.target.value)}>
                {Object.entries(data.params.year.items).map(([key, value]: any) => (
                  <MenuItem key={key} value={key}>
                    {key}
                  </MenuItem>
                ))}
              </TextField>
            </FlexBox>
            <Box height={177} width="100%">
              <LineChart data={data.items} />
            </Box>
          </Stack>
        </Box>
      </Box>
    </FlexBox>
  );
};

export default StatisticsLineChart;
