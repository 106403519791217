// Тип сущности
export const ITEM_TYPE_USER = "user"; // Пользователи
export const ITEM_TYPE_LANGUAGE = "language"; // Языки
export const ITEM_TYPE_ROLE = "role"; // Роли
export const ITEM_TYPE_MODULE = "module"; // Модули
export const ITEM_TYPE_MODULE_OPERATIONS = "module_operations"; // Операции модулей
export const ITEM_TYPE_USER_MERCHANT = "user_merchant"; // Мерчанты
export const ITEM_TYPE_USER_MERCHANT_CREDITS = "user_merchant_credits"; // Кредитные тарифы мерчанта
export const ITEM_TYPE_USER_MERCHANT_DISCOUNTS = "user_merchant_discounts"; // Скидки мерчанта для нас
export const ITEM_TYPE_USER_MERCHANT_BRANCH = "user_merchant_branch"; // Филиалы мерчанта
export const ITEM_TYPE_USER_BRANCH_SELLER = "user_branch_seller"; // Пордавцы филиала
export const ITEM_TYPE_USER_CLIENT = "user_client"; // Клиенты
export const ITEM_TYPE_USER_ROLES = "user_roles"; // Роли пользователей
export const ITEM_TYPE_MANUFACTURER = "manufacturer"; // Производитель / Бренд
export const ITEM_TYPE_MODEL = "model"; // Модель товара
export const ITEM_TYPE_CATEGORY = "category"; // Категории для маркетплейса
export const ITEM_TYPE_CATALOG = "catalog"; // Справочник / Каталог
export const ITEM_TYPE_PRODUCT_CATEGORY = "product_category"; // Категории товаров
export const ITEM_TYPE_PRODUCT = "product"; // Товар
export const ITEM_TYPE_PRODUCT_FIELD = "product_field"; // Дополнительные поля товара
export const ITEM_TYPE_PRODUCT_PARAMS = "product_params"; // Значения дополнительных полей товара
export const ITEM_TYPE_APPLICATION = "application"; // Заявки
export const ITEM_TYPE_APPLICATION_PRODUCTS = "application_products"; // Товары заявки
export const ITEM_TYPE_SCORING = "scoring"; // Скоринг
export const ITEM_TYPE_PLASTIC_CARD = "plastic_card"; // Пластиковые карты
export const ITEM_TYPE_LOAN = "loan"; // Кредиты
export const ITEM_TYPE_LOAN_SCHEDULE = "loan_schedule"; // Графики погашения
export const ITEM_TYPE_LOAN_SCHEDULE_PAYMENT = "loan_schedule_payment"; // Оплаты по графику погашения
export const ITEM_TYPE_SMS = "sms"; // СМС
export const ITEM_TYPE_SETTINGS = "settings"; // Настройки
export const ITEM_TYPE_LOG = "log"; // Логи
