import { FC } from "react";
import ClientsForm from "../../components/clients/ClientsForm";

interface CreateProps {}

const ClientsCreatePage: FC<CreateProps> = () => {
  return <ClientsForm isNew data={null} />;
};

export default ClientsCreatePage;
