import InsuranceProductsCreatePage from "./CreatePage";
import InsuranceProductsListPage from "./ListPage";
import InsuranceProductsUpdatePage from "./UpdatePage";

const InsuranceProducts = {
  List: InsuranceProductsListPage,
  Create: InsuranceProductsCreatePage,
  Update: InsuranceProductsUpdatePage,
};

export default InsuranceProducts;
