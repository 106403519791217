import { Grid, Stack, TextField } from "@mui/material";
import { useFormik } from "formik";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import {
  useEditScoringMutation,
  useSaveScoringMutation,
} from "../../app/api/scoringsApi";
import { getFormikError, hasFormikError } from "../../helpers/form";
import FormPageLayout from "../FormPageLayout";
import FormSection from "../FormSection";

interface IFieldSection {
  title: string;
  code: string;
  fields: IFieldProps[];
}

interface IFieldProps {
  name: string;
  code: string;
  is_required: boolean;
  section: string;
  type: string;
}

interface Props {
  isNew?: boolean;
  data?: any;
  fields: IFieldSection[];
}


const ScoringForm: FC<Props> = ({ data, isNew, fields }) => {
  const navigate = useNavigate();

  const query = isNew ? useSaveScoringMutation : useEditScoringMutation;

  const [save] = query();

  let initialFields = {};
  let schema = yup.object();

  fields.forEach((section: any) => {
    section.fields.forEach((field: any) => {
      initialFields = { ...initialFields, [field.code]: "" };
      schema = schema.concat(
        yup.object().shape({
          [field.code]: field.is_required
            ? yup.string().required()
            : yup.string(),
        })
      );
    });
  });

  const formik = useFormik({
    initialValues: isNew ? { fields: initialFields } : data,
    onSubmit: (values) => {
      save(values)
        .unwrap()
        .then(() => {
          toast.success(
            isNew ? "Скоринг успешно сохранен" : "Скоринг успешно обновлен"
          );
          navigate(`/dashboard/scoring/`);
        })
        .catch((error) => {
          toast.error("Ошибка при сохранении скоринга");
          console.error(error);
        });
    },
    validationSchema: yup.object().shape({
      fields: schema,
    }),
    // validationSchema: yup.object().shape({
    // score: yup.string().required(),
    // solvency: yup.string().required(),
    // rating_international: yup.string().required(),
    // rating_national: yup.string().required(),
    // net_profit_at_reporting_date: yup.string().required(),
    // reporting_date_at: yup.string().required(),
    // coverage_percentage_max: yup.string().required(),
    // description: yup.string().required(),
    // }),
  });

  return (
    <FormPageLayout
      title={isNew ? "Новый скоринг" : "Редактирование скоринга"}
      onSave={() => {
        console.log(formik.errors, formik.values);
        formik.handleSubmit();
      }}
      onClose={() => navigate("/dashboard/scoring/")}
    >
      <Stack
        flexGrow={1}
        component="form"
        noValidate
        onSubmit={formik.handleSubmit}
      >
        {fields.map((section: any) => (
          <FormSection title={section.title} key={section.title}>
            <Grid container spacing={2}>
              {section.fields.map((field: any) => {
                return (
                  <Grid item xs={12} sm={6} md={4} key={"fields." + field.code}>
                    <TextField
                      fullWidth
                      label={field.name}
                      type={field.type}
                      required={field.is_required}
                      {...formik.getFieldProps("fields." + field.code)}
                      onChange={(e: any) => {
                        formik.setFieldValue(
                          "fields." + field.code,
                          e.target.value
                        );
                      }}
                      error={hasFormikError(formik, "fields." + field.code)}
                      helperText={getFormikError(
                        formik,
                        "fields." + field.code
                      )}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </FormSection>
        ))}
      </Stack>
    </FormPageLayout>
  );
};

export default ScoringForm;
