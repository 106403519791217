import { FC } from "react";
import { useGetUserMerchantProductsQuery } from "../../app/api/userMerchantProductsApi";
import DetailsButton from "../../components/DetailsButton";
import { ProductsIcon } from "../../components/Icons";
import ListLayout from "../../components/ListLayout";
import ProductInfo from "../../components/products/ProductInfo";
import { useDrawer } from "../../contexts/DrawerContext";
import { formatPrice } from "../../helpers/numbers";
import useLocale from "../../hooks/useLocale";
import { Column } from "../../types";

interface Props {}

const InsuranceProductsListPage: FC<Props> = () => {
  const { openDrawer } = useDrawer();
  const { t, locale } = useLocale();

  const cols: Column[] = [
    {
      id: "id",
      label: "ID",
      width: 60,
    },
    {
      id: "name",
      label: "Наименование продукта",
      renderCell: (data: any) => data.name[locale],
    },
    {
      id: "is_legal_form",
      label: "Правовая форма",
      renderCell: (data: any) =>
        data.is_legal_form ? "Юр. лицо" : "Физ. лицо",
    },
    {
      id: "term",
      label: "Срок",
    },
    {
      id: "tariff",
      label: "Тариф",
    },
    {
      id: "coverage_amount_max",
      label: "Максимальная сумма покрытия",
      renderCell: (data: any) => formatPrice(data.coverage_amount_max),
    },
    {
      id: "actions",
      label: "",
      width: 60,
      renderCell: (data: any) => (
        <DetailsButton onClick={() => handleInfo(data)} />
      ),
    },
  ];

  const handleInfo = (data: any) => {
    openDrawer({
      content: <ProductInfo data={data} insurance={true} />,
    });
  };

  return (
    <ListLayout
      icon={<ProductsIcon />}
      title={t("insuranceProducts", "Страховые продукты")}
      query={useGetUserMerchantProductsQuery}
      cols={cols}
      createPermission="UserMerchantProduct@store"
    />
  );
};

export default InsuranceProductsListPage;
