import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { objectToFormData } from '../../helpers/form';
import { urlWithSearchParams } from '../../helpers/format';
import { rtkBaseQuery } from '../../helpers/rtkquery';

export const sellersApi = createApi({
  reducerPath: 'sellersApi',
  baseQuery: rtkBaseQuery('/branch-sellers'),
  tagTypes: ['Sellers'],
  endpoints: (builder) => ({
    getSellers: builder.query({
      query: (props) => urlWithSearchParams('', props),
      providesTags: ['Sellers'],
    }),
    getSeller: builder.query({
      query: (id) => `${id}`,
      providesTags: (_result, _error, { id }) => [{ type: 'Sellers', id }],
    }),
    saveSeller: builder.mutation({
      query: (body) => ({
        url: '',
        method: 'POST',
        body: objectToFormData(body),
      }),
      invalidatesTags: ['Sellers'],
    }),
    activateSeller: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `${id}/activate`,
        // method: "PATCH",
        method: 'POST',
        body: { ...body, _method: 'PATCH' },
      }),
      invalidatesTags: (_result, _error, { id }) => ['Sellers', { type: 'Sellers', id }],
    }),
    updateSeller: builder.mutation({
      query: (body) => ({
        url: `${body.id}`,
        // method: "PUT",
        method: 'POST',
        body: objectToFormData({ ...body, _method: 'PUT' }),
      }),
      invalidatesTags: (_result, _error, { id }) => ['Sellers', { type: 'Sellers', id }],
    }),
  }),
});

export const {
  useGetSellersQuery,
  useGetSellerQuery,
  useSaveSellerMutation,
  useActivateSellerMutation,
  useUpdateSellerMutation,
} = sellersApi;
