import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetProductByIdQuery } from "../../app/api/productsApi";
import ProductForm from "../../components/products/ProductForm";

interface UpdateProps {}

const ProductsUpdatePage: FC<UpdateProps> = () => {
  const { id } = useParams();
  const [fields, setFields] = useState([]);

  // console.log("🚀 ~ file: UpdatePage.tsx:11 ~ fields:", fields);

  const { data: productData } = useGetProductByIdQuery(id);

  useEffect(() => {
    // const sections = productData?.data?.policy_fields.map(
    //   (section: RecordObject) => ({
    //     ...section,
    //     fields: section.fields.map((field: RecordObject) => ({
    //       ...field,
    //       section: section.title,
    //     })),
    //   })
    // );
    // console.log(
    //   "🚀 ~ file: UpdatePage.tsx:27 ~ useEffect ~ sections:",
    //   sections && sections.map((s: any) => s.fields)
    // );
    const policy_fields = productData?.data?.policy_fields?.reduce(
      (acc: any, section: any) => [...acc, ...section.fields],
      []
    );
    // console.log(
    //   "🚀 ~ file: UpdatePage.tsx:33 ~ useEffect ~ policy_fields:",
    //   policy_fields
    // );
    setFields(policy_fields);
  }, [productData]);

  if (!productData) return null;

  return <ProductForm data={{ ...productData.data, policy_fields: fields }} />;
};

export default ProductsUpdatePage;
