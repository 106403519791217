import { Box } from '@mui/material';
import { FC } from 'react';

interface Props {}

const Loader: FC<Props> = () => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" gap={2}>
      <div className="dot-spinner">
        <div className="dot-spinner__dot" />
        <div className="dot-spinner__dot" />
        <div className="dot-spinner__dot" />
        <div className="dot-spinner__dot" />
        <div className="dot-spinner__dot" />
        <div className="dot-spinner__dot" />
        <div className="dot-spinner__dot" />
        <div className="dot-spinner__dot" />
      </div>
      Загрузка...
    </Box>
  );
};

export default Loader;
