import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import {
  useCreateUserMerchantProductMutation,
  useUpdateUserMerchantProductMutation,
} from "../../app/api/userMerchantProductsApi";
import { ROUTE } from "../../constants/routes";
import { getFormikError, hasFormikError } from "../../helpers/form";
import { handleScroll } from "../../helpers/utils";
import { RecordObject } from "../../types";
import FlexBox from "../FlexBox";
import FormPageLayout from "../FormPageLayout";
import FormSection from "../FormSection";
import ProductFields from "./ProductFields";

interface Props {
  isNew?: boolean;
  data: any;
  products: RecordObject[];
}

const ProductForm: FC<Props> = ({ data, isNew, products }) => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const [selectedProduct, setSelectedProduct] = useState<any>({
    ...data,
  });
  const query = isNew
    ? useCreateUserMerchantProductMutation
    : useUpdateUserMerchantProductMutation;

  const [save] = query();

  const handleFields = (fields: any) => {
    formik.setFieldValue("policy_fields", fields);
  };

  const formik = useFormik({
    initialValues: {
      product_id: "",
      merchant_internal_product_id: "",
      code: "",
      name: {
        ru: "",
        uz: "",
        en: "",
      },
      description: {
        ru: "",
        uz: "",
        en: "",
      },
      policy_fields: [],

      ...data,
    },
    onSubmit: (values) => {
      const { policy_fields, ...rest } = values;
      const fields = policy_fields.map(({ type, ...item }: any) => item);
      save({ ...rest, policy_fields: fields })
        .unwrap()
        .then((res) => {
          toast.success("Успешно сохранено");
          navigate(ROUTE.INSURANCE_PRODUCTS);
        })
        .catch((err) => {
          toast.error("Ошибка при сохранении");
          console.error(err);
        });
    },
    validationSchema: yup.object().shape({}),
  });

  useEffect(() => {
    if (isNew && formik.values.product_id) {
      const { id, policy_fields, ...foundProduct }: any = products.find(
        (item: any) => item.id === formik.values.product_id
      );
      if (foundProduct) {
        const product = {
          ...foundProduct,
          product_id: formik.values.product_id,
          policy_fields: policy_fields
            .reduce((acc: any, section: any) => [...acc, ...section.fields], [])
            .map(({ id, ...field }: any) => ({
              id: "",
              product_policy_field_id: id,
              ...field,
            })),
        };
        setSelectedProduct(product);
        formik.setValues(product);
        // console.log("product", foundProduct);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.product_id]);

  const form_sections = [
    {
      id: "baseProduct",
      title: "Базовый продукт",
      type: "grid",
      hide: false,
      fields: [
        {
          type: "text",
          name: "product_id",
          label: "Продукт",
          width: 6,
          select: true,
          options: products.map((item: any) => ({
            label: item.name[i18n.language],
            value: item.id,
          })),
          disabled: !isNew,
        },
      ],
    },
    {
      id: "general",
      title: "Основное",
      type: "grid",
      hide: false,
      fields: [
        {
          type: "text",
          name: "merchant_internal_product_id",
          label: "Внутренний ID продукта",
          width: 4,
        },
        {
          type: "text",
          name: "code",
          label: "Код",
          width: 4,
        },
        {
          type: "text",
          name: "name.ru",
          label: "Название на русском",
          width: 4,
        },
        {
          type: "text",
          name: "name.uz",
          label: "Название на узбекском",
          width: 4,
        },
        {
          type: "text",
          name: "name.en",
          label: "Название на английском",
          width: 4,
        },
      ],
    },
    {
      id: "terms",
      title: "Условия",
      type: "grid",
      hide: false,
      fields: [
        {
          type: "text",
          name: "term",
          label: "Срок",
          width: 4,
        },
        {
          type: "text",
          name: "tariff",
          label: "Тариф",
          width: 4,
        },
        {
          type: "text",
          name: "coverage_amount_max",
          label: "Максимальная сумма покрытия",
          width: 4,
        },
      ],
    },
    {
      id: "fields",
      title: "Поля",
      type: "custom",
      hide: false,
      content: (
        <ProductFields
          onChange={handleFields}
          data={selectedProduct?.policy_fields}
        />
      ),
    },
  ];

  return (
    <FormPageLayout
      title={
        isNew
          ? "Новый страховой продукт"
          : "Редактирование страхового продукта " + data.name.ru
      }
      onSave={formik.handleSubmit}
      onClose={() => navigate(ROUTE.INSURANCE_PRODUCTS)}
    >
      <FlexBox gap={3}>
        <Box position="relative" minWidth={240}>
          <List sx={{ position: "sticky", top: 0 }}>
            {form_sections.map(
              (section) =>
                !section.hide && (
                  <ListItem
                    button
                    key={section.id}
                    onClick={() => handleScroll(section.id)}
                  >
                    <ListItemText primary={section.title} />
                  </ListItem>
                )
            )}
          </List>
        </Box>
        <Stack
          flexGrow={1}
          component="form"
          noValidate
          onSubmit={formik.handleSubmit}
        >
          {form_sections.map(
            (section) =>
              !section.hide && (
                <FormSection
                  title={section.title}
                  id={section.id}
                  key={section.id}
                  // actions={section.headerActions}
                >
                  {section.type === "grid" && (
                    <Grid container spacing={2}>
                      {section.fields &&
                        section.fields.map((field: any, index: number) => {
                          if (!field) return null;

                          const { name, options, width, ...props } = field;
                          return (
                            <Grid
                              item
                              xs={12}
                              sm={width}
                              key={section.id + "_" + index}
                            >
                              <TextField
                                {...props}
                                fullWidth
                                {...formik.getFieldProps(name)}
                                error={hasFormikError(formik, name)}
                                helperText={getFormikError(formik, name)}
                              >
                                {options &&
                                  options.map(({ label, value }: any) => (
                                    <MenuItem value={value} key={value}>
                                      {label}
                                    </MenuItem>
                                  ))}
                              </TextField>
                            </Grid>
                          );
                        })}
                    </Grid>
                  )}
                  {section.type === "custom" && section.content}
                </FormSection>
              )
          )}
        </Stack>
      </FlexBox>
    </FormPageLayout>
  );
};

export default ProductForm;
