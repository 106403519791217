import { Button } from '@mui/material';
import { FC } from 'react';
import { CheckboxCheckedIcon, CheckboxIcon, CheckboxPartialCheckedIcon } from '../../components/Icons';

interface Props {
  checkedAll: 'all' | 'none' | 'partial';
  label: string;
  onClick: () => void;
}

const ReportCheckAllButton: FC<Props> = ({ checkedAll, label, onClick }) => {
  const handleSelectAll = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onClick();
  };

  return (
    <Button
      variant="text"
      color="inherit"
      sx={{ textTransform: 'none' }}
      endIcon={
        checkedAll === 'all' ? (
          <CheckboxCheckedIcon />
        ) : checkedAll === 'partial' ? (
          <CheckboxPartialCheckedIcon />
        ) : (
          <CheckboxIcon />
        )
      }
      onClick={handleSelectAll}
    >
      {label}
    </Button>
  );
};

export default ReportCheckAllButton;
