import { ComponentType, createElement } from 'react';
import { Navigate } from 'react-router-dom';

export const withAuthorization = (Component: ComponentType) => {
  const EnhancedComponent: ComponentType<any> = (props) => {
    // const { isAuthorized } = useAuth();
    const token = localStorage.getItem('token');

    if (!token) {
      return createElement(Navigate, { to: '/' });
    }

    return createElement(Component, props);
  };

  return EnhancedComponent;
};
