import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { urlWithSearchParams } from "../../helpers/format";
import { rtkBaseQuery } from "../../helpers/rtkquery";

export const userMerchantProductsApi = createApi({
  reducerPath: "userMerchantProductsApi",
  baseQuery: rtkBaseQuery("/user-merchant-products"),
  tagTypes: ["UserMerchantProducts"],
  endpoints: (builder) => ({
    getUserMerchantProducts: builder.query({
      query: (params) => urlWithSearchParams("", params),
      providesTags: ["UserMerchantProducts"],
    }),
    getUserMerchantProductById: builder.query({
      query: (id) => `${id}`,
      providesTags: (_result, _error, { id }) => [
        { type: "UserMerchantProducts", id },
      ],
    }),
    createUserMerchantProduct: builder.mutation({
      query: (body) => ({
        url: "",
        method: "POST",
        body,
      }),
      invalidatesTags: ["UserMerchantProducts"],
    }),
    updateUserMerchantProduct: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (_result, _error, { id }) => [
        "UserMerchantProducts",
        { type: "UserMerchantProducts", id },
      ],
    }),
  }),
});

export const {
  useGetUserMerchantProductsQuery,
  useGetUserMerchantProductByIdQuery,
  useCreateUserMerchantProductMutation,
  useUpdateUserMerchantProductMutation,
} = userMerchantProductsApi;
