import { Button, ButtonProps } from "@mui/material";
import { FC } from "react";
import { CloseIcon } from "./Icons";

interface CloseButtonProps {}

const CloseButton: FC<CloseButtonProps & ButtonProps> = (props) => {
  return (
    <Button
      color="primary"
      variant="contained"
      size="large"
      sx={{ p: 1.5 }}
      {...props}
    >
      <CloseIcon />
    </Button>
  );
};

export default CloseButton;
