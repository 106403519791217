import { Button, ButtonProps, Stack } from "@mui/material";
import { FC } from "react";

const MenuButton: FC<ButtonProps> = (props) => {
  return (
    <Button
      sx={{
        height: 1,
        width: 1,
        display: "flex",
        transition: ".2s",
        p: 2,
        "&:hover": {
          backgroundColor: "#333",
        },
      }}
      className="MenuButton"
      {...props}
    >
      <Stack
        sx={{
          width: 22,
          height: 22,
          gap: 0.375,
          justifyContent: "center",
          transition: ".2s",
          "& div": {
            width: 22,
            height: 2,
            borderRadius: 2,
            backgroundColor: "#FFFFFF",
          },
          ".MenuButton:hover &": {
            gap: 0.5,
          },
        }}
      >
        <div />
        <div />
        <div />
      </Stack>
    </Button>
  );
};

export default MenuButton;
