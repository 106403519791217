import { getIn } from 'formik';

export const hasFormikError = (formik: any, key: string): boolean => {
  return getIn(formik.touched, key) && Boolean(getIn(formik.errors, key));
};

export const getFormikError = (formik: any, key: string) => {
  // return hasFormikError(formik, key) && getIn(formik.errors, key);
  return hasFormikError(formik, key) && getIn(formik.errors, key);
};

export const formatedName = (firstName: string, lastName: string, middleName: string) => {
  if (!firstName || !lastName || !middleName) return '';
  const names = [firstName, middleName].filter(Boolean);
  const initials = names.map((n) => n.charAt(0) + '.').join(' ');
  const formated = lastName + ' ' + initials;
  return formated;
};

export const objectToFormData = (obj: any, form?: any, namespace?: any) => {
  const fd = form || new FormData();
  let formKey;
  for (let property in obj) {
    if (obj.hasOwnProperty(property)) {
      if (namespace) {
        formKey = namespace + '[' + property + ']';
      } else {
        formKey = property;
      }

      // if the property is an object, but not a File, use recursivity.
      if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
        objectToFormData(obj[property], fd, formKey);
      } else {
        // if it's a string or a File object
        if (formKey === 'password' && !obj[property]) continue;
        fd.append(formKey, obj[property]);
      }
    }
  }
  return fd;
};

export const validationErrorsResponse = (errors: any) => {
  const formikErrors: any = {};
  Object.keys(errors).forEach((key) => {
    if (key.includes('.')) {
      const [first, second] = key.split('.');
      formikErrors[first] = formikErrors[first] || {};
      formikErrors[first][second] = errors[key].join(', ');
      return;
    }
    formikErrors[key] = errors[key].join(', ');
  });
  console.log('🚀 ~ file: form.ts:58 ~ validationErrorsResponse ~ formikErrors:', formikErrors);
  return formikErrors;
};
