import { FC } from "react";

interface UserOperationsProps {}

const UserOperations: FC<UserOperationsProps> = () => {
  // const { data } = useGetUserOperationsQuery({});
  return <div>UserOperations</div>;
};

export default UserOperations;
