import { Button, Stack, TextField, Typography } from "@mui/material";
import { format } from "date-fns";
import { useFormik } from "formik";
import { FC } from "react";
import { useConfirmPaymentMutation } from "../../app/api/applicationsApi";
import FlexBox from "../FlexBox";

interface PaymentConfirmProps {
  id: string;
  onClose: () => void;
}

const PaymentConfirm: FC<PaymentConfirmProps> = ({ id, onClose }) => {
  const [confirm] = useConfirmPaymentMutation();
  const currentDate = format(new Date(), "yyyy-MM-dd");

  const formik = useFormik({
    initialValues: {
      paid_at: currentDate,
      contract_number: "",
      contract_date: currentDate,
    },
    onSubmit: (values) => {
      confirm({ id, ...values })
        .unwrap()
        .then((res) => {
          onClose();
        })
        .catch(console.error);
    },
  });

  const handleCancel = () => onClose();

  return (
    <Stack
      component="form"
      onSubmit={formik.handleSubmit}
      sx={{ p: 3, gap: 4, width: 400 }}
    >
      <Typography variant="h3">Подтвердить оплату?</Typography>

      <Stack gap={2}>
        <TextField
          fullWidth
          label="Номер контракта"
          {...formik.getFieldProps("contract_number")}
        />

        <TextField
          fullWidth
          type="date"
          label="Дата контракта"
          {...formik.getFieldProps("contract_date")}
        />

        <TextField
          fullWidth
          type="date"
          label="Дата оплаты"
          {...formik.getFieldProps("paid_at")}
        />
      </Stack>

      <FlexBox justifyContent="space-between">
        <Button
          variant="contained"
          size="large"
          color="secondary"
          onClick={handleCancel}
        >
          Отмена
        </Button>
        <Button variant="contained" size="large" type="submit">
          Подтвердить
        </Button>
      </FlexBox>
    </Stack>
  );
};

export default PaymentConfirm;
