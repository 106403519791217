import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { rtkQueryErrorLogger } from '../helpers/rtkquery';
import { applicationsApi } from './api/applicationsApi';
import { attachmentsApi } from './api/attachmentsApi';
import { authApi } from './api/authApi';
import { banksApi } from './api/banksApi';
import { clientsApi } from './api/clientsApi';
import { insurancesApi } from './api/insurancesApi';
import { languagesApi } from './api/languagesApi';
import { merchantBranchesApi } from './api/merchantBranchesApi';
import { moduleOperationsApi } from './api/moduleOperationsApi';
import { modulesApi } from './api/modulesApi';
import { policiesApi } from './api/policiesApi';
import { productCategoriesApi } from './api/productCategoriesApi';
import { productsApi } from './api/productsApi';
import { reportsApi } from './api/reportsApi';
import { roleOperationsApi } from './api/roleOperationsApi';
import { rolesApi } from './api/rolesApi';
import { scoringsApi } from './api/scoringsApi';
import { sellersApi } from './api/sellersApi';
import { statisticsApi } from './api/statisticsApi';
import { userApi } from './api/userApi';
import { userMerchantProductsApi } from './api/userMerchantProductsApi';
import { userOperationsApi } from './api/userOperationsApi';
import { userRolesApi } from './api/userRolesApi';
import { usersApi } from './api/usersApi';

export const store = configureStore({
  reducer: {
    [applicationsApi.reducerPath]: applicationsApi.reducer,
    [attachmentsApi.reducerPath]: attachmentsApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [banksApi.reducerPath]: banksApi.reducer,
    [sellersApi.reducerPath]: sellersApi.reducer,
    [clientsApi.reducerPath]: clientsApi.reducer,
    [insurancesApi.reducerPath]: insurancesApi.reducer,
    [languagesApi.reducerPath]: languagesApi.reducer,
    [merchantBranchesApi.reducerPath]: merchantBranchesApi.reducer,
    [moduleOperationsApi.reducerPath]: moduleOperationsApi.reducer,
    [modulesApi.reducerPath]: modulesApi.reducer,
    [productCategoriesApi.reducerPath]: productCategoriesApi.reducer,
    [productsApi.reducerPath]: productsApi.reducer,
    [roleOperationsApi.reducerPath]: roleOperationsApi.reducer,
    [rolesApi.reducerPath]: rolesApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [userOperationsApi.reducerPath]: userOperationsApi.reducer,
    [userRolesApi.reducerPath]: userRolesApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [policiesApi.reducerPath]: policiesApi.reducer,
    [userMerchantProductsApi.reducerPath]: userMerchantProductsApi.reducer,
    [statisticsApi.reducerPath]: statisticsApi.reducer,
    [scoringsApi.reducerPath]: scoringsApi.reducer,
    [reportsApi.reducerPath]: reportsApi.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      applicationsApi.middleware,
      attachmentsApi.middleware,
      authApi.middleware,
      banksApi.middleware,
      sellersApi.middleware,
      clientsApi.middleware,
      insurancesApi.middleware,
      languagesApi.middleware,
      merchantBranchesApi.middleware,
      moduleOperationsApi.middleware,
      modulesApi.middleware,
      productCategoriesApi.middleware,
      productsApi.middleware,
      roleOperationsApi.middleware,
      rolesApi.middleware,
      userApi.middleware,
      userOperationsApi.middleware,
      userRolesApi.middleware,
      usersApi.middleware,
      policiesApi.middleware,
      userMerchantProductsApi.middleware,
      statisticsApi.middleware,
      scoringsApi.middleware,
      reportsApi.middleware,

      rtkQueryErrorLogger,
    ]),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
