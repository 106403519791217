import { EditOutlined } from '@mui/icons-material';
import { Avatar, Box, Button, Stack, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSetBankApiStatusMutation } from '../../app/api/banksApi';
import { ROUTE, getRoute } from '../../constants/routes';
import { useDrawer } from '../../contexts/DrawerContext';
import { formatPrice } from '../../helpers/numbers';
import { handleError } from '../../helpers/utils';
import DataSetCard from '../DataSetCard';
import FlexBox from '../FlexBox';
import StatisticsCard from '../StatisticsCard';

interface Props {
  data: any;
}

const BankInfo: FC<Props> = ({ data }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { closeDrawer } = useDrawer();

  const [activeApi, setActiveApi] = useState(data.merchant.is_active_api);

  const [patch, { isLoading }] = useSetBankApiStatusMutation();

  const handleEdit = () => {
    navigate(getRoute(ROUTE.BANKS, data.id, data.status === 'pending' ? 'activate' : 'edit'));
    closeDrawer();
  };

  const handleToggleActivation = () => {
    patch({ id: data.id })
      .unwrap()
      .then((res) => {
        const prev = activeApi;
        setActiveApi(!prev);
        if (prev) toast.success('API успешно деактивирован');
        else toast.success('API успешно активирован');
      })
      .catch(handleError);
  };

  return (
    <Stack width={466} px={2.5} py={3.5} gap={3} height={1}>
      <FlexBox alignItems="flex-start" gap={2.5}>
        <Avatar
          src={data.merchant.logo?.url}
          variant="square"
          sx={{ width: 80, height: 60, '& img': { objectFit: 'contain' } }}
        />
        <Stack gap={0.5} alignItems="flex-start">
          <Typography variant="h3">{data.merchant.company_brand_name}</Typography>
          <Typography variant="body2" color="#999999">
            ID: {data.id}
          </Typography>
        </Stack>

        <Box display="flex" gap={2.5} ml="auto">
          <Button variant="contained" size="large" sx={{ width: 45, height: 45 }} onClick={handleEdit}>
            <EditOutlined />
          </Button>
        </Box>
      </FlexBox>

      <Stack gap={1}>
        <Typography variant="h6" color="#424242" textTransform="uppercase" mb={1} pl={2.5} letterSpacing={'0.05em'}>
          {t('statistics', 'Статистика')}
        </Typography>

        <StatisticsCard
          label={t('statistics.totalPolicies', 'Всего выдано полисов')}
          value={data.policies.count}
          subtitle={t('statistics.allTime', 'За все время')}
        />

        {false && (
          <StatisticsCard
            label={t('statistics.paidCommission', 'Оплаченная комиссия')}
            value={formatPrice(260000000) + t('perMonth', '/мес')}
            subtitle={100 + '%'}
          />
        )}
      </Stack>

      <Stack gap={1}>
        <Typography variant="h6" color="#424242" textTransform="uppercase" mb={1} pl={2.5} letterSpacing={'0.05em'}>
          {t('requisites', 'Реквизиты')}
        </Typography>

        <DataSetCard
          data={[
            {
              label: t('name', 'Наименование'),
              value: data.merchant.company_brand_name,
            },
            { label: t('contractNumber', 'Номер договора'), value: data.id },
            { label: t('mfo', 'МФО'), value: data.merchant.bank_mfo },
            {
              label: t('paymentAccount', 'Р/с'),
              value: data.merchant.bank_account,
            },
            { label: t('tin', 'ИНН'), value: data.merchant.bank_inn },
            { label: t('oked', 'ОКЭД'), value: data.merchant.bank_oked },
            {
              label: t('address', 'Адрес'),
              value: data.merchant.location_address,
            },
          ]}
        />
      </Stack>

      <Box mt="auto">
        <Button
          size="large"
          fullWidth
          variant="contained"
          color={activeApi ? 'error' : 'primary'}
          onClick={handleToggleActivation}
          disabled={isLoading}
        >
          {activeApi ? 'Деактивировать API' : 'Активировать API'}
        </Button>
      </Box>
    </Stack>
  );
};

export default BankInfo;
