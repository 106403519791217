import { DeleteOutline } from "@mui/icons-material";
import { Box, Button, Stack, Typography } from "@mui/material";
import { FC, ReactNode } from "react";

interface FormDialogProps {
  type: "add" | "edit" | "delete";
  title: string;
  children: ReactNode;
}

const FormDialog: FC<FormDialogProps> = ({ type, title, children }) => {
  return (
    <Stack pl={4} pt={4} pr={2.5} pb={3} width={450} gap={2.5}>
      <Typography variant="h4">{title}</Typography>
      {children}
      <Box display="none" gap={2.5}>
        {type === "edit" && (
          <Button
            variant="contained"
            color="secondary"
            sx={{ color: "#C5C8D1", p: 1.25, fontSize: 24 }}
          >
            <DeleteOutline />
          </Button>
        )}

        <Button variant="contained" color="secondary" sx={{ ml: "auto" }}>
          Отмена
        </Button>
        {type !== "delete" && (
          <Button variant="contained" color="primary">
            Готово
          </Button>
        )}
        {type === "delete" && (
          <Button
            variant="contained"
            color="error"
            startIcon={<DeleteOutline />}
          >
            Удалить
          </Button>
        )}
      </Box>
    </Stack>
  );
};

export default FormDialog;
