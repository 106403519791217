import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { urlWithSearchParams } from "../../helpers/format";
import { rtkBaseQuery } from "../../helpers/rtkquery";

export const productCategoriesApi = createApi({
  reducerPath: "productCategoriesApi",
  baseQuery: rtkBaseQuery("/product-categories"),
  tagTypes: ["ProductCategories"],
  endpoints: (builder) => ({
    getProductCategories: builder.query({
      query: (params) => urlWithSearchParams("", params),
      providesTags: ["ProductCategories"],
    }),
    getProductCategory: builder.query({
      query: (id) => `${id}`,
      providesTags: (_result, _error, { id }) => [
        { type: "ProductCategories", id },
      ],
    }),
    createProductCategory: builder.mutation({
      query: (body: any) => ({
        url: "",
        method: "POST",
        body,
      }),
      invalidatesTags: ["ProductCategories"],
    }),
    updateProductCategory: builder.mutation({
      query: (body: any) => ({
        url: "",
        method: "PUT",
        body,
      }),
      invalidatesTags: (_result, _error, { id }) => [
        "ProductCategories",
        { type: "ProductCategories", id },
      ],
    }),
    deleteProductCategory: builder.mutation({
      query: (id: string) => ({
        url: `${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ProductCategories"],
    }),
  }),
});

export const {
  useGetProductCategoriesQuery,
  useGetProductCategoryQuery,
  useCreateProductCategoryMutation,
  useUpdateProductCategoryMutation,
  useDeleteProductCategoryMutation,
} = productCategoriesApi;
