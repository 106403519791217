import { Button } from "@mui/material";
import { FC } from "react";

const InfoButton: FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <Button
      variant="outlined"
      sx={{ height: 35, width: 35, ml: 2 }}
      {...{ onClick }}
    >
      <svg
        width="3"
        height="15"
        viewBox="0 0 3 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.49512 2H1.50512"
          stroke="#999999"
          stroke-width="2.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1.49512 14L1.49512 7"
          stroke="#999999"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </Button>
  );
};

export default InfoButton;
