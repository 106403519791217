import { FC } from "react";
import { useParams } from "react-router-dom";
import { useGetProductsQuery } from "../../app/api/productsApi";
import { useGetUserMerchantProductByIdQuery } from "../../app/api/userMerchantProductsApi";
import InsuranceProductForm from "../../components/products/InsuranceProductForm";

interface UpdateProps {}

const InsuranceProductsUpdatePage: FC<UpdateProps> = () => {
  const { id } = useParams();
  const { data } = useGetUserMerchantProductByIdQuery(id);
  const { data: products } = useGetProductsQuery({ limit: 999 });

  if (!data || !products) return null;

  return (
    <InsuranceProductForm
      data={{ ...data.data, product_id: data.data.product.id }}
      products={products.data.items}
    />
  );
};

export default InsuranceProductsUpdatePage;
