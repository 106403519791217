import { Box, Grid, List, ListItem, ListItemText, MenuItem, Stack, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { useCreateInsuranceMutation, useUpdateInsuranceMutation } from '../../app/api/insurancesApi';
import { ROUTE } from '../../constants/routes';
import { getFormikError, hasFormikError, validationErrorsResponse } from '../../helpers/form';
import { handleScroll } from '../../helpers/utils';
import DocumentsTable from '../DocumentsTable';
import FileInput from '../FileInput';
import FlexBox from '../FlexBox';
import FormPageLayout from '../FormPageLayout';
import FormSection from '../FormSection';

interface Props {
  isNew?: boolean;
  data: any;
}

const InsuranceForm: FC<Props> = ({ data, isNew }) => {
  const navigate = useNavigate();
  const [files, setFiles] = useState(data?.merchant?.attachments || []);
  const query = isNew ? useCreateInsuranceMutation : useUpdateInsuranceMutation;

  const validationSchema = yup.object().shape({
    login: yup.string().required(),
    email: yup.string().email().required(),
    password: isNew ? yup.string().required().min(6) : yup.string().notRequired(),
    merchant: yup.object().shape({
      // affiliate_commission: yup.string().required(),
      bank_account: yup.string().length(20).required(),
      bank_inn: yup.string().min(9).max(10).required(),
      bank_mfo: yup.string().length(5).required(),
      bank_name: yup.string().required(),
      company_brand_name: yup.string().required(),
      // company_foundation_at: yup.string().required(),
      company_inn: yup.string().min(9).max(10).required(),
      company_name: yup.string().required(),
      company_oked: yup.string().length(5).required(),
      company_type: yup.string().required(),
      // contact_1_email: yup.string().required(),
      contact_1_first_name: yup.string().required(),
      contact_1_last_name: yup.string().required(),
      // contact_1_phone: yup.string().required(),
      // contact_1_position: yup.string().required(),
      // contact_1_second_name: yup.string().required(),
      // contact_2_email: yup.string().required(),
      // contact_2_first_name: yup.string().required(),
      // contact_2_last_name: yup.string().required(),
      // contact_2_phone: yup.string().required(),
      // contact_2_position: yup.string().required(),
      // contact_2_second_name: yup.string().required(),
      // contract_number: yup.string().required(),
      // description: yup.object().shape({
      //   en: yup.string().required(),
      //   ru: yup.string().required(),
      //   uz: yup.string().required(),
      // }),
      // location_address: yup.string().required(),
      location_city: yup.string().required(),
      location_district: yup.string().required(),
      // location_floor: yup.string().required(),
      location_house: yup.string().required(),
      // location_number: yup.string().required().min(2),
      location_street: yup.string().required(),
      // name: yup.string().required(),
      // social_email: yup.string().required(),
      // social_web: yup.string().required(),
    }),
  });

  const [save] = query();
  const formik = useFormik({
    initialValues: {
      login: '',
      email: '',
      password: '',
      logo: null,
      merchant: {
        // name: '',
        affiliate_commission: '',
        bank_account: '',
        bank_inn: '',
        bank_mfo: '',
        bank_name: '',
        company_brand_name: '',
        company_foundation_at: '',
        company_inn: '',
        company_name: '',
        company_oked: '',
        company_type: '',
        contact_1_email: '',
        contact_1_first_name: '',
        contact_1_last_name: '',
        contact_1_phone: '',
        contact_1_position: '',
        contact_1_second_name: '',
        contact_2_email: '',
        contact_2_first_name: '',
        contact_2_last_name: '',
        contact_2_phone: '',
        contact_2_position: '',
        contact_2_second_name: '',
        contract_number: '112358',
        description: {
          uz: '',
          ru: '',
          en: '',
        },
        location_address: '',
        location_city: '',
        location_district: '',
        location_floor: '',
        location_house: '',
        location_number: '',
        location_street: '',
        organization_type: 'insurance',
        social_email: '',
        social_web: '',
      },

      ...data,
    },
    onSubmit: (values) => {
      save(values)
        .unwrap()
        .then((res) => {
          toast.success(isNew ? 'Страховая компания успешно создана' : 'Страховая компания успешно обновлена');
          navigate(`/dashboard/insurances/${isNew ? res.data.id + '/activate' : ''}`);
        })
        .catch((error) => {
          if (error.status === 422) {
            const formikErrors = validationErrorsResponse(error.data.data);
            toast.error('Ошибка валидации');
            formik.setErrors(formikErrors);
          } else {
            toast.error('Ошибка при сохранении страховой компании');
            console.error(error);
          }
        });
    },
    validationSchema,
  });

  const form_sections = [
    {
      id: 'account',
      title: 'Аккаунт',
      type: 'grid',
      fields: [
        {
          type: 'text',
          name: 'login',
          label: 'Логин',
          width: 4,
        },
        {
          type: 'text',
          name: 'email',
          label: 'Email',
          width: 4,
        },
        {
          type: 'password',
          name: 'password',
          label: 'Пароль',
          width: 4,
        },
      ],
    },
    {
      id: 'logo',
      title: 'Логотип',
      type: 'custom',
      content: (
        <FileInput
          existFile={data.merchant?.logo}
          selectFile={(file: File) => formik.setFieldValue('merchant.logo', file)}
        />
      ),
    },
    {
      id: 'company',
      title: 'Компания',
      type: 'grid',
      fields: [
        {
          type: 'text',
          name: 'merchant.company_type',
          label: 'Тип компании',
          width: 4,
        },
        {
          type: 'text',
          name: 'merchant.company_brand_name',
          label: 'Бренд',
          width: 4,
        },
        {
          type: 'text',
          name: 'merchant.company_name',
          label: 'Название компании',
          width: 4,
        },
        {
          type: 'text',
          name: 'merchant.company_inn',
          label: 'ИНН',
          width: 4,
        },
        {
          type: 'text',
          name: 'merchant.company_oked',
          label: 'ОКЭД',
          width: 4,
        },
        {
          type: 'date',
          name: 'merchant.company_foundation_at',
          label: 'Дата основания',
          width: 4,
          InputLabelProps: { shrink: true },
        },
        {
          type: 'text',
          name: 'merchant.social_web',
          label: 'Веб сайт',
          width: 4,
        },
        {
          type: 'text',
          name: 'merchant.social_email',
          label: 'Email',
          width: 4,
        },
      ],
    },
    {
      id: 'bank',
      title: 'Банк',
      type: 'grid',
      fields: [
        {
          type: 'text',
          name: 'merchant.bank_name',
          label: 'Название банка',
          width: 4,
        },
        {
          type: 'text',
          name: 'merchant.bank_account',
          label: 'Расчетный счет',
          width: 4,
        },
        {
          type: 'text',
          name: 'merchant.bank_mfo',
          label: 'МФО',
          width: 4,
        },
        {
          type: 'text',
          name: 'merchant.bank_inn',
          label: 'ИНН банка',
          width: 4,
        },
      ],
    },
    {
      id: 'comission',
      title: 'Комиссия',
      type: 'grid',
      fields: [
        {
          type: 'text',
          name: 'merchant.affiliate_commission',
          label: 'Комиссия аффилиата',
          width: 4,
        },
      ],
    },
    {
      id: 'address',
      title: 'Адрес',
      type: 'grid',
      fields: [
        {
          type: 'text',
          name: 'merchant.location_city',
          label: 'Город',
          width: 4,
        },
        {
          type: 'text',
          name: 'merchant.location_district',
          label: 'Район',
          width: 4,
        },
        {
          type: 'text',
          name: 'merchant.location_street',
          label: 'Улица',
          width: 4,
        },
        {
          type: 'text',
          name: 'merchant.location_house',
          label: 'Дом',
          width: 4,
        },
        {
          type: 'text',
          name: 'merchant.location_floor',
          label: 'Этаж',
          width: 4,
        },
        {
          type: 'text',
          name: 'merchant.location_number',
          label: 'Офис',
          width: 4,
        },
        {
          type: 'text',
          name: 'merchant.location_address',
          label: 'Адрес',
          width: 12,
        },
      ],
    },
    {
      id: 'contact_1',
      title: 'Контактное лицо 1',
      type: 'grid',
      fields: [
        {
          type: 'text',
          name: 'merchant.contact_1_first_name',
          label: 'Имя',
          width: 4,
        },
        {
          type: 'text',
          name: 'merchant.contact_1_last_name',
          label: 'Фамилия',
          width: 4,
        },
        {
          type: 'text',
          name: 'merchant.contact_1_second_name',
          label: 'Отчество',
          width: 4,
        },
        {
          type: 'text',
          name: 'merchant.contact_1_phone',
          label: 'Телефон',
          width: 4,
        },
        {
          type: 'text',
          name: 'merchant.contact_1_email',
          label: 'Email',
          width: 4,
        },
        {
          type: 'text',
          name: 'merchant.contact_1_position',
          label: 'Должность',
          width: 4,
        },
      ],
    },
    {
      id: 'contact_2',
      title: 'Контактное лицо 2',
      type: 'grid',
      fields: [
        {
          type: 'text',
          name: 'merchant.contact_2_first_name',
          label: 'Имя',
          width: 4,
        },
        {
          type: 'text',
          name: 'merchant.contact_2_last_name',
          label: 'Фамилия',
          width: 4,
        },
        {
          type: 'text',
          name: 'merchant.contact_2_second_name',
          label: 'Отчество',
          width: 4,
        },
        {
          type: 'text',
          name: 'merchant.contact_2_phone',
          label: 'Телефон',
          width: 4,
        },
        {
          type: 'text',
          name: 'merchant.contact_2_email',
          label: 'Email',
          width: 4,
        },
        {
          type: 'text',
          name: 'merchant.contact_2_position',
          label: 'Должность',
          width: 4,
        },
      ],
    },
    {
      id: 'description',
      title: 'Описание',
      type: 'grid',
      fields: [
        {
          type: 'textarea',
          name: 'merchant.description.ru',
          label: 'Описание на русском',
          width: 12,
        },
        {
          type: 'textarea',
          name: 'merchant.description.en',
          label: 'Описание на английском',
          width: 12,
        },
        {
          type: 'textarea',
          name: 'merchant.description.uz',
          label: 'Описание на узбекском',
          width: 12,
        },
      ],
    },
    {
      id: 'merchant.attachments',
      title: 'Документы',
      type: 'custom',
      hide: isNew,
      content: (
        <DocumentsTable
          data={files}
          setData={setFiles}
          documentTypes={{
            contract_signed: 'Договор подписанный',
          }}
        />
      ),
    },
  ];

  return (
    <FormPageLayout
      title={isNew ? 'Новая компания' : 'Редактирование компании ' + data.merchant.company_brand_name}
      onSave={() => {
        console.log('formik.errors', formik.errors);
        formik.handleSubmit();
      }}
      onClose={() => navigate(ROUTE.INSURANCES)}
    >
      {/* <ApplicationStepper /> */}
      <FlexBox gap={3}>
        <Box position="relative" minWidth={240}>
          <List sx={{ position: 'sticky', top: 0 }}>
            {form_sections.map((section) => (
              <ListItem button key={section.id} onClick={() => handleScroll(section.id)}>
                <ListItemText primary={section.title} />
              </ListItem>
            ))}
          </List>
        </Box>
        <Stack flexGrow={1} component="form" noValidate onSubmit={formik.handleSubmit}>
          {form_sections.map(
            (section) =>
              !section.hide && (
                <FormSection
                  title={section.title}
                  id={section.id}
                  key={section.id}
                  // actions={section.headerActions}
                >
                  {section.type === 'grid' && (
                    <Grid container spacing={2}>
                      {section.fields &&
                        section.fields.map((field: any, index: number) => {
                          if (!field) return null;

                          const { name, options, width, ...props } = field;
                          return (
                            <Grid item xs={12} sm={width} key={section.id + '_' + index}>
                              {field.type === 'file' ? (
                                <FileInput
                                  existFile={data.merchant?.logo}
                                  selectFile={(file: File) => formik.setFieldValue(name, file)}
                                />
                              ) : (
                                <TextField
                                  {...props}
                                  fullWidth
                                  {...formik.getFieldProps(name)}
                                  error={hasFormikError(formik, name)}
                                  helperText={getFormikError(formik, name)}
                                >
                                  {options &&
                                    options.map(({ label, value }: any) => (
                                      <MenuItem value={value} key={value}>
                                        {label}
                                      </MenuItem>
                                    ))}
                                </TextField>
                              )}
                            </Grid>
                          );
                        })}
                    </Grid>
                  )}
                  {section.type === 'custom' && section.content}
                </FormSection>
              )
          )}
        </Stack>
      </FlexBox>
    </FormPageLayout>
  );
};

export default InsuranceForm;
