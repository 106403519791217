import { FC } from "react";
import { useParams } from "react-router-dom";
import { useGetSellerQuery } from "../../app/api/sellersApi";
import SellerForm from "../../components/sellers/SellerForm";

const BanksUpdatePage: FC = () => {
  const { id } = useParams();
  const { data } = useGetSellerQuery(id);

  if (!data) return null;

  return <SellerForm data={data.data} />;
};

export default BanksUpdatePage;
