import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useGetModulesQuery } from "../../app/api/modulesApi";
import { useGetAllRolesQuery } from "../../app/api/rolesApi";
import { useGetUserByIdQuery } from "../../app/api/usersApi";
import FormPageLayout from "../../components/FormPageLayout";
import FormSection from "../../components/FormSection";
import { RecordObject } from "../../types";

interface UserEditProps {}

const UserEdit: FC<UserEditProps> = () => {
  const { i18n } = useTranslation();
  const locale = i18n.language;
  const navigate = useNavigate();
  const [user, setUser] = useState<RecordObject>({});
  const [roles, setRoles] = useState([]);
  const [modules, setModules] = useState([]);

  const [userOperations, setUserOperations] = useState([]);
  const [availableModules, setAvailableModules] = useState<any[]>([]);

  const { id } = useParams();

  const { data } = useGetUserByIdQuery(id);
  const { data: rolesData } = useGetAllRolesQuery({});
  const { data: modulesData } = useGetModulesQuery({ limit: 99 });

  useEffect(() => {
    if (data) {
      setUser(data.data);
      setUserOperations(data.data.operations.items || []);
      formik.setValues(data.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (userOperations.length) {
      const modules = userOperations.map((op: any) => op.module_id);
      const uniqueModules = modules.filter(
        (v: any, i: any, a: any) => a.indexOf(v) === i
      );
      setAvailableModules(uniqueModules);
    }
  }, [userOperations]);

  useEffect(() => {
    if (rolesData) setRoles(rolesData.data.items);
  }, [rolesData]);

  useEffect(() => {
    if (modulesData) setModules(modulesData.data.items);
  }, [modulesData]);

  const formik = useFormik({
    initialValues: {
      ...{
        id: null,
        first_name: "",
        last_name: "",
        second_name: "",
        login: "",
        email: "",
        parent_id: null,
        merchant_id: null,
        merchant_branch_id: null,
        status: "active",
        publication_status: true,
      },
      ...user,
    },
    onSubmit: (values) => {},
  });

  if (!data) return null;

  return (
    <FormPageLayout
      title="Редактирование пользователя"
      onSave={formik.handleSubmit}
      onClose={() => navigate(-1)}
    >
      <Stack>
        <FormSection title="Данные" id="data" key="data">
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                {...formik.getFieldProps("last_name")}
                label="Фамилия"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                {...formik.getFieldProps("first_name")}
                label="Имя"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                {...formik.getFieldProps("second_name")}
                label="Отчество"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                {...formik.getFieldProps("email")}
                label="Email"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                {...formik.getFieldProps("login")}
                label="Логин"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                {...formik.getFieldProps("status")}
                label="Статус"
                fullWidth
                select
              >
                <MenuItem value="active">Активный</MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </FormSection>

        <FormSection title="Роль" id="roles" key="roles">
          <FormGroup>
            <Grid container>
              {roles.map((role: any) => (
                <Grid item xs={6} md={4} lg={3} key={role.id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled
                        defaultChecked={Boolean(
                          user.roles &&
                            user.roles.find((r: any) => r.role_id === role.id)
                        )}
                      />
                    }
                    label={role.name[locale]}
                    value={role.id}
                  />
                </Grid>
              ))}
            </Grid>
          </FormGroup>
        </FormSection>

        <FormSection title="Операции" id="operations" key="operations">
          <FormGroup>
            <Grid container>
              {modules.map(
                (module: any) =>
                  availableModules.includes(module.id) && (
                    <Grid item xs={12} md={6} lg={4} key={module.id}>
                      <FormSection title={module.name[locale]} id={module.id}>
                        <Stack>
                          {module.operations.map((operation: any) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled
                                  defaultChecked={Boolean(
                                    userOperations.find(
                                      (o: any) =>
                                        o.operation_id === operation.id
                                    )
                                  )}
                                />
                              }
                              label={operation.name[locale]}
                              value={operation.id}
                              key={operation.id}
                            />
                          ))}
                        </Stack>
                      </FormSection>
                    </Grid>
                  )
              )}
            </Grid>
          </FormGroup>
        </FormSection>
      </Stack>
    </FormPageLayout>
  );
};

export default UserEdit;
