import { Typography } from "@mui/material";
import { FC } from "react";
import { useGetModuleOperationsQuery } from "../../app/api/moduleOperationsApi";
import EnhancedTable from "../../components/EnhancedTable";
import { Column } from "../../types";

interface ModuleOperationsProps {}

const cols: Column[] = [
  {
    id: "id",
    label: "ID",
    sortable: true,
    numeric: true,
    width: 30,
  },
  {
    id: "name",
    label: "Название операции",
    sortable: true,
    numeric: false,
  },
  {
    id: "code",
    label: "Код",
    sortable: true,
    numeric: false,
  },
  {
    id: "controller",
    label: "Контроллер",
    sortable: true,
    numeric: false,
  },
  {
    id: "action",
    label: "Действие",
    sortable: true,
    numeric: false,
  },
];

const ModuleOperations: FC<ModuleOperationsProps> = () => {
  return (
    <div>
      <Typography>ModuleOperations</Typography>
      <EnhancedTable cols={cols} query={useGetModuleOperationsQuery} />
    </div>
  );
};

export default ModuleOperations;
