import { Box, Grid, List, ListItem, ListItemText, MenuItem, Stack, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { useCreateBankMutation, useUpdateBankMutation } from '../../app/api/banksApi';
import { ROUTE, getRoute } from '../../constants/routes';
import { getFormikError, hasFormikError, validationErrorsResponse } from '../../helpers/form';
import { handleScroll } from '../../helpers/utils';
import DocumentsTable from '../DocumentsTable';
import FileInput from '../FileInput';
import FlexBox from '../FlexBox';
import FormPageLayout from '../FormPageLayout';
import FormSection from '../FormSection';

interface Props {
  data?: any;
}

const BankForm: FC<Props> = ({ data }) => {
  const isNew = !data;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [files, setFiles] = useState(data?.merchant?.attachments || []);
  const query = isNew ? useCreateBankMutation : useUpdateBankMutation;

  const validationSchema = yup.object().shape({
    login: yup.string().required(),
    email: yup.string().email(),
    password: isNew ? yup.string().required().min(6) : yup.string().notRequired(),
    merchant: yup.object().shape({
      // name: yup.string().required(),
      company_type: yup.string().required(),
      company_brand_name: yup.string().required(),
      company_name: yup.string().required(),
      company_inn: yup.string().min(9).max(10).required(),
      company_oked: yup.string().length(5).required(),
      company_foundation_at: yup.string().required(),
      contract_number: isNew ? yup.string().required() : yup.string(),
      bank_name: yup.string().required(),
      bank_account: yup.string().length(20).required(),
      bank_inn: yup.string().min(9).max(10).required(),
      bank_mfo: yup.string().length(5).required(),
      // social_web: yup.string().required(),
      // social_email: yup.string().required(),
      // affiliate_commission: yup.string().required(),
      location_city: yup.string().required(),
      location_district: yup.string().required(),
      location_street: yup.string().required(),
      location_house: yup.string().required(),
      // location_floor: yup.string().required(),
      // location_number: yup.string().required().min(2),
      // location_address: yup.string().required(),
      contact_1_first_name: yup.string().required(),
      // contact_1_last_name: yup.string().required(),
      // contact_1_second_name: yup.string().required(),
      contact_1_phone: yup.string().required(),
      // contact_1_email: yup.string().required(),
      // contact_1_position: yup.string().required(),
      // contact_2_first_name: yup.string().required(),
      // contact_2_last_name: yup.string().required(),
      // contact_2_second_name: yup.string().required(),
      // contact_2_phone: yup.string().required(),
      // contact_2_email: yup.string().required(),
      // contact_2_position: yup.string().required(),
      // description: yup.object().shape({
      //   uz: yup.string().required(),
      //   ru: yup.string().required(),
      //   en: yup.string().required(),
      // }),
    }),
  });

  const initialValues = {
    login: '',
    email: '',
    password: '',
    merchant: {
      affiliate_commission: '',
      bank_account: '',
      bank_inn: '',
      bank_mfo: '',
      bank_name: '',
      company_brand_name: '',
      company_foundation_at: '',
      company_inn: '',
      company_name: '',
      company_oked: '',
      company_type: '',
      contact_1_email: '',
      contact_1_first_name: '',
      contact_1_last_name: '',
      contact_1_phone: '',
      contact_1_position: '',
      contact_1_second_name: '',
      contact_2_email: '',
      contact_2_first_name: '',
      contact_2_last_name: '',
      contact_2_phone: '',
      contact_2_position: '',
      contact_2_second_name: '',
      contract_number: '123',
      description: {
        uz: '',
        ru: '',
        en: '',
      },
      location_address: '',
      location_city: '',
      location_district: '',
      location_floor: '',
      location_house: '',
      location_number: '',
      location_street: '',
      logo: null,
      name: '',
      organization_type: 'bank',
      social_email: '',
      social_web: '',
    },
  };

  const [save] = query();
  const formik = useFormik({
    initialValues: { ...initialValues, ...data },
    onSubmit: (values) => {
      console.log('data', data);
      // const { id } = values;
      // const fd = new FormData();
      // fd.append('login', values.login);
      // fd.append('email', values.email);
      // save(objectToFormData(values))
      save(values)
        .unwrap()
        .then((res) => {
          toast.success(isNew ? 'Банк успешно создан' : 'Банк успешно обновлен');
          navigate(getRoute(ROUTE.BANKS, isNew && res.data.id, isNew ? 'activate' : ''));
        })
        .catch((error) => {
          if (error.status === 422) {
            const formikErrors = validationErrorsResponse(error.data.data);
            toast.error('Ошибка валидации');
            formik.setErrors(formikErrors);
          } else {
            toast.error('Ошибка сохранения банка');
            console.error(error);
          }
        });
    },
    validationSchema,
  });

  const form_sections = [
    {
      id: 'account',
      title: t('account', 'Аккаунт'),
      type: 'grid',
      fields: [
        {
          type: 'text',
          name: 'login',
          label: t('login', 'Логин'),
          width: 4,
        },
        {
          type: 'email',
          name: 'email',
          label: 'Email',
          width: 4,
        },
        {
          type: 'password',
          name: 'password',
          label: t('password', 'Пароль'),
          width: 4,
        },
      ],
    },
    {
      id: 'logo',
      title: t('logo', 'Логотип'),
      type: 'custom',
      content: (
        <FileInput
          existFile={data?.merchant?.logo}
          selectFile={(file: File) => formik.setFieldValue('merchant.logo', file)}
        />
      ),
    },
    {
      id: 'company',
      title: t('company', 'Компания'),
      type: 'grid',
      fields: [
        {
          type: 'text',
          name: 'merchant.company_type',
          label: t('company.type', 'Тип компании'),
          width: 4,
        },
        {
          type: 'text',
          name: 'merchant.company_brand_name',
          label: t('company.brand', 'Бренд'),
          width: 4,
        },
        {
          type: 'text',
          name: 'merchant.company_name',
          label: t('company.name', 'Название компании'),
          width: 4,
        },
        {
          type: 'number',
          name: 'merchant.company_inn',
          label: t('tin', 'ИНН'),
          width: 4,
        },
        {
          type: 'number',
          name: 'merchant.company_oked',
          label: t('oked', 'ОКЭД'),
          width: 4,
        },
        {
          type: 'date',
          name: 'merchant.company_foundation_at',
          label: t('company.foundationDate', 'Дата основания'),
          width: 4,
          InputLabelProps: { shrink: true },
        },
        {
          type: 'text',
          name: 'merchant.social_web',
          label: t('website', 'Веб сайт'),
          width: 4,
        },
        {
          type: 'text',
          name: 'merchant.social_email',
          label: 'Email',
          width: 4,
        },
      ],
    },
    {
      id: 'bank',
      title: t('bank', 'Банк'),
      type: 'grid',
      fields: [
        {
          type: 'text',
          name: 'merchant.bank_name',
          label: t('bank.name', 'Название банка'),
          width: 4,
        },
        {
          type: 'number',
          name: 'merchant.bank_account',
          label: t('paymentAccount', 'Расчетный счет'),
          width: 4,
        },
        {
          type: 'number',
          name: 'merchant.bank_mfo',
          label: t('mfo', 'МФО'),
          width: 4,
        },
        {
          type: 'number',
          name: 'merchant.bank_inn',
          label: t('banks.tin', 'ИНН банка'),
          width: 4,
        },
      ],
    },
    {
      id: 'comission',
      title: t('commission', 'Комиссия'),
      type: 'grid',
      fields: [
        {
          type: 'text',
          name: 'merchant.affiliate_commission',
          label: t('affiliateCommission', 'Комиссия аффилиата'),
          width: 4,
        },
      ],
    },
    {
      id: 'address',
      title: t('address', 'Адрес'),
      type: 'grid',
      fields: [
        {
          type: 'text',
          name: 'merchant.location_city',
          label: t('city', 'Город'),
          width: 4,
        },
        {
          type: 'text',
          name: 'merchant.location_district',
          label: t('district', 'Район'),
          width: 4,
        },
        {
          type: 'text',
          name: 'merchant.location_street',
          label: t('street', 'Улица'),
          width: 4,
        },
        {
          type: 'text',
          name: 'merchant.location_house',
          label: t('house', 'Дом'),
          width: 4,
        },
        {
          type: 'text',
          name: 'merchant.location_floor',
          label: t('floor', 'Этаж'),
          width: 4,
        },
        {
          type: 'text',
          name: 'merchant.location_number',
          label: t('office', 'Офис'),
          width: 4,
        },
        {
          type: 'text',
          name: 'merchant.location_address',
          label: t('address', 'Адрес'),
          width: 12,
        },
      ],
    },
    {
      id: 'contact_1',
      title: t('contact_1', 'Контактное лицо 1'),
      type: 'grid',
      fields: [
        {
          type: 'text',
          name: 'merchant.contact_1_first_name',
          label: t('firstName', 'Имя'),
          width: 4,
        },
        {
          type: 'text',
          name: 'merchant.contact_1_last_name',
          label: t('lastName', 'Фамилия'),
          width: 4,
        },
        {
          type: 'text',
          name: 'merchant.contact_1_second_name',
          label: t('middleName', 'Отчество'),
          width: 4,
        },
        {
          type: 'text',
          name: 'merchant.contact_1_phone',
          label: t('phone', 'Телефон'),
          width: 4,
        },
        {
          type: 'text',
          name: 'merchant.contact_1_email',
          label: 'Email',
          width: 4,
        },
        {
          type: 'text',
          name: 'merchant.contact_1_position',
          label: t('position', 'Должность'),
          width: 4,
        },
      ],
    },
    {
      id: 'contact_2',
      title: t('contact_2', 'Контактное лицо 2'),
      type: 'grid',
      fields: [
        {
          type: 'text',
          name: 'merchant.contact_2_first_name',
          label: t('firstName', 'Имя'),
          width: 4,
        },
        {
          type: 'text',
          name: 'merchant.contact_2_last_name',
          label: t('lastName', 'Фамилия'),
          width: 4,
        },
        {
          type: 'text',
          name: 'merchant.contact_2_second_name',
          label: t('middleName', 'Отчество'),
          width: 4,
        },
        {
          type: 'text',
          name: 'merchant.contact_2_phone',
          label: t('phone', 'Телефон'),
          width: 4,
        },
        {
          type: 'text',
          name: 'merchant.contact_2_email',
          label: 'Email',
          width: 4,
        },
        {
          type: 'text',
          name: 'merchant.contact_2_position',
          label: t('position', 'Должность'),
          width: 4,
        },
      ],
    },
    {
      id: 'description',
      title: t('description', 'Описание'),
      type: 'grid',
      fields: [
        {
          type: 'textarea',
          name: 'merchant.description.ru',
          label: t('description.ru', 'Описание на русском'),
          width: 12,
        },
        {
          type: 'textarea',
          name: 'merchant.description.en',
          label: t('description.en', 'Описание на английском'),
          width: 12,
        },
        {
          type: 'textarea',
          name: 'merchant.description.uz',
          label: 'Описание на узбекском',
          width: 12,
        },
      ],
    },
    {
      id: 'merchant.attachments',
      title: 'Документы',
      type: 'custom',
      hide: isNew,
      content: (
        <DocumentsTable
          data={files}
          setData={setFiles}
          documentTypes={{
            contract_signed: 'Договор подписанный',
          }}
        />
      ),
    },
  ];

  return (
    <FormPageLayout
      title={isNew ? 'Новый банк' : 'Редактирование банка ' + data?.merchant.company_brand_name}
      onSave={() => {
        console.log('errors', formik.errors);
        formik.handleSubmit();
      }}
      onClose={() => navigate(ROUTE.BANKS)}
    >
      {/* <ApplicationStepper /> */}
      <FlexBox gap={3}>
        <Box position="relative" minWidth={240}>
          <List sx={{ position: 'sticky', top: 0 }}>
            {form_sections.map(
              (section) =>
                !section.hide && (
                  <ListItem button key={section.id} onClick={() => handleScroll(section.id)}>
                    <ListItemText primary={section.title} />
                  </ListItem>
                )
            )}
          </List>
        </Box>

        <Stack flexGrow={1} component="form" noValidate onSubmit={formik.handleSubmit}>
          {form_sections.map(
            (section) =>
              !section.hide && (
                <FormSection
                  title={section.title}
                  id={section.id}
                  key={section.id}
                  // actions={section.headerActions}
                >
                  {section.type === 'grid' && (
                    <Grid container spacing={2}>
                      {section.fields &&
                        section.fields.map((field: any, index: number) => {
                          if (!field) return null;

                          const { name, options, width, ...props } = field;
                          return (
                            <Grid item xs={12} sm={width} key={section.id + '_' + index}>
                              <TextField
                                {...props}
                                fullWidth
                                {...formik.getFieldProps(name)}
                                error={hasFormikError(formik, name)}
                                helperText={getFormikError(formik, name)}
                              >
                                {options &&
                                  options.map(({ label, value }: any) => (
                                    <MenuItem value={value} key={value}>
                                      {label}
                                    </MenuItem>
                                  ))}
                              </TextField>
                            </Grid>
                          );
                        })}
                    </Grid>
                  )}
                  {section.type === 'custom' && section.content}
                </FormSection>
              )
          )}
        </Stack>
      </FlexBox>
    </FormPageLayout>
  );
};

export default BankForm;
