import { Add } from '@mui/icons-material';
import { FC, ReactNode, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getSearchParams } from '../helpers/utils';
import useUser from '../hooks/useUser';
import { Column } from '../types';
import IconButton from './IconButton';
import ListFilter from './ListFilter';
import PageLayout from './PageLayout';
import SplitTable from './SplitTable';

interface ListLayoutProps {
  title: string;
  icon?: any;
  query: any;
  cols: Column[];
  createPermission?: string;
  filter?: boolean;
  expandRow?: (row: any) => ReactNode;
  exportMutation?: any;
}

const emptyProps = { page: 1, sort: undefined, order: undefined };

const ListLayout: FC<ListLayoutProps> = ({
  title,
  icon,
  query,
  cols,
  createPermission,
  filter = false,
  expandRow,
  exportMutation,
}) => {
  const [searchParams] = useSearchParams();
  const [rows, setRows] = useState([]);
  const [props, setProps] = useState(getSearchParams());
  const [total, setTotal] = useState(0);
  const navigate = useNavigate();
  const { hasAccess } = useUser();

  const { data, isLoading } = query({ page: 1, ...props });
  const [toExport, { isLoading: isExporting }] = exportMutation
    ? exportMutation()
    : [function () {}, { isLoading: false }];

  useEffect(() => {
    if (data) {
      setRows(data.data.items);
      setTotal(data.data.total);
    }
  }, [data]);

  useEffect(() => {
    if (searchParams.size === 0) {
      setProps(emptyProps);
      return;
    }

    setProps((prev: any) => {
      return { ...prev, ...getSearchParams() };
    });
  }, [searchParams]);

  const handleExport = () => {
    if (exportMutation)
      toExport(props)
        .unwrap()
        .then((res: any) => {
          const link = document.createElement('a');
          link.href = res.data.url;
          link.setAttribute('download', 'file.xlsx');
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch((error: any) => {
          console.log(error);
          toast.error('Ошибка при экспорте');
        });
  };

  return (
    <PageLayout
      icon={icon}
      title={title}
      hasSearch
      actions={[
        (!createPermission || (createPermission && hasAccess('operation', createPermission))) && (
          <IconButton
            variant="contained"
            sx={{ transform: 'translateY(32px)' }}
            onClick={() => navigate('add')}
            key="add"
          >
            <Add />
          </IconButton>
        ),
      ]}
    >
      {filter && <ListFilter onExport={handleExport} exportLoading={isExporting} />}
      <SplitTable {...{ cols, rows, total, props, setProps, isLoading, expandRow }} />
    </PageLayout>
  );
};

export default ListLayout;
