import { Avatar, Button, Divider, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { renderFullName } from '../../helpers/format';
import { formatPrice } from '../../helpers/numbers';
import useLocale from '../../hooks/useLocale';
import DataSetCard from '../DataSetCard';
import DetailsDrawerContainer from '../DetailsDrawerContainer';
import FlexBox from '../FlexBox';
import InfoRow from '../InfoRow';

interface ClientInfoProps {
  data: any;
  onClose: () => void;
}

const ClientInfo: FC<ClientInfoProps> = ({ data, onClose }) => {
  const { locale } = useLocale();
  return (
    <DetailsDrawerContainer closeDrawer={onClose}>
      <Stack gap={2}>
        <FlexBox gap={2.5}>
          <Avatar src={data?.img} variant="square" sx={{ width: 80, height: 60, '& img': { objectFit: 'contain' } }} />
          <Typography variant="h3">{renderFullName(data.client)}</Typography>
        </FlexBox>

        <Stack>
          <InfoRow label="ID клиента" value={data.id} />
          <Divider />
          <InfoRow label="ФИО" value={renderFullName(data.client)} />
          <Divider />
          <InfoRow label="ПИНФЛ" value={data.client.pinfl} />
          <Divider />
          <InfoRow
            label="Номер телефона"
            value={
              <Stack>
                <div>{data.client.phone_1}</div>
                <div>{data.client.phone_2}</div>
              </Stack>
            }
          />
          <Divider />
        </Stack>

        <Typography variant="h4" mt={4}>
          Полисы
        </Typography>

        {data.policies.map((policy: any) => (
          <DataSetCard
            data={[
              {
                label: 'Наименование',
                value: policy.product.name[locale],
              },
              {
                label: 'Страховая сумма',
                value: formatPrice(policy.insurance_amount),
              },
              {
                label: 'Статус',
                value: policy.status,
              },
              {
                label: 'Полис',
                value: policy.policy && (
                  <Button download href={policy.policy.url}>
                    Скачать
                  </Button>
                ),
              },
            ]}
          />
        ))}

        {/* <Typography variant="h4" mt={4}>
          Текущие кредиты
        </Typography>
        <Stack>
          <InfoRow
            label="Наименование товара"
            value="Ноутбук Lenovo IdeaPad 3 15IGL05 81WQ00EMRK"
            infoClick={() => console.log("info click")}
          />
          <InfoRow
            label="Статус погашений"
            value={
              <Chip
                label="Есть просрочка"
                color="warning"
                icon={<InfoRounded sx={{ fontSize: 20 }} />}
              />
            }
          />
          <Divider />
          <InfoRow
            label="Наименование товара"
            value="Смартфон Vivo Y02 2/32GB Orchi"
            infoClick={() => console.log("info click")}
          />
          <InfoRow
            label="Статус погашений"
            value={<Chip label="Без просрочек" color="success" />}
          />
          <Divider />
        </Stack> */}
      </Stack>
    </DetailsDrawerContainer>
  );
};

export default ClientInfo;
