import { FC } from "react";
import { useGetProductsQuery } from "../../app/api/productsApi";
import InsuranceProductForm from "../../components/products/InsuranceProductForm";

interface CreateProps {}

const InsuranceProductsCreatePage: FC<CreateProps> = () => {
  const { data: products } = useGetProductsQuery({ limit: 999 });

  if (!products) return null;

  return (
    <InsuranceProductForm isNew data={{}} products={products.data.items} />
  );
};

export default InsuranceProductsCreatePage;
