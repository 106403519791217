import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { objectToFormData } from '../../helpers/form';
import { urlWithSearchParams } from '../../helpers/format';
import { rtkBaseQuery } from '../../helpers/rtkquery';

export const merchantBranchesApi = createApi({
  reducerPath: 'merchantBranchesApi',
  baseQuery: rtkBaseQuery('/merchant-branches'),
  tagTypes: ['MerchantBranches'],
  endpoints: (builder) => ({
    getMerchantBranches: builder.query({
      query: (props) => urlWithSearchParams('', props),
      providesTags: ['MerchantBranches'],
    }),
    getMerchantBranch: builder.query({
      query: (id) => `${id}`,
      providesTags: (_result, _error, { id }) => [{ type: 'MerchantBranches', id }],
    }),
    saveMerchantBranch: builder.mutation({
      query: (body) => ({
        url: '',
        method: 'POST',
        body: objectToFormData(body),
      }),
      invalidatesTags: ['MerchantBranches'],
    }),
    activateMerchantBranch: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `${id}/activate`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (_result, _error, { id }) => ['MerchantBranches', { type: 'MerchantBranches', id }],
    }),
    updateMerchantBranch: builder.mutation({
      query: (body) => ({
        url: `${body.id}`,
        // method: 'PUT',
        method: 'POST',
        body: objectToFormData({ ...body, _method: 'PUT' }),
      }),
      invalidatesTags: (_result, _error, { id }) => ['MerchantBranches', { type: 'MerchantBranches', id }],
    }),
  }),
});

export const {
  useGetMerchantBranchesQuery,
  useGetMerchantBranchQuery,
  useSaveMerchantBranchMutation,
  useActivateMerchantBranchMutation,
  useUpdateMerchantBranchMutation,
} = merchantBranchesApi;
