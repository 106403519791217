export const ROUTE = {
  HOME: "/",
  DASHBOARD: "/dashboard",
  APPLICATIONS: "/dashboard/applications",
  BANKS: "/dashboard/banks",
  INSURANCES: "/dashboard/insurances",
  PRODUCTS: "/dashboard/products",
  INSURANCE_PRODUCTS: "/dashboard/insurance-products",
  PRODUCT_CATEGORIES: "/dashboard/product-categories",
  BRANCHES: "/dashboard/branches",
  SELLERS: "/dashboard/sellers",
  CLIENTS: "/dashboard/clients",
  POLICIES: "/dashboard/policies",
  SCORING: "/dashboard/scoring",
  REPORTS: "/dashboard/reports",
  SETTINGS: "/dashboard/settings",
  USERS: "/dashboard/settings/users",
  ROLES: "/dashboard/settings/roles",
  USER_ROLES: "/dashboard/settings/user_roles",
  MODULES: "/dashboard/settings/modules",
};

export const getRoute = (
  route: string,
  id: string | number,
  suffix?: string
) => {
  return `${route}/${id}${suffix ? `/${suffix}` : ""}`;
};
