import { CssBaseline, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ru from 'date-fns/locale/ru';
import { StrictMode, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './app/i18n';
import router from './app/router';
import { store } from './app/store';
import theme from './app/theme';
import './helpers/yup';
import './index.css';

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <StrictMode>
    <Suspense fallback="">
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ru}>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <RouterProvider router={router} />

            <ToastContainer
              position="top-right"
              theme="colored"
              pauseOnHover
              autoClose={2000}
              newestOnTop={false}
              hideProgressBar
            />
          </ThemeProvider>
        </Provider>
      </LocalizationProvider>
    </Suspense>
  </StrictMode>
);
