import { Avatar } from '@mui/material';
import { FC } from 'react';
import { useGetInsurancesQuery } from '../../app/api/insurancesApi';
import DetailsButton from '../../components/DetailsButton';
import { InsuranceIcon } from '../../components/Icons';
import ListLayout from '../../components/ListLayout';
import InsuranceInfo from '../../components/insurances/InsuranceInfo';
import { useDrawer } from '../../contexts/DrawerContext';
import { renderFullName } from '../../helpers/format';
import { Column } from '../../types';
import { t } from 'i18next';

interface Props {}

const InsurancesListPage: FC<Props> = () => {
  const { openDrawer } = useDrawer();

  const cols: Column[] = [
    {
      id: 'id',
      label: 'ID',
      width: 60,
    },
    {
      id: 'logo',
      label: '',
      width: 20,
      renderCell: (data: any) => (
        <Avatar
          variant="rounded"
          src={data.merchant.logo?.url || '/assets/images/product-image-placeholder.png'}
          sx={{
            width: 60,
            height: 40,
            borderRadius: 1,
            mr: -2,
          }}
        />
      ),
    },
    {
      id: 'name',
      label: 'Название компании',
      renderCell: (data: any) => data.merchant.company_brand_name,
    },
    {
      id: 'policies_count',
      label: 'Всего выдано полисов',
      renderCell: (data: any) => data.policies.count,
    },
    {
      id: 'contact_person',
      label: 'Контактное лицо',
      renderCell: (data: any) => renderFullName(data.merchant, 'contact_1'),
    },
    {
      id: 'phone',
      label: 'Телефон',
      renderCell: (data: any) => data.merchant.contact_1_phone,
    },
    {
      id: 'is_active_api',
      label: t('isActiveApi', 'Активен API'),
      renderCell: (data: any) => (data.merchant.is_active_api ? 'Да' : 'Нет'),
      // width: 150,
    },
    {
      id: 'actions',
      label: '',
      width: 60,
      renderCell: (data: any) => <DetailsButton onClick={() => handleInfo(data)} />,
    },
  ];

  const handleInfo = (data: any) => {
    openDrawer({
      content: <InsuranceInfo data={data} />,
    });
  };

  return (
    <ListLayout
      icon={<InsuranceIcon />}
      title="Страховые компании"
      query={useGetInsurancesQuery}
      cols={cols}
      createPermission="UserMerchantInsurance@store"
    />
  );
};

export default InsurancesListPage;
