import { Box, Paper, Stack, Typography } from "@mui/material";
import { FC, ReactNode } from "react";

interface FormSectionProps {
  children?: ReactNode;
  title?: string;
  id?: string;
  actions?: ReactNode;
}

const FormSection: FC<FormSectionProps> = ({
  children,
  title,
  id,
  actions,
}) => {
  return (
    <Box component="section" mb={2} id={id} width={1}>
      {title && (
        <Stack direction="row" alignItems="center" gap={2}>
          <Typography variant="h4" p={2.5}>
            {title}
          </Typography>
          {actions}
        </Stack>
      )}
      <Box
        component={Paper}
        bgcolor="white"
        elevation={0}
        width={1}
        px={2.5}
        py={3}
      >
        {children}
      </Box>
    </Box>
  );
};

export default FormSection;
