import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { urlWithSearchParams } from "../../helpers/format";
import { rtkBaseQuery } from "../../helpers/rtkquery";

export const attachmentsApi = createApi({
  reducerPath: "attachmentsApi",
  baseQuery: rtkBaseQuery("/attachments"),
  endpoints: (builder) => ({
    getAttachments: builder.query({
      query: (params) => urlWithSearchParams("", params),
    }),
    getAttachmentById: builder.query({
      query: (id) => `${id}`,
    }),
    uploadAttachment: builder.mutation({
      query: (body) => ({
        url: "",
        method: "post",
        body,
      }),
    }),
    deleteAttachment: builder.mutation({
      query: (id) => ({
        url: `${id}`,
        method: "delete",
      }),
    }),
  }),
});

export const {
  useGetAttachmentsQuery,
  useGetAttachmentByIdQuery,
  useUploadAttachmentMutation,
  useDeleteAttachmentMutation,
} = attachmentsApi;
