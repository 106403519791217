import { ArrowForwardIos } from "@mui/icons-material";
import { Button, ButtonProps } from "@mui/material";
import { FC } from "react";

interface DetailsButtonProps extends ButtonProps {}

const DetailsButton: FC<DetailsButtonProps> = ({ sx, ...props }) => {
  return (
    <Button
      variant="outlined"
      sx={{
        fontSize: 14,
        p: 0,
        minWidth: 30,
        width: 30,
        height: 30,
        color: "#999999",
        borderColor: "#E2E2E2",
        borderRadius: "50%",
        ...sx,
      }}
      {...props}
    >
      <ArrowForwardIos fontSize="inherit" />
    </Button>
  );
};

export default DetailsButton;
