import { Close } from '@mui/icons-material';
import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { toast } from 'react-toastify';
import { useCancelPolicyMutation } from '../../app/api/policiesApi';
import { renderCompanyName, renderFullName } from '../../helpers/format';
import { formatPrice } from '../../helpers/numbers';
import { useDialog } from '../../hooks/useDialog';
import useLocale from '../../hooks/useLocale';
import DataSetCard from '../DataSetCard';
import DeleteConfirmDialog from '../DeleteConfirmDialog';
import FlexBox from '../FlexBox';

interface Props {
  data: any;
  onClose: () => void;
}

const PolicyInfo: FC<Props> = ({ data, onClose }) => {
  const { locale } = useLocale();
  const { openDialog, closeDialog, renderDialog } = useDialog();
  const [cancel, { isLoading: isCanceling }] = useCancelPolicyMutation();
  const handleCancelPolicy = () => {
    cancel(data.id)
      .unwrap()
      .then(console.log)
      .catch((error) => {
        console.error(error);
        toast.error(error.data.message);
      });
  };

  const handleConfirmAnnulment = () => {
    openDialog(
      <DeleteConfirmDialog
        title="Вы действительно хотите аннулировать данный полис?"
        confirm={() => {
          handleCancelPolicy && handleCancelPolicy();
          onClose();
          closeDialog();
        }}
        close={closeDialog}
      />
    );
  };

  return (
    <Stack width={466} px={2.5} py={3.5} gap={3} height={1}>
      <FlexBox alignItems="flex-start" gap={2.5}>
        <Stack gap={0.5} alignItems="flex-start">
          <Typography variant="h3">Полис</Typography>
          <Typography variant="body2" color="#999999">
            ID: {data.id}
          </Typography>
        </Stack>

        <Box display="flex" gap={2.5} ml="auto">
          <Button
            variant="contained"
            size="large"
            sx={{ height: 50 }}
            href={data?.policy?.url}
            target="_blank"
            download="policy.xls"
            rel="noreferrer"
          >
            Скачать полис
          </Button>

          <Button variant="contained" size="large" sx={{ width: 50, height: 50, bgcolor: '#dddddd' }} onClick={onClose}>
            <Close />
          </Button>
        </Box>
      </FlexBox>

      <Stack gap={1}>
        <DataSetCard
          data={[
            {
              label: 'Страховая компания',
              value: renderCompanyName(data.insurance),
            },
            { label: 'Банк', value: renderCompanyName(data.bank) },
            { label: 'Наименование клиента', value: renderFullName(data.client) },
            { label: 'Страхователь', value: renderCompanyName(data.policyholder) },
            { label: 'Выгодопреобретатель', value: <>&mdash;</> },
            { label: 'Юр.лицо / Физ.лицо', value: data.client.is_legal_form ? 'Юр.лицо' : 'Физ.лицо' },
            { label: 'Продукт', value: data.product.name[locale] },
          ]}
        />
      </Stack>

      <Stack gap={1}>
        <Typography variant="h6" color="#424242" textTransform="uppercase" mb={1} pl={2.5} letterSpacing={'0.05em'}>
          Обьект страхования
        </Typography>

        <DataSetCard
          data={[
            {
              label: 'Обьект страхования',
              value: data.insurance_object,
            },
            {
              label: 'Сумма страхования',
              value: formatPrice(data.insurance_amount),
            },
          ]}
        />
      </Stack>

      <Stack mt="auto">
        <Button
          variant="contained"
          size="large"
          color="error"
          fullWidth
          onClick={handleConfirmAnnulment}
          disabled={isCanceling || data.status === 'cancelled'}
          startIcon={isCanceling && <CircularProgress />}
        >
          Аннулировать полис
        </Button>
      </Stack>

      {renderDialog()}
    </Stack>
  );
};

export default PolicyInfo;
