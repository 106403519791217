import { createApi } from "@reduxjs/toolkit/query/react";
import { rtkBaseQuery } from "../../helpers/rtkquery";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: rtkBaseQuery("/user"),
  endpoints: (builder) => ({
    getUserInfo: builder.query({
      query: () => "info",
    }),
  }),
});

export const { useGetUserInfoQuery } = userApi;
