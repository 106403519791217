import { Button, ButtonProps } from "@mui/material";
import { FC, useEffect, useState } from "react";

const IconButton: FC<ButtonProps> = ({ sx, ...props }) => {
  const [width, setWidth] = useState(35);
  useEffect(() => {
    switch (props.size) {
      case "large":
        setWidth(50);
        break;
      case "medium":
        setWidth(35);
        break;
      case "small":
        setWidth(30);
        break;
      default:
        setWidth(35);
        break;
    }
  }, [props.size]);

  return (
    <Button
      sx={{
        borderRadius: 10,
        height: width,
        minWidth: width,
        width,
        ...sx,
      }}
      {...props}
    />
  );
};

export default IconButton;
