import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
} from "@mui/material";
import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useGetModulesQuery } from "../../app/api/modulesApi";
import { useGetRoleByIdQuery } from "../../app/api/rolesApi";
import DashboardContent from "../../components/DashboardContent";
import FormHeader from "../../components/FormHeader";
import FormSection from "../../components/FormSection";
import { RecordObject } from "../../types";

interface UserEditProps {}

const RoleEdit: FC<UserEditProps> = () => {
  const { i18n } = useTranslation();
  const { id } = useParams();
  const [role, setRole] = useState<RecordObject>({});
  const [modules, setModules] = useState([]);

  const [roleOperations, setRoleOperations] = useState([]);
  const [availableModules, setAvailableModules] = useState<any[]>([]);

  const { data } = useGetRoleByIdQuery(id);
  const { data: modulesData } = useGetModulesQuery({ limit: 999 });

  useEffect(() => {
    if (data) {
      setRole(data.data);
      setRoleOperations(data.data.operations.items || []);
      formik.setValues(data.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (roleOperations.length) {
      const modules = roleOperations.map((op: any) => op.module_id);
      const uniqueModules = modules.filter(
        (v: any, i: any, a: any) => a.indexOf(v) === i
      );
      setAvailableModules(uniqueModules);
    }
  }, [roleOperations]);

  useEffect(() => {
    if (modulesData) setModules(modulesData.data.items);
  }, [modulesData]);

  const formik = useFormik({
    initialValues: {
      ...role,
    },
    onSubmit: (values) => {},
  });

  if (!data) return null;

  return (
    <DashboardContent>
      <Stack>
        <FormHeader title={`Редактирование роли `} />

        <FormSection title="Операции" id="operations" key="operations">
          <FormGroup>
            <Grid container>
              {modules.map(
                (module: any) =>
                  availableModules.includes(module.id) && (
                    <Grid item xs={12} md={6} lg={4} key={module.id}>
                      <FormSection
                        title={module.name[i18n.language]}
                        id={module.id}
                      >
                        <Stack>
                          {module.operations.map((operation: any) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled
                                  defaultChecked={Boolean(
                                    roleOperations.find(
                                      (o: any) =>
                                        o.operation_id === operation.id
                                    )
                                  )}
                                />
                              }
                              label={operation.name[i18n.language]}
                              value={operation.id}
                              key={operation.id}
                            />
                          ))}
                        </Stack>
                      </FormSection>
                    </Grid>
                  )
              )}
            </Grid>
          </FormGroup>
        </FormSection>
      </Stack>
    </DashboardContent>
  );
};

export default RoleEdit;
