import { Dialog, DialogProps, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import {
  ReactElement,
  ReactNode,
  Ref,
  createElement,
  forwardRef,
  useState,
} from "react";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement<any, any>;
  },
  ref: Ref<unknown>
) {
  return createElement(Slide, {
    direction: "down",
    ref,
    ...props,
  });
});

export const useDialog = () => {
  const [open, setOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState<ReactNode>();

  const openDialog = (dialog: ReactNode) => {
    setDialogContent(dialog);
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const renderDialog = (props?: Partial<DialogProps>) =>
    createElement(
      Dialog,
      {
        open,
        onClose: closeDialog,
        TransitionComponent: Transition,
        ...props,
      },
      dialogContent
    );

  return { openDialog, closeDialog, renderDialog };
};
