import { DeleteOutline } from "@mui/icons-material";
import {
  Button,
  DialogActions,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { FC } from "react";

interface ConfirmDialogProps {
  title: string;
  confirm: () => void;
  close: () => void;
}

const DeleteConfirmDialog: FC<ConfirmDialogProps> = ({
  title,
  confirm,
  close,
}) => {
  return (
    <Stack width={450} p={2}>
      <DialogTitle>
        <Typography variant="h4">{title}</Typography>
      </DialogTitle>
      <DialogActions>
        <Stack direction="row" gap={2}>
          <Button size="large" variant="outlined" onClick={close}>
            Отмена
          </Button>
          <Button
            size="large"
            variant="contained"
            onClick={confirm}
            startIcon={<DeleteOutline />}
            color={"error"}
          >
            Удалить
          </Button>
        </Stack>
      </DialogActions>
    </Stack>
  );
};

export default DeleteConfirmDialog;
