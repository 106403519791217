import { Button, Paper, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import CloseButton from "./CloseButton";

interface Props {
  title: string;
  onClose?: () => void;
  onSave?: () => void;
}

const FormHeader: FC<Props> = ({ title, onClose, onSave }) => {
  const navigate = useNavigate();
  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      navigate(-1);
    }
  };
  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={2}
      px={1.5}
      py={1.25}
      component={Paper}
      elevation={0}
      bgcolor="primary.light"
      mb={6}
    >
      <CloseButton color="secondary" onClick={handleClose} />
      <Typography flexGrow={1} variant="h3">
        {title}
      </Typography>
      {onSave && (
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={onSave}
        >
          Сохранить
        </Button>
      )}
    </Stack>
  );
};

export default FormHeader;
