import { Stack } from "@mui/material";
import { FC } from "react";
import FlexBox from "./FlexBox";
import GreyCard from "./GreyCard";

interface Props {
  data: {
    label: string;
    value: string | number;
  }[];
}

const DataSetCard: FC<Props> = ({ data }) => {
  return (
    <GreyCard sx={{ paddingBottom: 2 }}>
      {data.map((item, index) => (
        <FlexBox
          py={2}
          alignItems="center"
          key={index}
          borderBottom={1.5}
          borderColor="#EBEAEF"
        >
          <Stack mr={2}>{item.label}</Stack>
          <Stack textAlign="right" ml="auto">
            {item.value}
          </Stack>
        </FlexBox>
      ))}
    </GreyCard>
  );
};

export default DataSetCard;
