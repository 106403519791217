import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { defaultLocale } from "../app/i18n";
import { Locale } from "./../app/i18n";

const useLocale = () => {
  const { t, i18n } = useTranslation();
  const [locale, setLocale] = useState<Locale>(
    (localStorage.getItem("locale") as Locale) || defaultLocale
  );

  useEffect(() => {
    i18n.changeLanguage(locale);
    localStorage.setItem("locale", locale);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  return {
    locale,
    setLocale,
    t,
  };
};

export default useLocale;
