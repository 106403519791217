import { Drawer } from "@mui/material";
import { createContext, ReactNode, useContext, useState } from "react";

interface DrawerContextType {
  openDrawer: (content: DrawerContentOptions) => void;
  closeDrawer: () => void;
  drawerOpen: boolean;
  drawerContent: any;
}

interface DrawerContentOptions {
  content: ReactNode;
  anchor?: "left" | "right" | "top" | "bottom";
}

export const DrawerContext = createContext<DrawerContextType>({
  openDrawer: () => {},
  closeDrawer: () => {},
  drawerOpen: false,
  drawerContent: null,
});

export const DrawerProvider = ({ children }: any) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerContent, setDrawerContent] = useState<DrawerContentOptions>({
    content: null,
    anchor: "right",
  });

  const openDrawer = (options: DrawerContentOptions) => {
    setDrawerContent(options);
    setDrawerOpen(true);
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
    setTimeout(() => {
      setDrawerContent({ content: null });
    }, 300);
  };

  return (
    <DrawerContext.Provider
      value={{
        openDrawer,
        closeDrawer,
        drawerOpen,
        drawerContent,
      }}
    >
      {children}
      <Drawer
        anchor={drawerContent.anchor || "right"}
        open={drawerOpen}
        onClose={closeDrawer}
        sx={{
          "& .MuiDrawer-paper": {
            top: 12,
            bottom: 12,
            height: "auto",
            right: 12,
            boxShadow: "none",
            borderRadius: 2.5,
          },
        }}
      >
        {drawerContent.content}
      </Drawer>
    </DrawerContext.Provider>
  );
};

export const useDrawer = () => {
  return useContext(DrawerContext);
};
