import { EditOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTE, getRoute } from "../../constants/routes";
import { useDrawer } from "../../contexts/DrawerContext";
import { formatPrice } from "../../helpers/numbers";
import useLocale from "../../hooks/useLocale";
import DataSetCard from "../DataSetCard";
import FlexBox from "../FlexBox";

interface Props {
  data: any;
  insurance?: boolean;
}

const ProductInfo: FC<Props> = ({ data, insurance }) => {
  const navigate = useNavigate();
  const { locale } = useLocale();
  const { closeDrawer } = useDrawer();

  const handleEdit = () => {
    navigate(
      getRoute(
        insurance ? ROUTE.INSURANCE_PRODUCTS : ROUTE.PRODUCTS,
        data.id,
        "edit"
      )
    );
    closeDrawer();
  };

  const policy_fields = insurance
    ? data.policy_fields
    : data.policy_fields.map((section: any) => section.fields).flat();

  return (
    <Stack width={466} px={2.5} py={3.5} gap={6}>
      <FlexBox alignItems="flex-start" gap={2.5}>
        {/* <Avatar
          src={data.logo?.url}
          variant="square"
          sx={{ width: 80, height: 60, "& img": { objectFit: "contain" } }}
        /> */}
        <Stack gap={0.5} alignItems="flex-start">
          <Typography variant="h3">{data.name[locale]}</Typography>
          <Typography variant="body2" color="#999999">
            ID: {data.id}
          </Typography>
        </Stack>

        <Box display="flex" gap={2.5} ml="auto">
          <Button
            variant="contained"
            size="large"
            sx={{ width: 45, height: 45 }}
            onClick={handleEdit}
          >
            <EditOutlined />
          </Button>
        </Box>
      </FlexBox>

      <Stack gap={1}>
        <Typography
          variant="h6"
          color="#424242"
          textTransform="uppercase"
          mb={1}
          pl={2.5}
          letterSpacing={"0.05em"}
        >
          Информация
        </Typography>

        <DataSetCard
          data={[
            {
              label: "Правовая форма",
              value: data.is_legal_form ? "Юр. лицо" : "Физ. лицо",
            },
            {
              label: "Код",
              value: data.code,
            },
          ]}
        />
      </Stack>

      {insurance && (
        <Stack gap={1}>
          <Typography
            variant="h6"
            color="#424242"
            textTransform="uppercase"
            mb={1}
            pl={2.5}
            letterSpacing={"0.05em"}
          >
            Условия
          </Typography>

          <DataSetCard
            data={[
              { label: "Срок (мес)", value: data.term },
              { label: "Тариф (%)", value: data.tariff },
              {
                label: "Максимальная сумма покрытия (сум)",
                value: (
                  <Box whiteSpace="nowrap">
                    {formatPrice(data.coverage_amount_max)}
                  </Box>
                ),
              },
            ]}
          />
        </Stack>
      )}

      <Stack gap={1}>
        <Typography
          variant="h6"
          color="#424242"
          textTransform="uppercase"
          mb={1}
          pl={2.5}
          letterSpacing={"0.05em"}
        >
          Поля
        </Typography>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Наименование</TableCell>
              <TableCell>Код</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {policy_fields.map((field: any, index: number) => (
              <TableRow key={index}>
                <TableCell>{field.id}</TableCell>
                <TableCell>
                  {field.name}{" "}
                  {field.is_required && <span style={{ color: "red" }}>*</span>}
                </TableCell>
                <TableCell>{field.code}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Stack>
    </Stack>
  );
};

export default ProductInfo;
