import { createApi } from "@reduxjs/toolkit/query/react";
import { rtkBaseQuery } from "../../helpers/rtkquery";
export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: rtkBaseQuery(),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (body: { login: string; password: string }) => ({
        url: "/login",
        method: "post",
        body,
      }),
    }),

    logout: builder.mutation({
      query: () => ({
        url: "/logout",
        method: "post",
      }),
    }),
  }),
});

export const { useLoginMutation, useLogoutMutation } = authApi;
