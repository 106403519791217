import { darken, lighten, PaletteOptions } from '@mui/material/styles';

const palette: PaletteOptions = {
  mode: 'light',
  primary: {
    main: '#2A8684',
    light: '#FFFFFF',
  },
  secondary: {
    main: '#F3F5F7',
    light: lighten('#F3F5F7', 0.05),
    dark: darken('#F3F5F7', 0.05),
    contrastText: '#989BA5',
  },
  error: {
    main: '#D67575',
    contrastText: '#FFF3F3',
  },
  // error: {
  //   main: "#F44336",
  //   contrastText: "#FFFFFF",
  // },
  success: {
    main: '#00AB55',
    contrastText: '#FFFFFF',
  },
  warning: {
    main: '#FFC107',
    contrastText: '#FFFFFF',
  },
};

export default palette;
