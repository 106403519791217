import { FC } from "react";
import ApplicationForm from "../../components/application/ApplicationForm";
import {useGetProductsQuery} from "../../app/api/productsApi";

interface CreateProps {}

const ApplicationCreatePage: FC<CreateProps> = () => {
  const { data: productsData } = useGetProductsQuery({ limit: 999 });

  if (!productsData) return null;

  return <ApplicationForm products={productsData.data.items || []} />;
};

export default ApplicationCreatePage;
