import { Box, Grid, List, ListItem, ListItemText, MenuItem, Stack, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { useSaveMerchantBranchMutation, useUpdateMerchantBranchMutation } from '../../app/api/merchantBranchesApi';
import { getFormikError, hasFormikError, validationErrorsResponse } from '../../helpers/form';
import { handleScroll } from '../../helpers/utils';
import DocumentsTable from '../DocumentsTable';
import FlexBox from '../FlexBox';
import FormPageLayout from '../FormPageLayout';
import FormSection from '../FormSection';

interface Props {
  isNew?: boolean;
  data: any;
}

const BranchForm: FC<Props> = ({ data, isNew }) => {
  const navigate = useNavigate();
  const [files, setFiles] = useState(data?.merchant_branch?.attachments || []);
  const query = isNew ? useSaveMerchantBranchMutation : useUpdateMerchantBranchMutation;

  const initialValues = {
    login: '',
    email: '',
    password: '',
    merchant_branch: {
      name: '',
      company_legal_address: '',
      contact_1_first_name: '',
      contact_1_last_name: '',
      contact_1_second_name: '',
      contact_1_phone: '',
      contact_1_email: '',
      contact_2_first_name: '',
      contact_2_last_name: '',
      contact_2_second_name: '',
      contact_2_phone: '',
      contact_2_email: '',
      description: {
        uz: '',
        ru: '',
        en: '',
      },
    },

    ...data,
  };

  const validationSchema = yup.object().shape({
    login: yup.string().required(),
    email: yup.string().email(),
    password: isNew ? yup.string().required().min(6) : yup.string(),
    merchant_branch: yup.object().shape({
      name: yup.string().required(),
      company_legal_address: yup.string().required(),
      contact_1_first_name: yup.string().required(),
      contact_1_last_name: yup.string().required(),
      // contact_1_second_name: yup.string().required(),
      // contact_1_phone: yup.string().required(),
      // contact_1_email: yup.string().required(),
      // contact_2_first_name: yup.string(),
      // contact_2_last_name: yup.string(),
      // contact_2_second_name: yup.string(),
      // contact_2_phone: yup.string(),
      // contact_2_email: yup.string(),
      // description: yup.object().shape({
      //   uz: yup.string().required(),
      //   ru: yup.string().required(),
      //   en: yup.string().required(),
      // }),
    }),
  });

  const [save] = query();
  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      save(values)
        .unwrap()
        .then((res) => {
          toast.success('Успешно сохранено');
          navigate(`/dashboard/branches/${isNew ? res.data.id + '/activate' : ''}`);
        })
        .catch((error) => {
          if (error.status === 422) {
            const formikErrors = validationErrorsResponse(error.data.data);
            toast.error('Ошибка валидации');
            formik.setErrors(formikErrors);
          } else {
            toast.error('Ошибка сохранения');
            console.error(error);
          }
        });
    },
    validationSchema,
  });

  const form_sections = [
    {
      id: 'account',
      title: 'Аккаунт',
      type: 'grid',
      fields: [
        {
          type: 'text',
          name: 'login',
          label: 'Логин',
          width: 4,
        },
        {
          type: 'text',
          name: 'email',
          label: 'Email',
          width: 4,
        },
        {
          type: 'password',
          name: 'password',
          label: 'Пароль',
          width: 4,
        },
      ],
    },
    {
      id: 'branch',
      title: 'Филиал',
      type: 'grid',
      fields: [
        {
          type: 'text',
          name: 'merchant_branch.name',
          label: 'Название',
          width: 5,
        },
        {
          type: 'text',
          name: 'merchant_branch.company_legal_address',
          label: 'Юридический адрес',
          width: 7,
        },
      ],
    },
    {
      id: 'contact_1',
      title: 'Контактное лицо 1',
      type: 'grid',
      fields: [
        {
          type: 'text',
          name: 'merchant_branch.contact_1_first_name',
          label: 'Имя',
          width: 4,
        },
        {
          type: 'text',
          name: 'merchant_branch.contact_1_last_name',
          label: 'Фамилия',
          width: 4,
        },
        {
          type: 'text',
          name: 'merchant_branch.contact_1_second_name',
          label: 'Отчество',
          width: 4,
        },
        {
          type: 'text',
          name: 'merchant_branch.contact_1_phone',
          label: 'Телефон',
          width: 4,
        },
        {
          type: 'text',
          name: 'merchant_branch.contact_1_email',
          label: 'Email',
          width: 4,
        },
      ],
    },
    {
      id: 'contact_2',
      title: 'Контактное лицо 2',
      type: 'grid',
      fields: [
        {
          type: 'text',
          name: 'merchant_branch.contact_2_first_name',
          label: 'Имя',
          width: 4,
        },
        {
          type: 'text',
          name: 'merchant_branch.contact_2_last_name',
          label: 'Фамилия',
          width: 4,
        },
        {
          type: 'text',
          name: 'merchant_branch.contact_2_second_name',
          label: 'Отчество',
          width: 4,
        },
        {
          type: 'text',
          name: 'merchant_branch.contact_2_phone',
          label: 'Телефон',
          width: 4,
        },
        {
          type: 'text',
          name: 'merchant_branch.contact_2_email',
          label: 'Email',
          width: 4,
        },
      ],
    },
    {
      id: 'description',
      title: 'Описание',
      type: 'grid',
      fields: [
        {
          type: 'textarea',
          name: 'merchant_branch.description.ru',
          label: 'Описание на русском',
          width: 12,
        },
        {
          type: 'textarea',
          name: 'merchant_branch.description.en',
          label: 'Описание на английском',
          width: 12,
        },
        {
          type: 'textarea',
          name: 'merchant_branch.description.uz',
          label: 'Описание на узбекском',
          width: 12,
        },
      ],
    },
    {
      id: 'merchant_branch.attachments',
      title: 'Документы',
      type: 'custom',
      hide: isNew,
      content: (
        <DocumentsTable
          data={files}
          setData={setFiles}
          documentTypes={{
            contract_signed: 'Договор подписанный',
          }}
        />
      ),
    },
  ];

  return (
    <FormPageLayout
      title={isNew ? 'Новый филиал' : 'Редактирование филиала ' + data.merchant_branch.name}
      onSave={() => {
        console.log(formik.errors, formik.values);
        formik.handleSubmit();
      }}
      onClose={() => navigate('/dashboard/branches')}
    >
      <input hidden name="isNew" value={`${isNew}`} />
      <FlexBox gap={3}>
        <Box position="relative" minWidth={240}>
          <List sx={{ position: 'sticky', top: 0 }}>
            {form_sections.map(
              (section) =>
                !section.hide && (
                  <ListItem button key={section.id} onClick={() => handleScroll(section.id)}>
                    <ListItemText primary={section.title} />
                  </ListItem>
                )
            )}
          </List>
        </Box>
        <Stack flexGrow={1} component="form" noValidate onSubmit={formik.handleSubmit}>
          {form_sections.map(
            (section) =>
              !section.hide && (
                <FormSection
                  title={section.title}
                  id={section.id}
                  key={section.id}
                  // actions={section.headerActions}
                >
                  {section.type === 'grid' && (
                    <Grid container spacing={2}>
                      {section.fields &&
                        section.fields.map((field: any, index: number) => {
                          if (!field) return null;

                          const { name, options, width, ...props } = field;
                          return (
                            <Grid item xs={12} sm={width} key={section.id + '_' + index}>
                              <TextField
                                {...props}
                                fullWidth
                                {...formik.getFieldProps(name)}
                                error={hasFormikError(formik, name)}
                                helperText={getFormikError(formik, name)}
                              >
                                {options &&
                                  options.map(({ label, value }: any) => (
                                    <MenuItem value={value} key={value}>
                                      {label}
                                    </MenuItem>
                                  ))}
                              </TextField>
                            </Grid>
                          );
                        })}
                    </Grid>
                  )}
                  {section.type === 'custom' && section.content}
                </FormSection>
              )
          )}
        </Stack>
      </FlexBox>
    </FormPageLayout>
  );
};

export default BranchForm;
