import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useGetModulesQuery } from "../../app/api/modulesApi";
import ListLayout from "../../components/ListLayout";
import { Column } from "../../types";

interface ModulesProps {}

const Modules: FC<ModulesProps> = () => {
  const { i18n } = useTranslation();

  const cols: Column[] = [
    {
      id: "id",
      label: "ID",
      sortable: true,
      numeric: true,
      width: 30,
    },
    {
      id: "name",
      label: "Название модуля",
      sortable: true,
      numeric: false,
      renderCell: (data: any) => data.name[i18n.language],
    },
    {
      id: "code",
      label: "Код",
      sortable: true,
      numeric: false,
    },
  ];

  return <ListLayout cols={cols} query={useGetModulesQuery} title="Модули" />;
};

export default Modules;
