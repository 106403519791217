import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { urlWithSearchParams } from '../../helpers/format';
import { rtkBaseQuery } from '../../helpers/rtkquery';

export const policiesApi = createApi({
  reducerPath: 'policiesApi',
  baseQuery: rtkBaseQuery('/policies'),
  tagTypes: ['Policies'],
  endpoints: (builder) => ({
    getPolicies: builder.query({
      query: (props) => urlWithSearchParams('', props),
      providesTags: ['Policies'],
    }),
    getPolicy: builder.query({
      query: (id) => `${id}`,
      providesTags: (_result, _error, { id }) => [{ type: 'Policies', id }],
    }),
    createPolicy: builder.mutation({
      query: (body) => ({
        url: '',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Policies'],
    }),
    updatePolicy: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_result, _error, { id }) => ['Policies', { type: 'Policies', id }],
    }),
    deletePolicy: builder.mutation({
      query: (id) => ({
        url: `${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Policies'],
    }),

    exportPolicies: builder.mutation({
      query: (params) => ({
        url: `export`,
        method: 'POST',
        params,
      }),
    }),

    cancelPolicy: builder.mutation({
      query: (id) => ({
        url: `${id}/cancel`,
        method: 'PATCH',
      }),
      invalidatesTags: ['Policies'],
    }),
  }),
});

export const {
  useGetPoliciesQuery,
  useGetPolicyQuery,
  useCreatePolicyMutation,
  useUpdatePolicyMutation,
  useDeletePolicyMutation,
  useExportPoliciesMutation,
  useCancelPolicyMutation,
} = policiesApi;
