import { Typography } from "@mui/material";
import { FC } from "react";
import { useGetRoleOperationsQuery } from "../../app/api/roleOperationsApi";
import EnhancedTable from "../../components/EnhancedTable";
import { Column } from "../../types";

interface RoleOperationsProps {}

const cols: Column[] = [
  {
    id: "id",
    label: "ID",
    sortable: true,
    numeric: true,
    width: 30,
  },
  {
    id: "role_id",
    label: "ID роли",
    sortable: true,
    numeric: true,
  },
  {
    id: "operation_id",
    label: "ID операции",
    sortable: true,
    numeric: true,
  },
];

const RoleOperations: FC<RoleOperationsProps> = () => {
  useGetRoleOperationsQuery({});
  return (
    <div>
      <Typography>RoleOperations</Typography>
      <EnhancedTable cols={cols} query={useGetRoleOperationsQuery} />
    </div>
  );
};

export default RoleOperations;
