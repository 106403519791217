/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, FormLabel, Stack, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import Accordion from '../../components/Accordion';
import FlexBox from '../../components/FlexBox';
import ReportCheckAllButton from './ReportCheckAllButton';

interface Props {
  fields: Record<string, string>;
  updateFields: (fields: any) => void;
}

const ReportProductFields: FC<Props> = ({ fields, updateFields }) => {
  const [checkedAll, setCheckedAll] = useState<'all' | 'none' | 'partial'>('none');
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const handleSelectAll = () => {
    if (checkedAll !== 'all') {
      setSelectedIds(Object.keys(fields));
    } else {
      setSelectedIds([]);
    }
  };

  const handleCheck = (e: any) => {
    const { value, checked } = e.target;
    setSelectedIds((prev) => (checked ? [...prev, value] : prev.filter((id) => id !== value)));
  };

  useEffect(() => {
    if (selectedIds.length === 0) setCheckedAll('none');
    else if (selectedIds.length === Object.keys(fields).length) setCheckedAll('all');
    else setCheckedAll('partial');

    updateFields(selectedIds);
  }, [selectedIds]);

  // useEffect(() => {}, [fields]);

  if (!fields) return null;

  return (
    <Accordion
      summary="Список полей"
      action={<ReportCheckAllButton checkedAll={checkedAll} onClick={handleSelectAll} label="Выбрать все" />}
      details={
        <Stack gap={0.5}>
          {Object.entries(fields)?.map(([id, label]) => (
            <FlexBox
              component={FormLabel}
              key={id}
              sx={{
                bgcolor: 'background.paper',
                borderRadius: 0.625,
                py: 0.625,
                pr: 1.25,
                pl: 2.5,
                boxShadow: '1px 2px 5px 0px #E1DCD8',
                alignItems: 'center',
                justifyContent: 'space-between',
                cursor: 'pointer',
              }}
            >
              <Typography fontSize={13} lineHeight={1}>
                {label}
              </Typography>
              <Stack>
                <Checkbox sx={{ p: 0 }} checked={selectedIds.includes(id)} value={id} onClick={handleCheck} />
              </Stack>
            </FlexBox>
          ))}
        </Stack>
      }
    />
  );
};

export default ReportProductFields;
