import { Box, Link, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import FlexBox from '../components/FlexBox';
import LoginForm from '../components/LoginForm';
import { ROUTE } from '../constants/routes';

interface HomeProps {}

const Home: FC<HomeProps> = () => {
  const navigate = useNavigate();
  // const { isAuthorized } = useAuth();
  // console.log('🚀 ~ file: Home.tsx:14 ~ isAuthorized:', isAuthorized);
  const token = localStorage.getItem('token');
  if (token) navigate(ROUTE.DASHBOARD);

  return (
    <FlexBox height="100vh" width={1} bgcolor="black" color="white" overflow="hidden">
      <Stack minWidth={600} width={600} height={1} pl={13} pr={23}>
        <Stack my="auto">
          <Typography variant="h1" mb={2}>
            Страховой агрегатор
          </Typography>
          <Typography variant="caption" color="#8E8E8E">
            Версия 1.0
          </Typography>
          <LoginForm />
        </Stack>
        <Box pb={6.5}>
          <Typography color="#8E8E8E" variant="caption">
            Служба поддержки:{' '}
            <Link href="tel:+998909119191" sx={{ color: 'white', textDecoration: 'none' }}>
              +99890 911 91 91
            </Link>
          </Typography>
        </Box>
      </Stack>

      <Box
        component="img"
        src="/assets/images/home_bg.png"
        sx={{ objectFit: 'cover', width: 1, height: 1, flexGrow: 1 }}
      />
    </FlexBox>
  );
};

export default Home;
