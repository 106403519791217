import { ArrowForwardIos, Edit } from "@mui/icons-material";
import { Button } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useGetUsersQuery } from "../../app/api/usersApi";
import ListLayout from "../../components/ListLayout";
import UserInfo from "../../components/UserInfo";
import { useDrawer } from "../../contexts/DrawerContext";
import { renderFullName } from "../../helpers/format";
import { Column } from "../../types";

interface UsersProps {}

const Users: FC<UsersProps> = () => {
  const { t, i18n } = useTranslation();
  const locale = i18n.language;
  const { openDrawer, closeDrawer } = useDrawer();
  const navigate = useNavigate();

  const cols: Column[] = [
    {
      id: "id",
      label: "ID",
      sortable: true,
      numeric: true,
      width: 30,
    },
    {
      id: "first_name",
      label: "ФИО",
      sortable: true,
      numeric: false,
      renderCell: (data: any) => renderFullName(data),
    },
    {
      id: "email",
      label: "Email",
      sortable: true,
      numeric: false,
    },
    {
      id: "login",
      label: "Логин",
      sortable: true,
      numeric: false,
    },
    {
      id: "roles",
      label: "Роли",
      sortable: false,
      numeric: false,
      renderCell: (data: any) =>
        data.roles.map((role: any) => role.role_name[locale]).join(", "),
    },
    {
      id: "actions",
      label: "",
      sortable: false,
      numeric: false,
      width: 110,
      renderCell: (data: any) => [
        <Button
          variant="outlined"
          size="small"
          sx={{ fontSize: 24, p: 0.5, color: "#999999", mr: 1 }}
          onClick={() => navigate("" + data.id)}
        >
          <Edit fontSize="inherit" />
        </Button>,
        <Button
          variant="outlined"
          size="small"
          sx={{ fontSize: 24, p: 0.5, color: "#999999" }}
          onClick={() => handleDetails(data)}
        >
          <ArrowForwardIos fontSize="inherit" />
        </Button>,
      ],
    },
  ];

  const handleDetails = (data: any) => {
    openDrawer({
      content: <UserInfo data={data} onClose={closeDrawer} />,
    });
  };

  return (
    <ListLayout
      title={t("users", "Пользователи")}
      cols={cols}
      query={useGetUsersQuery}
    />
  );
};

export default Users;
