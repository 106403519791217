import PoliciesCreatePage from './CreatePage';
import PoliciesListPage from './ListPage';
import PoliciesUpdatePage from './UpdatePage';

const Policies = {
  List: PoliciesListPage,
  Create: PoliciesCreatePage,
  Update: PoliciesUpdatePage,
};

export default Policies;
