import { Stack, StackProps } from "@mui/material";
import { FC, ReactNode } from "react";

interface DashboardContentProps {
  children: ReactNode;
}

const DashboardContent: FC<DashboardContentProps & StackProps> = ({
  children,
  ...props
}) => {
  return (
    <Stack
      pt={4}
      px={3}
      pb={2}
      width={1}
      height={"100vh"}
      overflow="auto"
      {...props}
    >
      {children}
    </Stack>
  );
};

export default DashboardContent;
