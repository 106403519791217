import { createApi } from "@reduxjs/toolkit/query/react";
import { urlWithSearchParams } from "../../helpers/format";
import { rtkBaseQuery } from "../../helpers/rtkquery";

export const rolesApi = createApi({
  reducerPath: "rolesApi",
  baseQuery: rtkBaseQuery("/roles"),
  endpoints: (builder) => ({
    getAllRoles: builder.query({
      query: (params) => urlWithSearchParams("", params),
    }),
    getRoleById: builder.query({
      query: (id) => `${id}`,
    }),
    createRole: builder.mutation({
      query: (body) => ({
        url: "",
        method: "post",
        body,
      }),
    }),
    updateRole: builder.mutation({
      query: (body) => ({
        url: `${body.id}`,
        method: "put",
        body,
      }),
    }),
  }),
});

export const {
  useGetAllRolesQuery,
  useGetRoleByIdQuery,
  useCreateRoleMutation,
  useUpdateRoleMutation,
} = rolesApi;
