import { Box, Stack } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useGetMerchantBranchesQuery } from '../../app/api/merchantBranchesApi';
import DetailsButton from '../../components/DetailsButton';
import { InsuranceIcon } from '../../components/Icons';
import ListLayout from '../../components/ListLayout';
import BranchInfo from '../../components/branches/BranchInfo';
import { useDrawer } from '../../contexts/DrawerContext';
import { renderCompanyName } from '../../helpers/format';
import { Column } from '../../types';

const BranchesListPage: FC = () => {
  const { t } = useTranslation();
  const { openDrawer } = useDrawer();

  const cols: Column[] = [
    {
      id: 'id',
      label: 'ID',
      width: 50,
    },
    {
      id: 'name',
      label: 'Название филиала',
      renderCell: (data: any) => (
        <Link to={`/dashboard/sellers?parent_id=${data.id}`}>{data.merchant_branch.name}</Link>
      ),
      // renderCell: (data) => data.merchant_branch.name,
    },
    {
      id: 'merchant',
      label: 'Банк',
      renderCell: (data) => renderCompanyName(data.merchant),
    },
    {
      id: 'company_legal_address',
      label: 'Адрес филиала',
      renderCell: (data) => data.merchant_branch.company_legal_address,
    },
    {
      id: 'contacts',
      label: 'Контакты',
      renderCell: (data) => {
        return (
          <Stack>
            <div>
              {[
                data.merchant_branch.contact_1_last_name,
                data.merchant_branch.contact_1_first_name,
                data.merchant_branch.contact_1_second_name,
              ]
                .filter(Boolean)
                .join(' ')}
            </div>
            <div>{data.merchant_branch.contact_1_phone}</div>
          </Stack>
        );
      },
    },
    {
      id: 'status',
      label: 'Статус',
      renderCell: (data) => (data.status === 'active' ? 'Активный' : 'Неактивный'),
    },
    {
      id: 'actions',
      label: '',
      renderCell: (data) => (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <DetailsButton onClick={() => handleInfo(data)} />
        </Box>
      ),
    },
  ];

  const handleInfo = (data: any) => {
    openDrawer({
      content: <BranchInfo data={data} />,
    });
  };

  return (
    <ListLayout
      query={useGetMerchantBranchesQuery}
      icon={<InsuranceIcon />}
      title={t('branches', 'Филиалы')}
      cols={cols}
      createPermission="UserMerchantBranch@store"
    />
  );
};

export default BranchesListPage;
