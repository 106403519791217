import { DeleteOutline } from '@mui/icons-material';
import { Button, MenuItem, Select, Stack, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { ChangeEvent, FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useUploadAttachmentMutation } from '../app/api/attachmentsApi';
import { ITEM_TYPE_USER } from '../constants/entityTypes';
import { useDialog } from '../hooks/useDialog';
import { RecordObject } from '../types';
import { IFile } from '../types/merchants';
import DeleteConfirmDialog from './DeleteConfirmDialog';

interface Props {
  item?: IFile;
  setItem: (item: IFile) => void;
  onClose: () => void;
  onDelete?: () => void;
  types: RecordObject;
}

interface FormValues {
  item_id: string;
  item_type: string;
  document_type: string;
  description: string;
  file: File | undefined;
}

const initialValues: FormValues = {
  item_id: '',
  item_type: ITEM_TYPE_USER,
  document_type: '',
  description: '',
  file: undefined,
};

const validationSchema = Yup.object().shape({
  item_id: Yup.string().required(),
  item_type: Yup.string().required(),
  document_type: Yup.string().required(),
  description: Yup.string(),
  file: Yup.mixed().required(),
});

const FileForm: FC<Props> = ({ item = null, setItem, onClose, onDelete, types }) => {
  const { openDialog, closeDialog, renderDialog } = useDialog();
  const { id } = useParams();

  const [upload, { isLoading }] = useUploadAttachmentMutation();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append('item_id', values.item_id);
      formData.append('item_type', values.item_type);
      formData.append('document_type', values.document_type);
      formData.append('description', values.description);
      if (values.file) {
        formData.append('file', values.file);
      }

      upload(formData)
        .unwrap()
        .then((res) => {
          if (res.success) {
            setItem(res.data);
            onClose();
          }
        })
        .catch(console.error);
    },
  });

  useEffect(() => {
    formik.setFieldValue('item_id', id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleCancel = () => {
    onClose();
    formik.resetForm();
  };

  const handleDelete = () => {
    openDialog(
      <DeleteConfirmDialog
        title="Вы действительно хотите удалить данную скидку?"
        confirm={() => {
          onDelete && onDelete();
          onClose();
          closeDialog();
        }}
        close={closeDialog}
      />
    );
  };

  return (
    <form
      onSubmit={(values) => {
        console.log('errors', formik.errors);
        formik.handleSubmit(values);
      }}
    >
      <input type="hidden" {...formik.getFieldProps('item_id')} />
      <input type="hidden" {...formik.getFieldProps('item_type')} />
      <Stack gap={3} alignItems="flex-start">
        <Select fullWidth {...formik.getFieldProps('document_type')}>
          {Object.keys(types).map((key) => (
            <MenuItem value={key} key={key}>
              {types[key]}
              {/* {key} */}
            </MenuItem>
          ))}
        </Select>
        {false && (
          <Button size="large" variant="contained">
            Сгенерировать документ
          </Button>
        )}
        <Button size="large" component="label" variant="contained">
          Выберите файл
          <input
            hidden
            type="file"
            onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('file', e.currentTarget.files?.[0])}
          />
        </Button>
        <TextField fullWidth label="Описание файла" {...formik.getFieldProps('description')} />
        <Stack direction="row" gap={2} justifyContent="flex-end">
          {item && (
            <Button variant="contained" color="secondary" sx={{ mr: 'auto' }} onClick={handleDelete}>
              <DeleteOutline />
            </Button>
          )}
          <Button variant="contained" color="secondary" size="large" onClick={handleCancel}>
            Отмена
          </Button>
          <Button variant="contained" color="primary" type="submit" size="large" disabled={isLoading}>
            Готово
          </Button>
        </Stack>
      </Stack>

      {renderDialog()}
    </form>
  );
};

export default FileForm;
