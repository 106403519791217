import { Box, Button, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useCreateApplicationMutation } from '../../app/api/applicationsApi';
import { ROUTE } from '../../constants/routes';
import { objectToFormData } from '../../helpers/form';
import useLocale from '../../hooks/useLocale';
import { RecordObject } from '../../types';
import { Product } from '../../types/product';

interface Props {
  product: Product | undefined;
  values: RecordObject;
  close: () => void;
}

const ApplicationConfirmDialog: FC<Props> = ({ product, values, close }) => {
  const { locale } = useLocale();
  const [save, { isLoading }] = useCreateApplicationMutation();
  const navigate = useNavigate();

  const confirm = () => {
    const formdata = objectToFormData(values);

    save(formdata)
      .unwrap()
      .then((_res) => {
        toast.success('Заявка успешно создана');
        navigate(ROUTE.APPLICATIONS);
      })
      .catch((error) => {
        toast.error(error.data.message ?? 'Ошибка при создании заявки');
        console.error(error);
      });
  };

  if (!product) return null;

  return (
    <Stack>
      <DialogTitle>Правильно ли введена информация?</DialogTitle>
      <DialogContent>
        <Stack>
          <Box display="flex" justifyContent="space-between" gap={2} py={1}>
            <Typography variant="h4">Продукт</Typography>
            <Typography variant="h4">{product.name[locale]}</Typography>
          </Box>

          {product.policy_fields.map((section: any) => (
            <Stack key={section.code} mt={2} gap={1}>
              <Typography variant="h4">{section.title}</Typography>
              {section.fields.map((field: any) => (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  key={field.code}
                  sx={{
                    pt: 1,
                    borderTop: '1px solid #ccc',
                  }}
                >
                  <Typography>{field.name}</Typography>
                  <Typography>{values.policy_fields[field.code] || 'Не указано'}</Typography>
                </Stack>
              ))}
            </Stack>
          ))}
        </Stack>
        <Stack direction="row" justifyContent="flex-end" gap={2} mt={4}>
          <Button variant="outlined" onClick={close} size="large" disabled={isLoading}>
            Назад
          </Button>
          <Button variant="contained" onClick={confirm} size="large" disabled={isLoading}>
            Подтвердить
          </Button>
        </Stack>
      </DialogContent>
    </Stack>
  );
};

export default ApplicationConfirmDialog;
