import SearchIcon from '@mui/icons-material/Search';
import { AppBar, Box, InputBase, Stack, Toolbar, Typography, alpha, styled } from '@mui/material';
import { FC, ReactNode } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getSearchParams } from '../helpers/utils';
import FlexBox from './FlexBox';
import SearchWidget from './SearchWidget';

interface PageLayoutProps {
  title: string;
  icon?: ReactNode;
  hasSearch?: boolean;
  actions?: ReactNode;
  children?: ReactNode;
}

const PageLayout: FC<PageLayoutProps> = ({ title, icon, hasSearch = false, actions, children }) => {
  return (
    <Stack sx={{ height: '100vh', width: 1, bgcolor: '#F5F4F5' }}>
      <AppBar
        position="static"
        color="transparent"
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 5px 10px rgba(225, 220, 216, 0.5)',
          zIndex: 1,
        }}
      >
        <Toolbar sx={{ pl: { xs: 12, md: 12 } }}>
          <FlexBox width={1} alignItems="center">
            <FlexBox gap={2} alignItems="center">
              <Stack color="#FF6A2A">{icon}</Stack>
              <Typography variant="h4" fontWeight={500}>
                {title}
              </Typography>
            </FlexBox>

            {hasSearch && <SearchWidget />}
            {actions}
          </FlexBox>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          p: 3,
          pl: 12,
          flexGrow: 1,
          width: 1,
        }}
      >
        {children}
      </Box>
    </Stack>
  );
};

export default PageLayout;
