import { Close } from '@mui/icons-material';
import { AppBar, Box, Button, Chip, Divider, Grid, Stack, Toolbar, Typography } from '@mui/material';
import { format } from 'date-fns';
import { FC, Fragment } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetApplicationByIdQuery } from '../../app/api/applicationsApi';
import FlexBox from '../../components/FlexBox';
import FormSection from '../../components/FormSection';
import InfoRow from '../../components/InfoRow';
import Loader from '../../components/Loader';
import { ROUTE } from '../../constants/routes';
import { APPLICATION_STATUS } from '../../constants/statuses';
import { renderFullName } from '../../helpers/format';
import useLocale from '../../hooks/useLocale';

interface Props {}

const ApplicationDetailsPage: FC<Props> = () => {
  const { id } = useParams();
  const { data, isFetching } = useGetApplicationByIdQuery(id);
  const navigate = useNavigate();
  const { locale } = useLocale();

  if (isFetching) {
    return (
      <Stack alignItems="center" justifyContent="center" minHeight={1}>
        <Loader />
      </Stack>
    );
  }

  if (!data) return null;

  const policy_fields = data?.data?.policy_fields_label;

  return (
    <Stack sx={{ height: '100vh', width: 1, bgcolor: '#F5F4F5' }}>
      <AppBar
        position="static"
        color="transparent"
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 5px 10px rgba(225, 220, 216, 0.5)',
          zIndex: 1,
        }}
      >
        <Toolbar sx={{ pl: { xs: 8.5 }, pr: { xs: 0 } }}>
          <FlexBox width={1} alignItems="center" gap={3.5}>
            <Button
              variant="contained"
              color="secondary"
              sx={{
                width: 64,
                height: 64,
                borderRadius: 0,
                color: '#000000',
                borderRight: 1,
                borderColor: '#E2DDD9',
                backgroundColor: 'white',
              }}
              onClick={() => navigate(ROUTE.APPLICATIONS)}
            >
              <Close />
            </Button>

            <Typography variant="h3" fontWeight={500} mr="auto">
              Заявка №{id}
            </Typography>
          </FlexBox>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          p: 3,
          pl: 16,
          flexGrow: 1,
          height: 'calc(100% - 64px)',
          overflow: 'auto',
        }}
      >
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <FormSection title="Информация о заявке">
              <Stack>
                <InfoRow label="ID заявки" value={data.data.id} />
                <Divider />
                <InfoRow
                  label="Время получения заявки"
                  value={format(new Date(data.data.created_at), 'dd.LL.yyyy / HH:mm')}
                />
                <Divider />
                <InfoRow label="Продукт" value={data.data.product.name[locale]} />
                <Divider />
                <InfoRow label="Клиент" value={[renderFullName(data.data.client)]} />
                <Divider />
                <InfoRow label="Статус" value={<Chip {...APPLICATION_STATUS[data.data.status]} />} />
                <Divider />
                <InfoRow
                  label="Полис"
                  value={
                    data.data?.policy?.file?.url && (
                      <Button
                        variant="contained"
                        size="large"
                        fullWidth
                        download={data.data.policy.file?.file_name}
                        href={data.data.policy.file.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Скачать полис
                      </Button>
                    )
                  }
                />
              </Stack>
            </FormSection>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormSection title="Данные заявки">
              <Stack>
                {policy_fields.map((field: any, index: number) => (
                  <Fragment key={field.code}>
                    {index !== 0 && <Divider />}
                    <InfoRow label={field.name} value={field.value} />
                  </Fragment>
                ))}
              </Stack>
            </FormSection>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
};

export default ApplicationDetailsPage;
