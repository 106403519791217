import { AccountBalanceOutlined } from '@mui/icons-material';
import { Avatar, Box, Button, Chip, Stack } from '@mui/material';
import { getIn } from 'formik';
import { FC } from 'react';
import { useExportPoliciesMutation, useGetPoliciesQuery } from '../../app/api/policiesApi';
import ListLayout from '../../components/ListLayout';
import PolicyInfo from '../../components/policies/PolicyInfo';
import { POLICY_STATUS } from '../../constants/statuses';
import { useDrawer } from '../../contexts/DrawerContext';
import { dateFormat, renderCompanyName, renderFullName } from '../../helpers/format';
import { formatPrice } from '../../helpers/numbers';
import useLocale from '../../hooks/useLocale';
import { Column } from '../../types';

interface Props {}

const PoliciesListPage: FC<Props> = () => {
  const { locale } = useLocale();
  const { openDrawer, closeDrawer } = useDrawer();

  const cols: Column[] = [
    {
      id: 'id',
      label: 'ID',
    },
    {
      id: 'insurance.company_name',
      label: 'Название страховой компании',
      renderCell: (data) => renderCompanyName(data.insurance),
    },
    {
      id: 'bank.company_name',
      label: 'Банк',
      renderCell: (data) => (
        <Box display="flex" alignItems="center">
          <Avatar
            variant="rounded"
            src={data.bank.logo || '/assets/images/product-image-placeholder.png'}
            sx={{
              width: 60,
              height: 40,
              borderRadius: 1,
              mr: 2,
            }}
          />
          <Box whiteSpace="nowrap">{renderCompanyName(data.bank)}</Box>
        </Box>
      ),
    },
    {
      id: 'bank_branch.name',
      label: 'Филиал',
      renderCell: (data) => data.bank_branch.name,
    },
    {
      id: 'client',
      label: 'Заемщик',
      renderCell: (data) => renderFullName(data.client),
    },
    {
      id: 'insurance_object',
      label: 'Объект страхования',
    },
    {
      id: 'insurance',
      label: 'Страхователь',
      // renderCell: (data) => data.insurance.company_name,
      renderCell: (data) => <Box whiteSpace="nowrap">{data.bank.company_type + ' ' + data.bank.company_name}</Box>,
    },
    {
      id: 'beneficiary',
      label: 'Выгодопреобретатель',
      // renderCell: (data) => data.beneficiary.company_name,
      renderCell: (data) => <>&mdash;</>,
    },
    {
      id: 'is_legal_form',
      label: 'Юр.лицо / Физ.лицо',
      renderCell: (data) => (data.client.is_legal_form ? 'Юр.лицо' : 'Физ.лицо'),
    },
    {
      id: 'product',
      label: 'Продукт',
      renderCell: (data) => data.product.name[locale],
    },
    {
      id: 'contract_id',
      label: 'ID полиса',
    },
    {
      id: 'application.contract_id',
      label: 'ID договора',
      renderCell: (data) => getIn(data, 'application.policy_fields.contract_id'),
    },
    {
      id: 'policy_number',
      label: 'Номер полиса',
      renderCell: (data) => data.series + ' ' + data.number,
    },
    {
      id: 'period_begin',
      label: 'Дата начала',
      renderCell: (data) => dateFormat(data.period_begin),
    },
    {
      id: 'period_end',
      label: 'Дата окончания',
      renderCell: (data) => dateFormat(data.period_end),
    },
    {
      id: 'period',
      label: 'Кол-во дней',
      renderCell: (data) =>
        (new Date(data.period_end).getTime() - new Date(data.period_begin).getTime()) / (1000 * 60 * 60 * 24),
    },
    {
      id: 'insurance_premium',
      label: 'Страховая премия',
      renderCell: (data) => formatPrice(data.insurance_premium),
      align: 'right',
    },
    {
      id: 'currency',
      label: 'Валюта',
      // renderCell: (data) => currencies[data.currency],
    },
    {
      id: 'insurance_amount',
      label: 'Страховая сумма',
      renderCell: (data) => formatPrice(data.insurance_amount),
      align: 'right',
    },
    {
      id: 'payment_payer',
      label: 'Плательщик',
      renderCell: (data) => renderFullName(data.payment_payer),
    },
    {
      id: 'policy.held',
      label: 'Удержано за полис (от)',
      renderCell: (data) => renderFullName(data.client),
    },
    {
      id: 'policy.url',
      label: 'Полис',
      renderCell: (data) => {
        return (
          data.policy?.url && (
            <a href={data.policy.url} target="_blank" download="policy.xls" rel="noreferrer">
              Скачать
            </a>
          )
        );
      },
    },
    // { id: 'franchise', label: 'Франшиза' },
    {
      id: 'status',
      label: 'Статус полиса',
      renderCell: (data) => (
        <Stack>
          <Chip {...POLICY_STATUS[data.status]} />
        </Stack>
      ),
    },
    {
      id: 'status_payment_insurance',
      label: 'Статус оплаты',
      renderCell: (data) => (
        <Stack alignItems="center" gap={0.5}>
          <Chip
            label={data.status_payment_insurance === 'payment_paid' ? 'Оплачено' : 'Не оплачено'}
            color={data.status_payment_insurance === 'payment_paid' ? 'success' : 'error'}
          />
          {data.payment_insurance_bank_debt > 0 && <Box>Долг: {formatPrice(data.payment_insurance_bank_debt)}</Box>}
        </Stack>
      ),
    },
    {
      id: 'paid_at',
      label: 'Дата оплаты',
      renderCell: (data) => dateFormat(data.payment_insurance_confirmed_at),
    },
    {
      id: 'confirm_payment',
      label: '',
      renderCell: (data) => (
        <Button
          size="large"
          variant="contained"
          sx={{ whiteSpace: 'nowrap' }}
          color={data.status !== 'cancelled' ? 'primary' : 'error'}
          onClick={() => handleShowInfo(data)}
        >
          {data.status !== 'cancelled' ? 'Аннулировать' : 'Аннулирован'}
        </Button>
      ),
    },
  ];

  const handleShowInfo = (data: any) => {
    openDrawer({
      content: <PolicyInfo data={data} onClose={closeDrawer} />,
    });
  };

  return (
    <ListLayout
      icon={<AccountBalanceOutlined />}
      title="Полисы"
      query={useGetPoliciesQuery}
      cols={cols}
      createPermission="ApplicationPolicy@store"
      filter
      exportMutation={useExportPoliciesMutation}
    />
  );
};

export default PoliciesListPage;
