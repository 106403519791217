import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { objectToFormData } from '../../helpers/form';
import { rtkBaseQuery } from '../../helpers/rtkquery';

export const insurancesApi = createApi({
  reducerPath: 'insurancesApi',
  baseQuery: rtkBaseQuery('/insurances'),
  tagTypes: ['Insurances'],
  endpoints: (builder) => ({
    getInsurances: builder.query({
      query: (params) => ({
        url: '',
        params,
      }),
      providesTags: ['Insurances'],
    }),
    getInsurance: builder.query({
      query: (id) => `${id}`,
      providesTags: (_result, _error, { id }) => [{ type: 'Insurances', id }],
    }),
    createInsurance: builder.mutation({
      query: (body) => ({
        url: '',
        method: 'POST',
        body: objectToFormData(body),
      }),
      invalidatesTags: ['Insurances'],
    }),
    activateInsurance: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `${id}/activate`,
        // method: "PATCH",
        method: 'POST',
        body: { ...body, _method: 'PATCH' },
      }),
      invalidatesTags: (_result, _error, { id }) => ['Insurances', { type: 'Insurances', id }],
    }),
    updateInsurance: builder.mutation({
      query: (body) => ({
        url: `${body.id}`,
        // method: "PUT",
        method: 'POST',
        body: objectToFormData({ ...body, _method: 'PUT' }),
      }),
      invalidatesTags: (_result, _error, { id }) => ['Insurances', { type: 'Insurances', id }],
    }),
    patchInsurance: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (_result, _error, { id }) => ['Insurances', { type: 'Insurances', id }],
    }),
    deleteInsurance: builder.mutation({
      query: (id) => ({
        url: `${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Insurances'],
    }),
    setInsuranceApiStatus: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `${id}/status-api`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (_result, _error, { id }) => ['Insurances', { type: 'Insurances', id }],
    }),
    getInsurancesDropdown: builder.query({
      query: () => '/dropdown',
    }),
  }),
});

export const {
  useGetInsurancesQuery,
  useGetInsuranceQuery,
  useCreateInsuranceMutation,
  useActivateInsuranceMutation,
  useUpdateInsuranceMutation,
  usePatchInsuranceMutation,
  useDeleteInsuranceMutation,
  useSetInsuranceApiStatusMutation,
  useGetInsurancesDropdownQuery,
} = insurancesApi;
