import { Close, VisibilityOutlined } from '@mui/icons-material';
import { Box, Button, Chip, Divider, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { FC, Fragment } from 'react';
import { APPLICATION_STATUS } from '../../constants/statuses';
import { renderFullName } from '../../helpers/format';
import { useDialog } from '../../hooks/useDialog';
import useLocale from '../../hooks/useLocale';
import InfoRow from '../InfoRow';
import PaymentConfirm from './PaymentConfirm';

interface ApplicationInfoProps {
  data: any;
  onClose: () => void;
  onDetails: () => void;
}

const ApplicationInfo: FC<ApplicationInfoProps> = ({ data, onClose, onDetails }) => {
  const { locale } = useLocale();
  const { openDialog, closeDialog, renderDialog } = useDialog();

  const handleConfirmPayment = () => {
    openDialog(
      <PaymentConfirm
        id={data.id}
        onClose={() => {
          closeDialog();
          onClose();
        }}
      />
    );
  };

  return (
    <Stack width={466} px={2.5} py={3.5} minHeight={1} gap={2} overflow="auto">
      <Stack direction="row" alignItems="flex-start" justifyContent="space-between" gap={2.5}>
        <Stack gap={1} mt={-1} alignItems="flex-start">
          <Typography variant="h3">Заявка: {data.id}</Typography>
          <Chip {...(APPLICATION_STATUS[data.status] || { label: data.status })} />
        </Stack>

        <Box display="flex" gap={2.5}>
          <Button variant="outlined" size="large" sx={{ width: 50, height: 50 }} onClick={onDetails}>
            <VisibilityOutlined />
          </Button>

          <Button variant="contained" size="large" sx={{ width: 50, height: 50 }} onClick={onClose}>
            <Close />
          </Button>
        </Box>
      </Stack>

      <Stack>
        <InfoRow label="ID заявки" value={data.id} />
        <Divider />
        <InfoRow label="Время получения заявки" value={format(new Date(data.created_at), 'dd.LL.yyyy / HH:mm')} />
        <Divider />
        <InfoRow label="Продукт" value={data.product.name[locale]} />
        <Divider />
        <InfoRow label="Клиент" value={renderFullName(data.client)} />
      </Stack>

      <Stack>
        {data.policy_fields_label.map((field: any, index: number) => (
          <Fragment key={field.code}>
            {index !== 0 && <Divider />}
            <InfoRow label={field.name} value={field.value} />
          </Fragment>
        ))}
      </Stack>

      <Stack mt="auto">
        {['ready_to_payment', 'policy_ready'].includes(data.status) && (
          <Button variant="contained" size="large" fullWidth onClick={handleConfirmPayment}>
            Подтвердить оплату
          </Button>
        )}
        {data.status === 'policy_paid' && (
          <Button
            variant="contained"
            size="large"
            fullWidth
            download={data?.policy?.file?.file_name}
            href={data?.policy?.file.url}
            target="_blank"
            rel="noreferrer"
          >
            Скачать полис
          </Button>
        )}
      </Stack>

      {renderDialog()}
    </Stack>
  );
};

export default ApplicationInfo;
