import { QueryStats } from '@mui/icons-material';
import { Box, Divider, Stack, styled } from '@mui/material';
import { FC, Fragment } from 'react';

import useUser from '../hooks/useUser';
import { BankIcon, CustomersIcon, InsuranceIcon, PolicyIcon, ProductsIcon, ReportsIcon, SettingsIcon } from './Icons';
import NavList from './NavList';
import NavListItem from './NavListItem';
import UserPanel from './UserPanel';

interface SidebarProps {
  onClose: () => void;
}

const StyledDivider = styled(Divider)({
  border: 'none',
  marginTop: 16,
  marginBottom: 16,
});

interface ItemSection {
  id: string;
  items: SectionItem[];
  module?: string;
}

interface SectionItem extends SectionLink {
  icon: JSX.Element;
  children?: SectionLink[];
  module?: string;
}

interface SectionLink {
  href: string;
  label: string;
}

const itemsSections: ItemSection[] = [
  {
    id: 'dashboard',
    items: [
      {
        icon: <QueryStats />,
        href: '/dashboard',
        label: 'Отчеты и показатели',
      },
    ],
  },
  {
    id: 'main',
    items: [
      {
        icon: <ReportsIcon />,
        href: '/dashboard/applications',
        label: 'Заявки',
        module: 'Application',
      },
      {
        icon: <PolicyIcon />,
        href: '/dashboard/policies',
        label: 'Полисы',
        module: 'ApplicationPolicy',
      },
      {
        icon: <CustomersIcon />,
        href: '/dashboard/clients',
        label: 'Клиенты',
        module: 'UserClient',
      },
    ],
  },
  {
    id: 'bank_insurance',
    items: [
      // {
      //   icon: <ScoringIcon />,
      //   href: '/dashboard/scoring',
      //   label: 'Скоринг',
      //   module: 'UserMerchantScoring',
      // },
      {
        icon: <InsuranceIcon />,
        href: '/dashboard/insurances',
        label: 'Страховые компании',
        module: 'UserMerchantInsurance',
        children: [
          {
            // icon: <ScoringIcon />,
            href: '/dashboard/scoring',
            label: 'Скоринг',
            // module: 'UserMerchantScoring',
          },
          // {
          //   // icon: <ProductsIcon />,
          //   href: '/dashboard/insurance-products',
          //   label: 'Страховые продукты',
          //   // module: 'UserMerchantProduct',
          // },
        ],
      },
      {
        icon: <BankIcon />,
        href: '/dashboard/banks',
        label: 'Банки',
        module: 'UserMerchantBank',
        children: [
          {
            // icon: <InsuranceIcon />,
            href: '/dashboard/branches',
            label: 'Филиалы',
            // module: 'UserMerchantBranch',
          },
          {
            // icon: <InsuranceIcon />,
            href: '/dashboard/sellers',
            label: 'Продавцы',
            // module: 'UserBranchSeller',
          },
        ],
      },
      {
        icon: <ProductsIcon />,
        href: '/dashboard/products',
        label: 'Продукты',
        module: 'Product',
      },
    ],
  },
  // {
  //   icon: <ListRounded />,
  //   href: "/dashboard/product-categories",
  //   label: "Категории продукта",
  //   module: "ProductCategory@index",
  // },
  // {
  //   id: 'reports',
  //   items: [
  //     {
  //       icon: <ReportsIcon />,
  //       href: '/dashboard/reports',
  //       label: 'Отчеты',
  //       module: 'Report',
  //     },
  //   ],
  // },
  {
    id: 'reports',
    items: [
      {
        icon: <ReportsIcon />,
        label: 'Отчеты',
        href: '/dashboard/reports',
      },
    ],
  },
  {
    id: 'settings',
    module: 'Settings',
    items: [
      {
        icon: <SettingsIcon />,
        href: '#',
        label: 'Настройки',
        children: [
          {
            label: 'Пользователи',
            href: '/dashboard/settings/users',
          },
          {
            label: 'Роли',
            href: '/dashboard/settings/roles',
          },
          {
            label: 'Роли пользователя',
            href: '/dashboard/settings/user_roles',
          },
          {
            label: 'Модули',
            href: '/dashboard/settings/modules',
          },
        ],
      },
    ],
  },
];

const Sidebar: FC<SidebarProps> = ({ onClose }) => {
  const { hasAccess } = useUser();

  return (
    <Box width={320} p={0} height={1} bgcolor="black" color="white" borderRadius="0 20px 20px 0">
      <Stack height={1} overflow="auto" py={3}>
        <UserPanel />
        {itemsSections.map(
          (section, index) =>
            (!section.module || (section.module && hasAccess('module', section.module))) && (
              <Fragment key={section.id}>
                {index !== 0 && <StyledDivider />}
                <NavList>
                  {section.items.map(
                    (item, key) =>
                      (!item.module || (item.module && hasAccess('module', item.module))) && (
                        <NavListItem {...item} closeNav={onClose} key={index + '_' + key} />
                      )
                  )}
                </NavList>
              </Fragment>
            )
        )}

        <Stack mt="auto">
          <StyledDivider />
          {/* user */}
        </Stack>
      </Stack>
    </Box>
  );
};

export default Sidebar;
