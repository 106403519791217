import { Box, Card, Stack, Typography } from "@mui/material";
import { FC } from "react";

interface ScoringCardProps {
  label: string;
  value: string;
}

const ScoringCard: FC<ScoringCardProps> = ({ label, value }) => {
  return (
    <Stack
      component={Card}
      p={2.5}
      bgcolor="#F5F4F7"
      gap={1}
      width={1}
      borderRadius={2.5}
    >
      <Typography fontSize={13}>{label}</Typography>
      <Typography fontSize={20} color="#00C271">
        {value}
      </Typography>
      <Box width={1} height={4} bgcolor="#E2E2E2">
        <Box width="80%" bgcolor="#00C271" height={1} />
      </Box>
    </Stack>
  );
};

export default ScoringCard;
