import { Avatar, Box, Stack, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import IconButton from "./IconButton";

interface UserPanelProps {
  variant?: "full" | "compact";
}

interface User {
  email: string;
  first_name: string;
  last_name: string;
  role_code: any;
  show_name: string;
}

const UserPanel: FC<UserPanelProps> = ({ variant }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const localUser = localStorage.getItem("user");
    if (localUser) setUser(JSON.parse(localUser));
  }, []);

  if (!user) return null;

  if (variant === "compact") {
    return <Avatar sx={{ width: 40, height: 40 }} />;
  }

  const handleLogout = () => {
    navigate("/logout");
  };

  return (
    <Box display="flex" px={3} gap={1.25} mb={2.5} alignItems="flex-start">
      <Avatar sx={{ width: 40, height: 40 }} />
      <Stack maxWidth={170}>
        <Typography variant="h5" fontWeight={500}>
          {user.show_name}
        </Typography>
        <Typography variant="body2" color="#666666">
          {user.email}
        </Typography>
      </Stack>
      <IconButton size="small" sx={{ ml: "auto" }} onClick={handleLogout}>
        <svg
          width="29"
          height="30"
          viewBox="0 0 29 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.66272 24.5H15.3144C16.7912 24.5 18 23.3773 18 21.9792V20.0576C18 19.5502 17.5667 19.14 17.0307 19.14C16.4947 19.14 16.0614 19.5502 16.0614 20.0576V21.9792C16.0614 22.3679 15.725 22.6648 15.3144 22.6648H7.66272C7.2522 22.6648 6.93858 22.3679 6.93858 21.9792V8.02076C6.93858 7.63212 7.25217 7.33522 7.66272 7.33522H15.3144C15.725 7.33522 16.0614 7.6321 16.0614 8.02076V9.90459C16.0614 10.412 16.4947 10.8222 17.0307 10.8222C17.5667 10.8222 18 10.412 18 9.90459V8.02076C18 6.62274 16.7912 5.5 15.3144 5.5H7.66272C6.18597 5.5 5 6.62274 5 8.02076V21.9792C5 23.3773 6.18597 24.5 7.66272 24.5Z"
            fill="#D9D9D9"
          />
          <path
            d="M20.9054 19.2844C21.079 19.4281 21.296 19.5 21.5077 19.5C21.7681 19.5 22.0231 19.3973 22.2021 19.1972L25.6962 15.3888C25.8806 15.2297 26 14.9987 26 14.7421C26 14.465 25.8644 14.2186 25.6473 14.0595L22.1533 10.7541C21.7952 10.4153 21.2093 10.4153 20.8512 10.7541C20.4931 11.0928 20.4931 11.6471 20.8512 11.9859L22.8423 13.8696H10.9223C10.4124 13.8696 10 14.2596 10 14.7421C10 15.2246 10.4123 15.6147 10.9223 15.6147H23.0482L20.8129 18.0526C20.4765 18.4171 20.5202 18.9713 20.9054 19.2844Z"
            fill="#D9D9D9"
          />
        </svg>
      </IconButton>
    </Box>
  );
};

export default UserPanel;
