import InsurancesActivationPage from './ActivationPage';
import InsurancesCreatePage from './CreatePage';
import InsurancesListPage from './ListPage';
import CompaniesUpdatePage from './UpdatePage';

const Insurances = {
  List: InsurancesListPage,
  Create: InsurancesCreatePage,
  Activate: InsurancesActivationPage,
  Update: CompaniesUpdatePage,
};

export default Insurances;
