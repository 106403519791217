import { FileDownloadOutlined, Replay } from '@mui/icons-material';
import { Box, Button, CircularProgress, MenuItem, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { getIn, useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useGetBanksDropdownQuery } from '../app/api/banksApi';
import { useGetInsurancesDropdownQuery } from '../app/api/insurancesApi';
import { useGetProductsQuery } from '../app/api/productsApi';
import { parseLocalizedDate } from '../helpers/format';
import { getSearchParams } from '../helpers/utils';
import useUser from '../hooks/useUser';
import { RecordObject } from '../types';

interface Props {
  onExport?: () => void;
  exportLoading?: boolean;
}

const ListFilter: FC<Props> = ({ onExport, exportLoading }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [banks, setBanks] = useState([]);
  const [insurances, setInsurances] = useState([]);
  const [products, setProducts] = useState([]);

  const { isAdmin, isBank, isInsurance } = useUser();

  const { data: banksQuery } = useGetBanksDropdownQuery({}, { skip: isBank });
  const { data: insurancesQuery } = useGetInsurancesDropdownQuery({}, { skip: isInsurance });
  const { data: productsQuery } = useGetProductsQuery({});

  useEffect(() => {
    if (banksQuery) setBanks(banksQuery.data.items);
    if (insurancesQuery) setInsurances(insurancesQuery.data.items);
    if (productsQuery) setProducts(productsQuery.data.items);
  }, [banksQuery, insurancesQuery, productsQuery]);

  const emptyValues = {
    bank_id: '',
    insurance_id: '',
    date_from: null,
    date_to: null,
    product_id: '',
    q: '',
  };

  const initialValues = {
    bank_id: searchParams.get('bank_id') || '',
    insurance_id: searchParams.get('insurance_id') || '',
    date_from: searchParams.get('date_from') || null,
    date_to: searchParams.get('date_to') || null,
    product_id: searchParams.get('product_id') || '',
  };

  const cleanObject = (obj: RecordObject) => {
    return Object.entries(obj)
      .filter(([key, value]) => value != null && value !== '')
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      setSearchParams({
        ...getSearchParams(),
        ...cleanObject(values),
      });
    },
  });

  const handleReset = () => {
    formik.resetForm({ values: emptyValues });
    setSearchParams(cleanObject({ ...getSearchParams(), ...emptyValues }));
  };

  return (
    <Box component="form" onSubmit={formik.handleSubmit} display="flex" gap={3.5} mb={2}>
      {(isAdmin || isInsurance) && (
        <TextField
          label={'Банк'}
          select
          sx={{ minWidth: 220 }}
          InputLabelProps={{ shrink: true }}
          SelectProps={{
            displayEmpty: true,
          }}
          {...formik.getFieldProps('bank_id')}
        >
          <MenuItem value="">Все</MenuItem>
          {banks.map((company: any) => (
            <MenuItem key={company.id} value={company.id}>
              {company.merchant.company_type + ' ' + company.merchant.company_name}
            </MenuItem>
          ))}
        </TextField>
      )}

      {(isAdmin || isBank) && (
        <TextField
          label={'Страховая компания'}
          select
          sx={{ minWidth: 220 }}
          InputLabelProps={{ shrink: true }}
          SelectProps={{
            displayEmpty: true,
          }}
          {...formik.getFieldProps('insurance_id')}
        >
          <MenuItem value="">Все</MenuItem>
          {insurances.map((company: any) => (
            <MenuItem key={company.id} value={company.id}>
              {company.merchant.company_type + ' ' + company.merchant.company_name}
            </MenuItem>
          ))}
        </TextField>
      )}

      <Box display="flex" gap={2}>
        <DatePicker
          // {...formik.getFieldProps('date_from')}
          value={parseLocalizedDate(getIn(formik.values, 'date_from')) || null}
          onChange={(date) => formik.setFieldValue('date_from', date?.toLocaleDateString('ru'))}
          slotProps={{
            textField: {
              label: 'Дата от',
              placeholder: '__.__.____',
              InputLabelProps: {
                shrink: true,
              },
              sx: { bgcolor: 'white', width: 170 },
            },
          }}
          sx={{ width: 170 }}
        />
        <DatePicker
          value={parseLocalizedDate(getIn(formik.values, 'date_to')) || null}
          onChange={(date) => formik.setFieldValue('date_to', date?.toLocaleDateString('ru'))}
          slotProps={{
            textField: {
              label: 'Дата до',
              placeholder: '__.__.____',
              InputLabelProps: {
                shrink: true,
              },
              sx: { bgcolor: 'white', width: 170 },
            },
          }}
        />
      </Box>

      <TextField
        label="Продукт"
        select
        sx={{ minWidth: 220 }}
        InputLabelProps={{ shrink: true }}
        SelectProps={{
          displayEmpty: true,
        }}
        {...formik.getFieldProps('product_id')}
      >
        <MenuItem value="">Все</MenuItem>
        {products.map((product: any) => (
          <MenuItem key={product.id} value={product.id}>
            {product.name.ru}
          </MenuItem>
        ))}
      </TextField>

      <Button
        variant="outlined"
        size="large"
        type="reset"
        color="inherit"
        onClick={handleReset}
        title="Сбросить фильтр"
      >
        <Replay />
      </Button>

      <Box ml="auto" display="flex" gap={2.5}>
        <Button variant="contained" size="large" type="submit" sx={{ fontSize: 14 }}>
          Сформировать
        </Button>

        {/* TODO: Add action to export excel document */}
        <Button variant="contained" size="large" title="Скачать Excel" onClick={onExport} disabled={exportLoading}>
          {exportLoading ? <CircularProgress /> : <FileDownloadOutlined />}
        </Button>
      </Box>
    </Box>
  );
};

export default ListFilter;
