import { FC } from 'react';
import { useGetSellersQuery } from '../../app/api/sellersApi';
import DetailsButton from '../../components/DetailsButton';
import { InsuranceIcon } from '../../components/Icons';
import ListLayout from '../../components/ListLayout';
import SellerInfo from '../../components/sellers/SellerInfo';
import { useDrawer } from '../../contexts/DrawerContext';
import { renderCompanyName } from '../../helpers/format';
import { Column } from '../../types';

const SellersListPage: FC = () => {
  const { openDrawer } = useDrawer();

  const cols: Column[] = [
    {
      id: 'id',
      label: 'ID',
    },
    {
      id: 'name',
      label: 'Имя',
      renderCell: (data) =>
        [data.branch_seller.last_name, data.branch_seller.first_name, data.branch_seller.second_name]
          .filter(Boolean)
          .join(' '),
    },
    {
      id: 'bank',
      label: 'Банк',
      renderCell: (data) => renderCompanyName(data.merchant),
    },
    {
      id: 'branch',
      label: 'Филиал',
      renderCell: (data) => data.merchant_branch.name,
    },
    {
      id: 'branch_seller.phone',
      label: 'Номер телефона',
      renderCell: (data) => data.branch_seller.phone,
    },
    {
      id: 'branch_seller.email',
      label: 'Email',
      renderCell: (data) => data.branch_seller.email,
    },
    {
      id: 'status',
      label: 'Статус',
      renderCell: (data) => (data.status === 'active' ? 'Активный' : 'Неактивный'),
    },
    {
      id: 'applications_count',
      label: 'Кол-во полисов',
      renderCell: (data) => data.applications.count,
    },
    {
      id: 'actions',
      label: '',
      renderCell: (data) => <DetailsButton onClick={() => handleInfo(data)} />,
    },
  ];

  const handleInfo = (data: any) => {
    openDrawer({
      content: <SellerInfo data={data} />,
    });
  };

  return (
    <ListLayout
      icon={<InsuranceIcon />}
      title="Продавцы"
      cols={cols}
      query={useGetSellersQuery}
      createPermission="UserBranchSeller@store"
    />
  );
};

export default SellersListPage;
