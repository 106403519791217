import { EditOutlined } from "@mui/icons-material";
import { Avatar, Box, Button, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ROUTE, getRoute } from "../../constants/routes";
import { useDrawer } from "../../contexts/DrawerContext";
import { renderFullName } from "../../helpers/format";
import FlexBox from "../FlexBox";

interface Props {
  data: any;
}

const SellerInfo: FC<Props> = ({ data }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { closeDrawer } = useDrawer();

  const handleEdit = () => {
    navigate(getRoute(ROUTE.SELLERS, data.id, "edit"));
    closeDrawer();
  };
  return (
    <Stack width={466} px={2.5} py={3.5} gap={3}>
      <FlexBox alignItems="flex-start" gap={2.5}>
        <Avatar
          src={data.branch_seller?.logo?.url}
          variant="square"
          sx={{ width: 80, height: 60, "& img": { objectFit: "contain" } }}
        />
        <Stack gap={0.5} alignItems="flex-start">
          <Typography variant="h3">
            {renderFullName(data.branch_seller)}
          </Typography>
          <Typography variant="body2" color="#999999">
            ID: {data.id}
          </Typography>
        </Stack>

        <Box display="flex" gap={2.5} ml="auto">
          <Button
            variant="contained"
            size="large"
            sx={{ width: 45, height: 45 }}
            onClick={handleEdit}
          >
            <EditOutlined />
          </Button>
        </Box>
      </FlexBox>

      <Stack gap={1}>
        <Typography
          variant="h6"
          color="#424242"
          textTransform="uppercase"
          mb={1}
          pl={2.5}
          letterSpacing={"0.05em"}
        >
          {t("phone", "Телефон")}
        </Typography>

        <Typography pl={2.5}>{data.branch_seller.phone}</Typography>
      </Stack>

      <Stack gap={1}>
        <Typography
          variant="h6"
          color="#424242"
          textTransform="uppercase"
          mb={1}
          pl={2.5}
          letterSpacing={"0.05em"}
        >
          {t("email", "Email")}
        </Typography>
        <Typography pl={2.5}>
          {data.branch_seller.email || "Не указано"}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default SellerInfo;
