import { FC } from "react";
import { useGetProductsQuery } from "../../app/api/productsApi";
import DetailsButton from "../../components/DetailsButton";
import { ProductsIcon } from "../../components/Icons";
import ListLayout from "../../components/ListLayout";
import ProductInfo from "../../components/products/ProductInfo";
import { useDrawer } from "../../contexts/DrawerContext";
import useLocale from "../../hooks/useLocale";
import { Column } from "../../types";

interface Props {}

const ProductsListPage: FC<Props> = () => {
  const { openDrawer } = useDrawer();
  const { t, locale } = useLocale();

  const cols: Column[] = [
    {
      id: "id",
      label: "ID",
      width: 60,
    },
    {
      id: "name",
      label: "Наименование продукта",
      renderCell: (data: any) => data.name[locale],
    },
    {
      id: "is_legal_form",
      label: "Правовая форма",
      renderCell: (data: any) =>
        data.is_legal_form ? "Юр. лицо" : "Физ. лицо",
    },
    {
      id: "actions",
      label: "",
      width: 60,
      renderCell: (data: any) => (
        <DetailsButton onClick={() => handleInfo(data)} />
      ),
    },
  ];

  const handleInfo = (data: any) => {
    openDrawer({
      content: <ProductInfo data={data} insurance={false} />,
    });
  };

  return (
    <ListLayout
      icon={<ProductsIcon />}
      title={t("products", "Продукты")}
      query={useGetProductsQuery}
      cols={cols}
      createPermission="Product@store"
    />
  );
};

export default ProductsListPage;
