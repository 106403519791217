import { createTheme } from "@mui/material/styles";
import components from "./components";
import palette from "./palette";
import typography from "./typography";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 992,
      lg: 1128,
      xl: 1400,
    },
  },
  shape: {
    borderRadius: 8,
  },
  palette,
  components,
  spacing: 8,
  typography,
});

export default theme;
