import { Box, Grid, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { BarsChartProps, ChartProps, CommonItems } from '../../types/statistics';
import FlexBox from '../FlexBox';
import Loader from '../Loader';
import YearlyBars from './bar-chart/YearlyBars';
import { BarChartColors } from './vars';

const StatisticsBarsChart: FC<ChartProps> = ({ query }) => {
  const [data, setData] = useState<BarsChartProps | null>(null);
  const [year, setYear] = useState<string>(new Date().getFullYear().toString());
  const { data: queryData, isLoading } = query({ year }, { skip: !year });

  useEffect(() => {
    if (queryData) setData(queryData.data);
  }, [queryData]);

  if (isLoading) return <Loader />;

  if (!data) return null;

  return (
    <Stack
      gap={2.5}
      sx={{
        borderRadius: 0.75,
        bgcolor: '#FFF',
        border: '1px solid #E5E9EB',
        width: 1200,
        padding: '15px 30px 30px',
      }}
    >
      <FlexBox justifyContent="space-between" alignItems="center">
        <Typography fontSize={20} fontWeight={600}>
          {data.title}
        </Typography>

        <TextField
          select
          value={year}
          size="small"
          onChange={(e) => setYear(e.target.value)}
          sx={{ mr: -1.25, bgcolor: '#EDF1F6' }}
        >
          {Object.keys(data.params.year.items).map((year: string) => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
        </TextField>
      </FlexBox>
      <Grid container spacing={4}>
        <Grid item xs={12} md={3}>
          <ApplicationsCommon data={data.items.common} />
        </Grid>

        <Grid item xs={12} md={9}>
          <YearlyBars data={data.items.grouped} />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default StatisticsBarsChart;

const ApplicationsCommon: FC<{ data: CommonItems }> = ({ data }) => {
  return (
    <>
      <Box display="flex" sx={{ height: 98, p: 0.375, borderRadius: 0.625, bgcolor: 'black', gap: 0.375, mb: 2.5 }}>
        {Object.values(data).map((item) => {
          if (item.code === 'all') return null;

          return (
            <Stack
              key={item.code}
              bgcolor={BarChartColors[item.code]}
              justifyContent="center"
              alignItems="center"
              width={`${item.percentage}%`}
              borderRadius={0.375}
              color="white"
              fontSize={14}
              lineHeight={24}
              fontWeight={600}
              sx={{ transition: '0.3s' }}
            >
              {item.percentage}%
            </Stack>
          );
        })}
      </Box>
      <Stack gap={0.625}>
        {Object.values(data).map((item) => (
          <Typography component={FlexBox} variant="caption" alignItems="center" key={item.code}>
            <Box mr={1.25} width={18} height={18} bgcolor={BarChartColors[item.code]} borderRadius={0.75} />
            <Box fontWeight={500}>{item.label}</Box>
            <Box component="span" color="#9A9EA5" ml={0.5}>
              {item.count ?? item.value} ({item.percentage}%)
            </Box>
          </Typography>
        ))}
      </Stack>
    </>
  );
};
