import { Box, Button, FormLabel, Stack, TextField, TextFieldProps, Typography, styled } from '@mui/material';
import { useFormik } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useLoginMutation } from '../app/api/authApi';
import { DEV_MODE } from '../constants/vars';
import { getFormikError, hasFormikError } from '../helpers/form';

interface LoginFormProps {}

const StyledTextField = styled(TextField)`
  & .MuiOutlinedInput-input {
    background-color: transparent;
    color: #d2d2d2;
  }
  & .MuiOutlinedInput-notchedOutline,
  & .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #696969;
  }
  & .MuiInputLabel-root {
    color: #d2d2d2;
  }
`;
StyledTextField.defaultProps = {
  fullWidth: true,
};

const LoginField = (props: TextFieldProps) => {
  const { label, ...rest } = props;
  return (
    <Stack>
      <FormLabel sx={{ color: 'white', ml: 1.5, mb: 0.5 }}>{label}</FormLabel>
      <TextField size="small" fullWidth {...rest} />
    </Stack>
  );
};

const LoginForm: FC<LoginFormProps> = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [login, { isLoading }] = useLoginMutation();

  const formik = useFormik({
    initialValues: DEV_MODE
      ? {
          login: 'admin@ia.uz',
          password: 'Secret7770',
        }
      : {
          login: '',
          password: '',
        },
    validationSchema: Yup.object({
      login: Yup.string().required('Обязательное поле'),
      password: Yup.string().required('Обязательное поле'),
    }),
    onSubmit: (values) => {
      login(values)
        .unwrap()
        .then((response) => {
          const { token, ...user } = response.data;
          localStorage.setItem('token', token);
          localStorage.setItem('user', JSON.stringify(user));

          toast.success(response.message);

          navigate('/dashboard');
        })
        .catch((error) => {
          console.error(error);
          toast.error(error.data.data.error);
        });
    },
    validateOnChange: false,
    validateOnBlur: false,
  });

  return (
    <Stack component="form" width={315} pt={11} pb={5.5} onSubmit={formik.handleSubmit} gap={2.5}>
      <LoginField
        label={t('', 'Логин')}
        {...formik.getFieldProps('login')}
        error={hasFormikError(formik, 'login')}
        helperText={getFormikError(formik, 'login')}
      />
      <LoginField
        label={t('password', 'Пароль')}
        {...formik.getFieldProps('password')}
        error={hasFormikError(formik, 'password')}
        helperText={getFormikError(formik, 'password')}
        type="password"
      />
      {false && (
        <Box textAlign="right" mt={-1.5}>
          <Typography
            component={Link}
            to="#"
            fontSize={12}
            fontWeight={400}
            color="#8E8E8E"
            sx={{ textDecoration: 'none' }}
          >
            Восстановить доступ
          </Typography>
        </Box>
      )}
      <Button
        size="large"
        variant="contained"
        sx={{
          alignSelf: 'flex-end',
          mt: 4.5,
          width: 146,
          height: 49,
          textTransform: 'capitalize',
          '&.Mui-disabled': {
            bgcolor: '#696969',
            color: '#D2D2D2',
          },
        }}
        type="submit"
        disabled={isLoading}
      >
        ВОЙТИ
      </Button>
    </Stack>
  );
};

export default LoginForm;
