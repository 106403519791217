import { RecordObject } from '../types';

const useUser = () => {
  const user = JSON.parse(localStorage.getItem('user') || '{}');

  const hasAccess = (type: string, value: string) => {
    if (type === 'module') {
      return Object.keys(user.modules).includes(value);
    } else if (type === 'operation') {
      return user.operations.find((op: RecordObject) => op.operation_code === value);
    }
  };

  const hasRole = (roleCode: string) => {
    return user.roles.some((role: RecordObject) => role.role_code === roleCode);
  };

  const hasRoles = (roles: string[]) => {
    return roles.some((role: string) => hasRole(role));
  };

  const isAdmin = hasRole('admin');
  const isBank = user.organization_type === 'bank';
  const isInsurance = user.organization_type === 'insurance';

  return { user, hasAccess, hasRole, hasRoles, isAdmin, isBank, isInsurance };
};

export default useUser;
