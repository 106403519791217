import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { urlWithSearchParams } from '../../helpers/format';
import { rtkBaseQuery } from '../../helpers/rtkquery';

export const applicationsApi = createApi({
  reducerPath: 'applicationsApi',
  baseQuery: rtkBaseQuery('/applications'),
  tagTypes: ['Applications'],
  endpoints: (builder) => ({
    getApplications: builder.query({
      query: (params) => urlWithSearchParams('', params),
      providesTags: ['Applications'],
    }),
    getApplicationById: builder.query({
      query: (id) => `${id}`,
      providesTags: (_result, _error, { id }) => [{ type: 'Applications', id }],
    }),
    checkApplication: builder.mutation({
      query: (body) => ({
        url: `check-data`,
        method: 'POST',
        body,
      }),
    }),
    createApplication: builder.mutation({
      query: (body) => ({
        url: '',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Applications'],
    }),
    confirmPayment: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `${id}/policy-payment`,
        method: 'POST',
        body: { ...body, _method: 'PATCH' },
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: 'Applications', id }, 'Applications'],
    }),

    exportApplications: builder.mutation({
      query: (params) => ({
        url: `export`,
        method: 'POST',
        params,
      }),
    }),
  }),
});

export const {
  useGetApplicationsQuery,
  useGetApplicationByIdQuery,
  useCreateApplicationMutation,
  useConfirmPaymentMutation,
  useCheckApplicationMutation,
  useExportApplicationsMutation,
} = applicationsApi;
