import { Box, Chip } from '@mui/material';
import { format } from 'date-fns';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useExportApplicationsMutation, useGetApplicationsQuery } from '../../app/api/applicationsApi';
import DetailsButton from '../../components/DetailsButton';
import { ReportsIcon } from '../../components/Icons';
import ListLayout from '../../components/ListLayout';
import ApplicationInfo from '../../components/application/ApplicationInfo';
import { APPLICATION_STATUS } from '../../constants/statuses';
import { useDrawer } from '../../contexts/DrawerContext';
import { renderCompanyName, renderFullName } from '../../helpers/format';
import useLocale from '../../hooks/useLocale';
import { Column } from '../../types';

interface ListPageProps {}

const ApplicationsListPage: FC<ListPageProps> = () => {
  const navigate = useNavigate();
  const { openDrawer, closeDrawer } = useDrawer();
  const { locale } = useLocale();

  const cols: Column[] = [
    {
      id: 'id',
      label: 'ID',
      width: 60,
    },
    // {
    //   id: "merchant.company_brand_name",
    //   label: "Мерчант",
    //   // renderCell: (data) => data.merchant.company_brand_name,
    // },
    {
      id: 'name',
      label: 'ФИО клиента',
      renderCell: (data) => renderFullName(data.client),
    },
    {
      id: 'product.name',
      label: 'Наименование продукта',
      renderCell: (data) => data.product.name[locale],
    },
    {
      id: 'created_at',
      label: 'Заявка получена',
      renderCell: (data) => format(new Date(data.created_at.replace('Z', '')), 'dd.MM.yyyy / HH:mm'),
    },
    {
      id: 'bank',
      label: 'Банк',
      renderCell: (data) => (
        <div>
          <div>{renderCompanyName(data.bank)}</div>
          <Box color="grey.500">{data.bank_branch.name}</Box>
        </div>
      ),
    },
    {
      id: 'insurance',
      label: 'Страховая компания',
      renderCell: (data) => (
        <div>
          <div>{renderCompanyName(data.insurance)}</div>
        </div>
      ),
    },
    {
      id: 'policy_fields.contract_id',
      label: 'ID договора',
      renderCell: (data) => data.policy_fields && data.policy_fields.contract_id,
    },
    {
      id: 'policy_fields.contract_number',
      label: 'Номер договора',
      renderCell: (data) => data.policy_fields && data.policy_fields.contract_number,
    },
    {
      id: 'policy.id',
      label: 'ID и номер полиса',
      renderCell: (data) => data.policy && data.policy.id + '(' + data.policy.series + ' ' + data.policy.number + ')',
    },

    // {
    //   id: "insurance_cost",
    //   label: "Сумма страховки, UZS",
    //   renderCell: (data) => formatPrice(data.insurance_cost),
    // },
    // {
    //   label: "Срок рассрочки",
    //   id: "loan_term",
    //   renderCell: (data) => data.loan_term + " мес.",
    // },
    // {
    //   label: "Результат скоринга",
    //   id: "scoreResult",
    // },
    {
      label: 'Статус заявки',
      id: 'status',
      renderCell: (data) => <Chip {...(APPLICATION_STATUS[data.status] || { label: 'Активна', color: 'info' })} />,
    },
    {
      label: '',
      id: 'actions',
      renderCell: (data) => <DetailsButton onClick={() => handleDetails(data)} />,
    },
  ];

  const handleShowInfo = (data: any) => {
    navigate(data.id + '');
    closeDrawer();
  };

  const handleDetails = (data: any) => {
    openDrawer({
      content: <ApplicationInfo {...{ data }} onClose={closeDrawer} onDetails={() => handleShowInfo(data)} />,
    });
  };

  return (
    <ListLayout
      title="Заявки"
      cols={cols}
      query={useGetApplicationsQuery}
      icon={<ReportsIcon />}
      createPermission="Application@store"
      filter
      exportMutation={useExportApplicationsMutation}
    />
  );
};

export default ApplicationsListPage;
