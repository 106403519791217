import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { rtkBaseQuery } from '../../helpers/rtkquery';

export const reportsApi = createApi({
  reducerPath: 'reportsApi',
  baseQuery: rtkBaseQuery('/reports'),
  endpoints: (builder) => ({
    getReports: builder.query({
      query: () => '',
    }),
    getReportById: builder.query({
      query: (id) => `${id}`,
    }),
    generateReport: builder.mutation({
      query: (body) => ({
        url: '',
        method: 'POST',
        body,
      }),
    }),
    getReportsData: builder.query({
      query: (id) => '/get-data',
    }),
  }),
});

export const { useGetReportsQuery, useGetReportByIdQuery, useGenerateReportMutation, useGetReportsDataQuery } =
  reportsApi;
