import { FC } from 'react';

interface CreateProps {}

const ApplicationUpdatePage: FC<CreateProps> = () => {
  // return <ApplicationForm data={{}} />;
  return null;
};

export default ApplicationUpdatePage;
