import { DeleteOutlined } from '@mui/icons-material';
import { Checkbox, IconButton, InputBase, MenuItem, TableCell, TableRow, TextField } from '@mui/material';
import { FC } from 'react';
import { RecordObject } from '../../types';

type Props = {
  row: RecordObject;
  index: number;
  onChange: (index: number, field: string, value: any) => void;
  onDelete: () => void;
  isBaseProduct?: boolean;
  isNewProduct?: boolean;
  isStaticField: boolean;
};

const ProductFieldsRow: FC<Props> = ({
  row,
  index,
  onChange: handleChangeField,
  onDelete: handleDeleteField,
  isBaseProduct,
  isNewProduct,
  isStaticField,
}) => {
  return (
    <TableRow key={index}>
      <TableCell>{index + 1}</TableCell>
      <TableCell>
        <TextField
          name={`policy_fields.${index}.section`}
          value={row.section}
          onChange={(e) => handleChangeField(index, 'section', e.target.value)}
          fullWidth
          size="small"
          select
          disabled={!isBaseProduct || isStaticField}
        >
          <MenuItem value="client_info">Данные клиента</MenuItem>
          <MenuItem value="loan_info">Данные кредита</MenuItem>
          <MenuItem value="docs">Документы</MenuItem>
        </TextField>
      </TableCell>
      <TableCell>
        <TextField
          name={`policy_fields.${index}.type`}
          value={row.type}
          onChange={(e) => handleChangeField(index, 'type', e.target.value)}
          fullWidth
          select
          disabled={!isBaseProduct || isStaticField}
          size="small"
        >
          <MenuItem value="text">Текст</MenuItem>
          <MenuItem value="number">Цифра</MenuItem>
          <MenuItem value="formattedPrice">Сумма</MenuItem>
          <MenuItem value="date">Дата</MenuItem>
          <MenuItem value="file">Файл</MenuItem>
        </TextField>
      </TableCell>
      <TableCell>
        <InputBase
          type="hidden"
          name={`policy_fields.${index}.id`}
          value={row.id}
          // value={row["id"]}
        />
        {!isBaseProduct && (
          <InputBase
            type="hidden"
            name={`policy_fields.${index}.product_policy_field_id`}
            value={row.product_policy_field_id || row.id || ''}
            // value={row["id"]}
          />
        )}
        <TextField
          name={`policy_fields.${index}.name`}
          value={row.name}
          // value={row["name"]}
          onChange={(e) => handleChangeField(index, 'name', e.target.value)}
          fullWidth
          size="small"
        />
      </TableCell>
      <TableCell>
        <TextField
          name={`policy_fields.${index}.code`}
          value={row.code}
          onChange={(e) => handleChangeField(index, 'code', e.target.value)}
          fullWidth
          size="small"
          disabled={isStaticField}
        />
      </TableCell>
      <TableCell align="center">
        <Checkbox
          name={`policy_fields.${index}.is_required`}
          checked={row.is_required}
          onChange={(e) => handleChangeField(index, 'is_required', e.target.checked)}
          size="small"
          disabled={isStaticField}
        />
      </TableCell>
      {isBaseProduct && (
        <TableCell>
          {!isStaticField && (
            <IconButton size="small" onClick={handleDeleteField}>
              <DeleteOutlined />
            </IconButton>
          )}
        </TableCell>
      )}
    </TableRow>
  );
};

export default ProductFieldsRow;
