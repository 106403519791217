import { Close } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { FC, ReactNode } from "react";

interface DetailsDrawerContainerProps {
  children: ReactNode;
  closeDrawer: () => void;
}

const DetailsDrawerContainer: FC<DetailsDrawerContainerProps> = ({
  children,
  closeDrawer,
}) => {
  return (
    <Box height={1} width={466} px={2.5} py={3.5} position="relative">
      <Box position="absolute" top={28} right={20}>
        <Button
          variant="contained"
          size="large"
          sx={{ p: 1.5 }}
          onClick={closeDrawer}
        >
          <Close />
        </Button>
      </Box>
      {children}
    </Box>
  );
};

export default DetailsDrawerContainer;
