import ScoringCreatePage from './CreatePage';
import ScoringListPage from './ListPage';
import ScoringUpdatePage from './UpdatePage';

const Scoring = {
  List: ScoringListPage,
  Create: ScoringCreatePage,
  Update: ScoringUpdatePage,
};

export default Scoring;
