import { ChevronRight } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  MenuItem,
  Pagination,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { ReactComponent as SortingArrowsIcon } from '../assets/svg/SortingArrows.svg';
import { ReactComponent as SortingArrowsDescIcon } from '../assets/svg/SortingArrowsDesc.svg';
import { UseQueryType } from '../types/api';
import { Column } from '../types/table';
import Loader from './Loader';

interface EnhancedTableProps {
  cols: Column[];
  query: UseQueryType | Function;
  selectable?: boolean;
  detailsAction?: (item: any) => void;
  limitOptions?: number[];
}

const EnhancedTable: FC<EnhancedTableProps> = ({
  cols,
  query,
  selectable = false,
  detailsAction,
  limitOptions = [10, 20, 50],
}) => {
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState<number>(0);
  const [limit, setLimit] = useState<number>(limitOptions[0]);
  const [rows, setRows] = useState([]);
  const [props, setProps] = useState({ limit, page });
  const [sort, setSort] = useState<string | undefined>();
  const [order, setOrder] = useState<'desc' | 'asc' | undefined>();

  const { data, refetch, isLoading } = query(props);

  useEffect(() => {
    if (data) {
      setRows(data.data.items);
      setTotal(data.data.total);
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, [props, refetch]);

  useEffect(() => {
    setProps((prev) => ({
      ...prev,
      page,
      limit,
      sort,
      order,
    }));
  }, [page, total, limit, sort, order]);

  const createSortHandler = (property: string) => () => {
    if (property === sort) {
      setOrder(order === 'asc' ? 'desc' : 'asc');
    } else {
      setSort(property);
      setOrder('desc');
    }
  };

  const renderData = () => {
    if (isLoading) {
      return (
        <TableRow>
          <TableCell colSpan={cols.length + (selectable ? 1 : 0) + (detailsAction ? 1 : 0)} align="center">
            <Loader />
          </TableCell>
        </TableRow>
      );
    } else {
      if (rows.length === 0) {
        return (
          <TableRow>
            <TableCell colSpan={cols.length + (selectable ? 1 : 0) + (detailsAction ? 1 : 0)} align="center">
              Нет данных
            </TableCell>
          </TableRow>
        );
      } else {
        return rows.map((row: any) => (
          <TableRow
            key={row.id}
            sx={{
              borderTop: '4px solid #F5F4F5',
              '& td:first-of-type': {
                borderTopLeftRadius: 5,
                borderBottomLeftRadius: 5,
              },
            }}
          >
            {selectable && (
              <TableCell>
                <Checkbox sx={{ p: 0 }} />
              </TableCell>
            )}
            {cols.map((col) => (
              <TableCell key={col.id} align={col.align || ('left' as any)}>
                {col.renderCell ? col.renderCell(row) : row[col.id]}
              </TableCell>
            ))}
            {detailsAction && (
              <TableCell>
                <Button variant="outlined" sx={{ p: 0 }} onClick={() => detailsAction(row)}>
                  <ChevronRight />
                </Button>
              </TableCell>
            )}
          </TableRow>
        ));
      }
    }
  };

  return (
    <TableContainer
      sx={{
        bgcolor: '#F3F5F7',
        borderRadius: 2.5,
        py: 0.25,
        px: 2,
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            {selectable && (
              <TableCell width={50}>
                <Checkbox sx={{ p: 0 }} />
              </TableCell>
            )}
            {cols.map((col) => (
              <TableCell key={col.id} align={col.align || ('left' as any)} sx={{ whiteSpace: 'pre' }} width={col.width}>
                <TableSortLabel
                  active={col.sortable}
                  IconComponent={sort === col.id && order ? SortingArrowsDescIcon : SortingArrowsIcon}
                  direction={sort === col.id ? order : undefined}
                  onClick={createSortHandler(col.id)}
                >
                  {col.label}
                </TableSortLabel>
              </TableCell>
            ))}
            {detailsAction && <TableCell width={62} />}
          </TableRow>
        </TableHead>
        <TableBody>
          {renderData()}
          {rows.map((row: any) => (
            <TableRow key={row.id}>
              {selectable && (
                <TableCell>
                  <Checkbox sx={{ p: 0 }} />
                </TableCell>
              )}
              {cols.map((col) => (
                <TableCell key={col.id} align={col.align || ('left' as any)}>
                  {col.renderCell ? col.renderCell(row) : row[col.id]}
                </TableCell>
              ))}
              {detailsAction && (
                <TableCell>
                  <Button variant="outlined" sx={{ p: 0 }} onClick={() => detailsAction(row)}>
                    <ChevronRight />
                  </Button>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={cols.length + (selectable ? 2 : 1)} align="right">
              <Stack direction="row" alignItems="center" justifyContent="flex-end" gap={3}>
                <Box display="flex" alignItems="center">
                  <Typography variant="body2" mr={1}>
                    Показывать по:
                  </Typography>
                  <Select value={limit} onChange={(e) => setLimit(Number(e.target.value))} size="small">
                    {limitOptions.map((option) => (
                      <MenuItem value={option} key={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Pagination
                  shape="rounded"
                  count={Math.ceil(total / limit)}
                  page={page}
                  onChange={(e, page) => setPage(page)}
                  showFirstButton
                  showLastButton
                />
              </Stack>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default EnhancedTable;
