import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Collapse,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { FC, useState } from "react";
import { useLocation } from "react-router";
import { Link, useNavigate } from "react-router-dom";
import NavList from "./NavList";

export interface NavListItemProps {
  icon?: JSX.Element;
  label: string;
  href: string;
  children?: Pick<NavListItemProps, "label" | "href">[];
  subItem?: boolean;
  closeNav: () => void;
}

const NavListItem: FC<NavListItemProps> = ({
  icon,
  label,
  href,
  children,
  subItem,
  closeNav,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [expanded, setExpanded] = useState(false);

  const handleClick = () => {
    navigate(href);
    closeNav();
  };

  return (
    <ListItem
      sx={{
        p: 0,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <ListItemButton
        selected={location.pathname === href}
        sx={{
          width: 1,
          py: 1.25,
          px: 3,
          // minHeight: 48,
          // borderRadius: 1.25,
          transition: "0.2s",
          // "&:hover, &.Mui-selected": {
          "&:hover,     &.Mui-selected, &.Mui-selected:hover": {
            backgroundColor: "#222222",
          },
          "&:hover .MuiListItemIcon-root, &.Mui-selected .MuiListItemIcon-root":
            {
              color: "#FF6A2A",
            },
        }}
        component={Link}
        to={href}
        onClick={children ? () => setExpanded((prev) => !prev) : handleClick}
      >
        <ListItemIcon
          sx={{
            color: "#666666",
            transition: "0.2s",
            minWidth: 35,
          }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText sx={{ m: 0 }}>{label}</ListItemText>
        {children && (expanded ? <ExpandLess /> : <ExpandMore />)}
      </ListItemButton>
      {children && (
        <Collapse in={expanded} timeout="auto" unmountOnExit sx={{ width: 1 }}>
          <NavList>
            {children.map((child, key) => (
              <NavListItem {...child} key={key} closeNav={closeNav} />
            ))}
          </NavList>
        </Collapse>
      )}
    </ListItem>
  );
};

export default NavListItem;
