import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useGetUserRolesQuery } from "../../app/api/userRolesApi";
import ListLayout from "../../components/ListLayout";
import { Column } from "../../types";

interface UserRolesProps {}

const UserRoles: FC<UserRolesProps> = () => {
  const { i18n } = useTranslation();

  const cols: Column[] = [
    {
      id: "role_id",
      label: "ID",
      sortable: true,
      numeric: true,
      width: 30,
    },
    {
      id: "role_name",
      label: "Название роли",
      sortable: true,
      numeric: false,
      renderCell: (data: any) => data.role_name[i18n.language],
    },
    {
      id: "role_code",
      label: "Код",
      sortable: true,
      numeric: false,
    },
  ];

  return (
    <ListLayout
      cols={cols}
      query={useGetUserRolesQuery}
      title="Роли пользователя"
    />
  );
};

export default UserRoles;
