import { FC } from "react";
import { useParams } from "react-router-dom";
import { useGetBankQuery } from "../../app/api/banksApi";
import BankForm from "../../components/banks/BankForm";

const BanksUpdatePage: FC = () => {
  const { id } = useParams();
  const { data } = useGetBankQuery(id);

  if (!data) return null;

  return <BankForm data={data.data} />;
};

export default BanksUpdatePage;
