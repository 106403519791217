import { Edit } from "@mui/icons-material";
import { Button } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useGetAllRolesQuery } from "../../app/api/rolesApi";
import ListLayout from "../../components/ListLayout";
import { Column } from "../../types/table";

const Roles: FC = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const cols: Column[] = [
    {
      id: "id",
      label: "ID",
      sortable: true,
      numeric: true,
      width: 30,
    },
    {
      id: "name",
      label: "Название роли",
      sortable: true,
      renderCell: (data: any) => data.name[i18n.language],
    },
    {
      id: "code",
      label: "Код",
      // sortable: true,
      // numeric: false,
    },
    {
      id: "actions",
      label: "",
      sortable: false,
      numeric: false,
      width: 40,
      renderCell: (data: any) => [
        <Button
          variant="outlined"
          size="small"
          sx={{ fontSize: 24, p: 0.5, color: "#999999", mr: 1 }}
          onClick={() => navigate("" + data.id)}
        >
          <Edit fontSize="inherit" />
        </Button>,
      ],
    },
  ];

  return <ListLayout title="Роли" cols={cols} query={useGetAllRolesQuery} />;
};

export default Roles;
